import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { reviewData, reviewDataAdd } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DataService } from 'src/app/services/data.service';
import { MemberService } from 'src/app/services/member.service';
declare var $: any;
@Component({
  selector: 'app-review-member',
  templateUrl: './review-member.component.html',
  styleUrls: ['./review-member.component.css'],
})
export class ReviewMemberComponent implements OnInit {
  @Input() bibid: string;
  currentUser: any[] = [];

  USERID :string= '';
 // editStatus = false;
  addStatus = true;
  REVIEWID:string="";
  reviewData: reviewData[] = [];

  public Editor = ClassicEditor;

  addFG: UntypedFormGroup;
  editFG: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private memberService: MemberService,
    private activateRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
     private data:DataService 
  ) {}

  ngOnInit(): void {
  
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    if (this.currentUser.length > 0) {
      this.USERID =String(this.currentUser[0].USERID) ;
     
    }

    this.addFG = this.fb.group({
      value: new UntypedFormControl('', Validators.required),
    });
    this.editFG = this.fb.group({
      value: new UntypedFormControl('', Validators.required),
    });

this.load();
  }

  load(){
    this.addStatus = true;
    this.spinner.show(); // หมุนๆ
    this.homeService
      .getBookReviewMember(this.bibid)
      .subscribe((Review: any) => {
        this.reviewData = Review;

        if (this.USERID != '') {
          //user log in
          this.reviewData.forEach((element) => {

         //   alert(element.USERID + ' |' + this.USERID)
            //เช็คว่าเคย review หรือไม่
            if (element.USERID === this.USERID ) {
              this.addStatus = false;
            }
          });
        }
        this.spinner.hide(); // ซ่อนหมุนๆ
      });
  }

  Save(flag:string){
    const  objReviewDataAdd :reviewDataAdd = {
      REVIEWID:'',
      USERID:   this.USERID,
      BIBID: this.bibid ,
      Review:   this.addFG.controls.value.value,
      FLAG: flag,
      REVIEWTYPE: 0 //member review
      };
  
     this.memberService.addMemberReview(objReviewDataAdd).subscribe((getData: any) => {
        if (getData === true) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500,
          });
          this.load();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'บันทึกข้อมูลไม่สำเร็จ',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        $("#addModal").modal('hide');
      });
  }


showEditModal(val:reviewData){
  this.editFG.controls.value.setValue(val.REVIEWDETAIL);
  this.REVIEWID =val.REVIEWID;
  $("#editModal").modal('show');//open edit modal
}

  edit(flag:string){
    const  objReviewDataAdd :reviewDataAdd = {
      REVIEWID:this.REVIEWID,
      USERID:   this.USERID,
      BIBID: this.bibid ,
      Review:   this.editFG.controls.value.value,
      FLAG: flag,
      REVIEWTYPE: 0 //member review
      };
  
     this.memberService.updateMemberReview(objReviewDataAdd).subscribe((getData: any) => {
        if (getData === true) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500,
          });
          this.load();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'แก้ไขข้อมูลไม่สำเร็จ',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        $("#editModal").modal('hide');
      });
  }


  deleteReview(id:string){
    Swal.fire({
      title: 'ยืนยันการลบ review?',
     // text: this.addFG.controls.NewsTitle.value,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
  
        this.memberService.reviewDelete(id).subscribe((getData: any) => {
          if (getData === true) {
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'ลบข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500,
            });
            this.load();
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'ลบข้อมูลไม่สำเร็จ',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  
  
  }


}
