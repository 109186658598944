import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import {marcHolding,marc} from 'src/app/models/walai';
@Component({
  selector: 'app-holding',
  templateUrl: './holding.component.html',
  styleUrls: ['./holding.component.css']
})
export class HoldingComponent implements OnInit {
  @Input() bibid: string;
  holdings :any[];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.getHolding(this.bibid).subscribe((holdings: marcHolding[]) => {
      //ตัวแปรเก็บ Item ทั้งหมด
     this.holdings = holdings;
console.log(  this.holdings);
   });
  }

}
