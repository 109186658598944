import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-facebook',
  templateUrl: './share-facebook.component.html',
  styleUrls: ['./share-facebook.component.css']
})
export class ShareFacebookComponent implements OnInit {
  @Input() shareUrl: string;
  navUrl: string;
  constructor() { }

  ngOnInit() {
    this.createNavigationUrl();
  }

  private createNavigationUrl() {
    let searchParams = new URLSearchParams();

    // TODO: zrobić map z tego manualnego dziugania

    // switch(this.type) {
    //   case 'facebook':
        searchParams.set('u', this.shareUrl);
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + searchParams;
    //     break;
    //   case 'twitter':
    //     searchParams.set('url', this.shareUrl);
    //     this.navUrl =  'https://twitter.com/share?' + searchParams;
    //     break;
    // }
  }

  public share() {
    return window.open(this.navUrl, "_blank");
  }

}
