<div class="jumbotron container-fluid py-4 py-lg-5">
  <div class="d-flex justify-content-center py-lg-5">
    <div class="col-12 col-lg-5">
      <div class="input-group Bg-Search" [formGroup]="qsearchForm">

        <div>
          <button id="inputState" class="btn rounded-0 border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-filter-circle me-1 color-orange"></i><span class="mx-1">{{inputState}}</span><i class="bi bi-chevron-down ms-1"></i>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" role="button" (click)="SearchOption('KEYWORD', 'Keyword')">Keyword</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('TITLE', 'Title')">Title</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('AUTHOR', 'Author')">Author</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('SUBJECT', 'Subject')">Subject</a></li>
            <!-- <li><a class="dropdown-item" role="button" (click)="SearchOption('TAGS', 'Tags')">Tags</a></li> -->
            <li><a class="dropdown-item" role="button" (click)="SearchOption('PUBLISHER', 'Publisher')">Publisher</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('JOURNALTITLE', 'Journal')">Journal</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('CALLNUMBER', 'Call number')">Call number</a></li>
            <li><a class="dropdown-item" role="button" (click)="SearchOption('ISBNISSN', 'ISBN/ISSN')">ISBN/ISSN</a></li>
            
          </ul>
        </div>


        <input
          type="text"
          class="form-control border-start"
          placeholder="Search..."
          formControlName="textSearch"
          (keydown.enter)="search(true,'')"
          id="txtSearch"
        />

        <div class="input-group-append">
          <button
            type="button"
            class="color-orange1 btn"
            id="btnSearch"
            (click)="search(true,'')"
          >
            <i
              class="bi bi-search"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            ></i>
          </button>
        </div>
      </div>

      <div class="mt-4 text-center" *ngIf="auth.length > 0">
        <button
          type="button"
          class="btn btn-primary btn-sm py-1 fw-light shadow me-2 mb-1"
          *ngIf="BT"
          (click)="showAuth(BT.authid, 'BT')"
        >
          Broader Term <span class="badge badge-light">{{ BT.Count }}</span>
        </button>
        <button
          type="button"
          class="btn btn-sm py-1 fw-light shadow btn-warning me-2 mb-1"
          *ngIf="NT"
          (click)="showAuth(NT.authid, 'NT')"
        >
          Narrower Term <span class="badge badge-light">{{ NT.Count }}</span>
        </button>
        <button
          type="button"
          class="btn btn-sm py-1 fw-light shadow btn-success me-2 mb-1"
          *ngIf="RT"
          (click)="showAuth(RT.authid, 'RT')"
        >
          Related Term <span class="badge badge-light">{{ RT.Count }}</span>
        </button>
        <button
          type="button"
          class="btn btn-sm py-1 fw-light shadow btn-danger me-2 mb-1"
          *ngIf="LV"
          (click)="showAuth(LV.authid, 'LV')"
        >
          Language Variants
          <span class="badge badge-light">{{ LV.Count }}</span>
        </button>

        <div
          class="alert alert-primary alert-dismissible fade show mt-2"
          role="alert"
          *ngIf="AuthSymbol.length > 0"
        >
          <span
            class="badge text-bg-primary me-2"
            (click)="searchAuth(item.REFID, item.AUTHVIEWOPAC)"
            *ngFor="let item of AuthSymbol"
            >{{ item.AUTHVIEWOPAC }}</span
          >

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container pt-5 pb-3">
  <div class="col-12 mb-4">
  <h5 class="text-walai-default-color">  
    <!-- <span class="me-2"><a  class="a-walai" (click)="loadRSS()" target="_blank"><i class="bi bi-rss"></i></a></span> -->

      <span class="me-2">{{ "SearchResults" | translate }}:</span>
      <span
        class="badge rounded-pill bg-secondary text-dark me-2 fw-light"
        *ngFor="let item of CurrentKeyword; let i = index"
        >{{ item.Ntk }} : {{ ConvertCurrentFilterAndKeywordToText(item) }}</span
      >
    </h5>

    <!-- <div *ngIf="bookcount == null">
      <p class="text-walai-default-color">ไม่พบข้อมูลหนังสือ</p>
    </div> -->
  </div>

  <!-- *ngIf="bookcount!=null" -->
  <div class="row p-0 shadow-lg">
    <!-- Sidebar -->
    <aside class="border-end col-12 col-lg-3 px-0 d-none d-lg-block">
      <div class="navbar bg-light px-3 py-filter">
        <h5
          class="navbar-brand ps-0 form-control border-0 bg-transparent text-walai-default-color my-auto"
        >
          {{ "Filter" | translate }}
        </h5>
      </div>

      <div
        *ngIf="CurrentFilter.length > 0"
        class="container selected-criteria-panel"
      >
        <h6 class="text-walai-default-color">Current Filter</h6>
        <div
          class="alert alert-primary alert-dismissible fade show"
          role="alert"
          *ngFor="let filter of CurrentFilter"
        >
          <small>{{ filter.Ntk }} :{{ filter.Ntt }} </small>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            (click)="RemoveKeyword(filter.Ntk, filter.id)"
          ></button>
        </div>
      </div>

      <div *ngIf="bookcount != null" class="list-group list-group-flush">
        <!-- locationFacet -->
        <ng-container *ngIf="locationFacet && showlocation == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Location" | translate }}
          </h5>
          <ul class="list-group" *ngIf="locationFacetLessShow">
            <li
              role="button"
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of locationFacetLess"
              (click)="addFilter('LOCATION', s.name)"
            >
              <!-- {{s.name}} -->

              <app-location-name [LOCATIONID]="s.name"></app-location-name>
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="locationFacetLess.length >= facetLess"
                (click)="MoreLocation(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="locationFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of locationFacet"
              (click)="addFilter('LOCATION', s.name)"
            >
              <!-- {{s.name}} -->

              <app-location-name [LOCATIONID]="s.name"></app-location-name>
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
            >
              <a class="a-walai text-primary" (click)="MoreLocation(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- subjectFacet -->
        <ng-container *ngIf="subjectFacet && showSubject == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Subject" | translate }}
          </h5>
          <ul class="list-group" *ngIf="subjectFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of subjectFacetLess"
              (click)="addFilter('SUBJECTFACET', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>

            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="subjectFacetLess.length >= facetLess"
                (click)="MoreSubject(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="subjectFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of subjectFacet"
              (click)="addFilter('SUBJECTFACET', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
            >
              <a class="a-walai text-primary" (click)="MoreSubject(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- authorFacet -->
        <ng-container *ngIf="authorFacet && showAuthor == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Author" | translate }}
          </h5>
          <ul class="list-group" *ngIf="authorFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of authorFacetLess"
              (click)="addFilter('AUTHORFACET', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="authorFacetLess.length >= facetLess"
                (click)="MoreAuthor(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="authorFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of authorFacet"
              (click)="addFilter('AUTHORFACET', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreAuthor(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Pubyear -->
        <ng-container *ngIf="bookcount && showPubyear == 1">
          <h5
            class="list-group-item list-group-item-action  my-3  text-walai-default-color"
          >
            {{ "PublishYear" | translate }}
          </h5>
          <div class="px-2">

            <mat-slider style="width: 260px;margin-left: 20px;"  [min]="year_min" [max]="year_max"  showTickMarks discrete  >
              <input [(ngModel)]="year_Cmin_value" matSliderStartThumb>
              <input [(ngModel)]="year_Cmax_value" matSliderEndThumb>
            </mat-slider>
            <div class="text-center">
              <button
                (click)="addFilterYear()"
                type="button"
                class="btn btn-primary btn-sm"
              >
                Filter
              </button>
            </div>
            <!-- <ngx-slider
              [(value)]="minYearValue"
              [(highValue)]="maxYearValue"
              [options]="optionsYear"
              (userChangeEnd)="addFilterYear($event)"
            ></ngx-slider> -->
          </div>
        </ng-container>

        <!-- sourceTypeFacet -->
        <ng-container *ngIf="sourceTypeFacet && showMattype == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "SourceType" | translate }}
          </h5>
          <ul class="list-group" *ngIf="sourceTypeFacet">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of sourceTypeFacet"
              (click)="addFilter('MEDIATYPE', s.name)"
            >
              {{ getMEDIATYPENameByID(s.name) }}
              <!-- <app-location-name [LOCATIONID]="s.name"></app-location-name> -->
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
            ></li>
          </ul>
        </ng-container>

        <!-- collection -->
        <ng-container *ngIf="collectionFacet && showCollection == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "collection" | translate }}
          </h5>
          <ul class="list-group" *ngIf="collectionFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of collectionFacetLess"
              (click)="addFilter('collection', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="collectionFacetLess.length >= facetLess"
                (click)="Morecollection(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="collectionFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of collectionFacet"
              (click)="addFilter('collection', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="Morecollection(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- lang -->
        <ng-container *ngIf="langFacet && showLang == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "lang" | translate }}
          </h5>
          <ul class="list-group" *ngIf="langFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of langFacetLess"
              (click)="addFilter('lang', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="langFacetLess.length >= facetLess"
                (click)="Morelang(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="langFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of langFacet"
              (click)="addFilter('lang', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="Morelang(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Region -->
        <ng-container *ngIf="RegionFacet.length && showRegion == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Geo Area" | translate }}
          </h5>
          <ul class="list-group" *ngIf="RegionFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of RegionFacetLess"
              (click)="addFilter('Region', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="RegionFacetLess.length >= facetLess"
                (click)="MoreRegion(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="RegionFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of RegionFacet"
              (click)="addFilter('Region', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreRegion(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Era -->
        <ng-container *ngIf="EraFacet.length && showEra == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Era" | translate }}
          </h5>
          <ul class="list-group" *ngIf="EraFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of EraFacetLess"
              (click)="addFilter('Era', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="EraFacetLess.length >= facetLess"
                (click)="MoreEra(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="EraFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of EraFacet"
              (click)="addFilter('Era', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreEra(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- CallNoLC -->
        <ng-container *ngIf="CallNoLCFacet && showCallNoLC == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Call no LC" | translate }}
          </h5>
          <ul class="list-group" *ngIf="CallNoLCFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoLCFacetLess"
              (click)="addFilter('CallNoLC', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="CallNoLCFacetLess.length >= facetLess"
                (click)="MoreCallNoLC(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="CallNoLCFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoLCFacet"
              (click)="addFilter('CallNoLC', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreCallNoLC(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- CallNoDC -->
        <ng-container *ngIf="CallNoDCFacet && showCallNoDC == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Call no DC" | translate }}
          </h5>
          <ul class="list-group" *ngIf="CallNoDCFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoDCFacetLess"
              (click)="addFilter('CallNoDC', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="CallNoDCFacetLess.length >= facetLess"
                (click)="MoreCallNoDC(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="CallNoDCFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoDCFacet"
              (click)="addFilter('CallNoDC', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreCallNoDC(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- CallNoNLM -->
        <ng-container *ngIf="CallNoNLMFacet && showCallNoNLM == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Call no NLM" | translate }}
          </h5>
          <ul class="list-group" *ngIf="CallNoNLMFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoNLMFacetLess"
              (click)="addFilter('CallNoNLM', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="CallNoNLMFacetLess.length >= facetLess"
                (click)="MoreCallNoNLM(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="CallNoNLMFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of CallNoNLMFacet"
              (click)="addFilter('CallNoNLM', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreCallNoNLM(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Illustrations -->
        <ng-container *ngIf="IllustrationsFacet && showIllustrations == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Illustrations" | translate }}
          </h5>
          <ul class="list-group" *ngIf="IllustrationsFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of IllustrationsFacetLess"
              (click)="addFilter('Illustrations', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="IllustrationsFacetLess.length >= facetLess"
                (click)="MoreIllustrations(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="IllustrationsFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of IllustrationsFacet"
              (click)="addFilter('Illustrations', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreIllustrations(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- TargetAudience -->
        <ng-container *ngIf="TargetAudienceFacet && showTargetAudience == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Target audience" | translate }}
          </h5>
          <ul class="list-group" *ngIf="TargetAudienceFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of TargetAudienceFacetLess"
              (click)="addFilter('TargetAudience', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="TargetAudienceFacetLess.length >= facetLess"
                (click)="MoreTargetAudience(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="TargetAudienceFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of TargetAudienceFacet"
              (click)="addFilter('TargetAudience', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreTargetAudience(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- FormofItem -->
        <ng-container *ngIf="FormofItemFacet && showFormofItem == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Form of item" | translate }}
          </h5>
          <ul class="list-group" *ngIf="FormofItemFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of FormofItemFacetLess"
              (click)="addFilter('FormofItem', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="FormofItemFacetLess.length >= facetLess"
                (click)="MoreFormofItem(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="FormofItemFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of FormofItemFacet"
              (click)="addFilter('FormofItem', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreFormofItem(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- NatureOfContents -->
        <ng-container
          *ngIf="NatureOfContentsFacet && showNatureOfContents == 1"
        >
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Nature of contents" | translate }}
          </h5>
          <ul class="list-group" *ngIf="NatureOfContentsFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of NatureOfContentsFacetLess"
              (click)="addFilter('NatureOfContents', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="NatureOfContentsFacetLess.length >= facetLess"
                (click)="MoreNatureOfContents(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="NatureOfContentsFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of NatureOfContentsFacet"
              (click)="addFilter('NatureOfContents', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                (click)="MoreNatureOfContents(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- GovernmentPublication -->
        <ng-container
          *ngIf="GovernmentPublicationFacet && showGovernmentPublication == 1"
        >
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Government publication" | translate }}
          </h5>
          <ul class="list-group" *ngIf="GovernmentPublicationFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of GovernmentPublicationFacetLess"
              (click)="addFilter('GovernmentPublication', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="GovernmentPublicationFacetLess.length >= facetLess"
                (click)="MoreGovernmentPublication(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul
            class="list-group"
            *ngIf="GovernmentPublicationFacetLessShow == false"
          >
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of GovernmentPublicationFacet"
              (click)="addFilter('GovernmentPublication', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                (click)="MoreGovernmentPublication(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- ConferencePublication -->
        <ng-container
          *ngIf="ConferencePublicationFacet && showConferencePublication == 1"
        >
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Conference publication" | translate }}
          </h5>
          <ul class="list-group" *ngIf="ConferencePublicationFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of ConferencePublicationFacetLess"
              (click)="addFilter('ConferencePublication', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="ConferencePublicationFacetLess.length >= facetLess"
                (click)="MoreConferencePublication(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul
            class="list-group"
            *ngIf="ConferencePublicationFacetLessShow == false"
          >
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of ConferencePublicationFacet"
              (click)="addFilter('ConferencePublication', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                (click)="MoreConferencePublication(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Festschrift -->
        <ng-container *ngIf="FestschriftFacet && showFestschrift == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Festschrift" | translate }}
          </h5>
          <ul class="list-group" *ngIf="FestschriftFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of FestschriftFacetLess"
              (click)="addFilter('Festschrift', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="FestschriftFacetLess.length >= facetLess"
                (click)="MoreFestschrift(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="FestschriftFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of FestschriftFacet"
              (click)="addFilter('Festschrift', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreFestschrift(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- LiteraryForm -->
        <ng-container *ngIf="LiteraryFormFacet && showLiteraryForm == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "LiteraryForm" | translate }}
          </h5>
          <ul class="list-group" *ngIf="LiteraryFormFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of LiteraryFormFacetLess"
              (click)="addFilter('LiteraryForm', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="LiteraryFormFacetLess.length >= facetLess"
                (click)="MoreLiteraryForm(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="LiteraryFormFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of LiteraryFormFacet"
              (click)="addFilter('LiteraryForm', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreLiteraryForm(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>

        <!-- Biography -->
        <ng-container *ngIf="BiographyFacet && showBiography == 1">
          <h5
            class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
          >
            {{ "Biography" | translate }}
          </h5>
          <ul class="list-group" *ngIf="BiographyFacetLessShow">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of BiographyFacetLess"
              (click)="addFilter('Biography', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a
                class="a-walai text-primary"
                *ngIf="BiographyFacetLess.length >= facetLess"
                (click)="MoreBiography(false)"
                >more...</a
              >
            </li>
          </ul>

          <!-- When Show More -->
          <ul class="list-group" *ngIf="BiographyFacetLessShow == false">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
              *ngFor="let s of BiographyFacet"
              (click)="addFilter('Biography', s.name)"
            >
              {{ s.name }}
              <span class="badge bg-secondary text-dark fw-normal">{{
                s.count
              }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
            >
              <a class="a-walai text-primary" (click)="MoreBiography(true)"
                >...less</a
              >
            </li>
          </ul>
        </ng-container>
      </div>
    </aside>

    <!-- aside responsive-->
    <div
      class="offcanvas offcanvas-start d-lg-none"
      tabindex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div class="offcanvas-header">
        <h5 class="label-text-walai-default-color fw-normal">
          {{ "Filter" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="list-group list-group-flush">
          <!-- locationFacet -->
          <ng-container *ngIf="locationFacet && showlocation == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Location" | translate }}
            </h5>
            <ul class="list-group" *ngIf="locationFacetLessShow">
              <li
                role="button"
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of locationFacetLess"
                (click)="addFilter('LOCATION', s.name)"
              >
                <!-- {{s.name}} -->

                <app-location-name [LOCATIONID]="s.name"></app-location-name>
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="locationFacetLess.length >= facetLess"
                  (click)="MoreLocation(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="locationFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of locationFacet"
                (click)="addFilter('LOCATION', s.name)"
              >
                <!-- {{s.name}} -->

                <app-location-name [LOCATIONID]="s.name"></app-location-name>
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
              >
                <a class="a-walai text-primary" (click)="MoreLocation(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- subjectFacet -->
          <ng-container *ngIf="subjectFacet && showSubject == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Subject" | translate }}
            </h5>
            <ul class="list-group" *ngIf="subjectFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of subjectFacetLess"
                (click)="addFilter('SUBJECTFACET', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>

              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="subjectFacetLess.length >= facetLess"
                  (click)="MoreSubject(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="subjectFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of subjectFacet"
                (click)="addFilter('SUBJECTFACET', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
              >
                <a class="a-walai text-primary" (click)="MoreSubject(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- authorFacet -->
          <ng-container *ngIf="authorFacet && showAuthor == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Author" | translate }}
            </h5>
            <ul class="list-group" *ngIf="authorFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of authorFacetLess"
                (click)="addFilter('AUTHORFACET', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="authorFacetLess.length >= facetLess"
                  (click)="MoreAuthor(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="authorFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of authorFacet"
                (click)="addFilter('AUTHORFACET', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreAuthor(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Pubyear -->
          <!-- <ng-container *ngIf="bookcount && showPubyear == 1">
            <h5
              class="list-group-item list-group-item-action mb-3 text-walai-default-color"
            >
              {{ "PublishYear" | translate }}
            </h5>
            <div class="px-2">
              <ngx-slider
                [(value)]="minYearValue"
                [(highValue)]="maxYearValue"
                [options]="optionsYear"
                (userChangeEnd)="addFilterYear($event)"
              ></ngx-slider>
            </div>
          </ng-container> -->

          <!-- sourceTypeFacet -->
          <ng-container *ngIf="sourceTypeFacet && showMattype == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "SourceType" | translate }}
            </h5>
            <ul class="list-group" *ngIf="sourceTypeFacet">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of sourceTypeFacet"
                (click)="addFilter('MEDIATYPE', s.name)"
              >
                {{ getMEDIATYPENameByID(s.name) }}
                <!-- <app-location-name [LOCATIONID]="s.name"></app-location-name> -->
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai"
              ></li>
            </ul>
          </ng-container>

          <!-- collection -->
          <ng-container *ngIf="collectionFacet && showCollection == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "collection" | translate }}
            </h5>
            <ul class="list-group" *ngIf="collectionFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of collectionFacetLess"
                (click)="addFilter('collection', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="collectionFacetLess.length >= facetLess"
                  (click)="Morecollection(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="collectionFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of collectionFacet"
                (click)="addFilter('collection', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="Morecollection(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- lang -->
          <ng-container *ngIf="langFacet && showLang == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "lang" | translate }}
            </h5>
            <ul class="list-group" *ngIf="langFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of langFacetLess"
                (click)="addFilter('lang', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="langFacetLess.length >= facetLess"
                  (click)="Morelang(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="langFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of langFacet"
                (click)="addFilter('lang', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="Morelang(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Region -->
          <ng-container *ngIf="RegionFacet && showRegion == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Geo Area" | translate }}
            </h5>
            <ul class="list-group" *ngIf="RegionFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of RegionFacetLess"
                (click)="addFilter('Region', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="RegionFacetLess.length >= facetLess"
                  (click)="MoreRegion(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="RegionFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of RegionFacet"
                (click)="addFilter('Region', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreRegion(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Era -->
          <ng-container *ngIf="EraFacet && showEra == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Era" | translate }}
            </h5>
            <ul class="list-group" *ngIf="EraFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of EraFacetLess"
                (click)="addFilter('Era', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="EraFacetLess.length >= facetLess"
                  (click)="MoreEra(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="EraFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of EraFacet"
                (click)="addFilter('Era', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreEra(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- CallNoLC -->
          <ng-container *ngIf="CallNoLCFacet && showCallNoLC == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Call no LC" | translate }}
            </h5>
            <ul class="list-group" *ngIf="CallNoLCFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoLCFacetLess"
                (click)="addFilter('CallNoLC', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="CallNoLCFacetLess.length >= facetLess"
                  (click)="MoreCallNoLC(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="CallNoLCFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoLCFacet"
                (click)="addFilter('CallNoLC', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreCallNoLC(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- CallNoDC -->
          <ng-container *ngIf="CallNoDCFacet && showCallNoDC == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Call no DC" | translate }}
            </h5>
            <ul class="list-group" *ngIf="CallNoDCFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoDCFacetLess"
                (click)="addFilter('CallNoDC', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="CallNoDCFacetLess.length >= facetLess"
                  (click)="MoreCallNoDC(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="CallNoDCFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoDCFacet"
                (click)="addFilter('CallNoDC', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreCallNoDC(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- CallNoNLM -->
          <ng-container *ngIf="CallNoNLMFacet && showCallNoNLM == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Call no NLM" | translate }}
            </h5>
            <ul class="list-group" *ngIf="CallNoNLMFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoNLMFacetLess"
                (click)="addFilter('CallNoNLM', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="CallNoNLMFacetLess.length >= facetLess"
                  (click)="MoreCallNoNLM(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="CallNoNLMFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of CallNoNLMFacet"
                (click)="addFilter('CallNoNLM', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreCallNoNLM(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Illustrations -->
          <ng-container *ngIf="IllustrationsFacet && showIllustrations == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Illustrations" | translate }}
            </h5>
            <ul class="list-group" *ngIf="IllustrationsFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of IllustrationsFacetLess"
                (click)="addFilter('Illustrations', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="IllustrationsFacetLess.length >= facetLess"
                  (click)="MoreIllustrations(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="IllustrationsFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of IllustrationsFacet"
                (click)="addFilter('Illustrations', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  (click)="MoreIllustrations(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- TargetAudience -->
          <ng-container *ngIf="TargetAudienceFacet && showTargetAudience == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Target audience" | translate }}
            </h5>
            <ul class="list-group" *ngIf="TargetAudienceFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of TargetAudienceFacetLess"
                (click)="addFilter('TargetAudience', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="TargetAudienceFacetLess.length >= facetLess"
                  (click)="MoreTargetAudience(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="TargetAudienceFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of TargetAudienceFacet"
                (click)="addFilter('TargetAudience', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  (click)="MoreTargetAudience(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- FormofItem -->
          <ng-container *ngIf="FormofItemFacet && showFormofItem == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Form of item" | translate }}
            </h5>
            <ul class="list-group" *ngIf="FormofItemFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of FormofItemFacetLess"
                (click)="addFilter('FormofItem', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="FormofItemFacetLess.length >= facetLess"
                  (click)="MoreFormofItem(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="FormofItemFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of FormofItemFacet"
                (click)="addFilter('FormofItem', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreFormofItem(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- NatureOfContents -->
          <ng-container
            *ngIf="NatureOfContentsFacet && showNatureOfContents == 1"
          >
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Nature of contents" | translate }}
            </h5>
            <ul class="list-group" *ngIf="NatureOfContentsFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of NatureOfContentsFacetLess"
                (click)="addFilter('NatureOfContents', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="NatureOfContentsFacetLess.length >= facetLess"
                  (click)="MoreNatureOfContents(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul
              class="list-group"
              *ngIf="NatureOfContentsFacetLessShow == false"
            >
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of NatureOfContentsFacet"
                (click)="addFilter('NatureOfContents', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  (click)="MoreNatureOfContents(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- GovernmentPublication -->
          <ng-container
            *ngIf="GovernmentPublicationFacet && showGovernmentPublication == 1"
          >
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Government publication" | translate }}
            </h5>
            <ul class="list-group" *ngIf="GovernmentPublicationFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of GovernmentPublicationFacetLess"
                (click)="addFilter('GovernmentPublication', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="GovernmentPublicationFacetLess.length >= facetLess"
                  (click)="MoreGovernmentPublication(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul
              class="list-group"
              *ngIf="GovernmentPublicationFacetLessShow == false"
            >
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of GovernmentPublicationFacet"
                (click)="addFilter('GovernmentPublication', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  (click)="MoreGovernmentPublication(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- ConferencePublication -->
          <ng-container
            *ngIf="ConferencePublicationFacet && showConferencePublication == 1"
          >
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Conference publication" | translate }}
            </h5>
            <ul class="list-group" *ngIf="ConferencePublicationFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of ConferencePublicationFacetLess"
                (click)="addFilter('ConferencePublication', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="ConferencePublicationFacetLess.length >= facetLess"
                  (click)="MoreConferencePublication(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul
              class="list-group"
              *ngIf="ConferencePublicationFacetLessShow == false"
            >
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of ConferencePublicationFacet"
                (click)="addFilter('ConferencePublication', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  (click)="MoreConferencePublication(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Festschrift -->
          <ng-container *ngIf="FestschriftFacet && showFestschrift == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Festschrift" | translate }}
            </h5>
            <ul class="list-group" *ngIf="FestschriftFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of FestschriftFacetLess"
                (click)="addFilter('Festschrift', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="FestschriftFacetLess.length >= facetLess"
                  (click)="MoreFestschrift(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="FestschriftFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of FestschriftFacet"
                (click)="addFilter('Festschrift', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreFestschrift(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- LiteraryForm -->
          <ng-container *ngIf="LiteraryFormFacet && showLiteraryForm == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "LiteraryForm" | translate }}
            </h5>
            <ul class="list-group" *ngIf="LiteraryFormFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of LiteraryFormFacetLess"
                (click)="addFilter('LiteraryForm', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="LiteraryFormFacetLess.length >= facetLess"
                  (click)="MoreLiteraryForm(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="LiteraryFormFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of LiteraryFormFacet"
                (click)="addFilter('LiteraryForm', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreLiteraryForm(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>

          <!-- Biography -->
          <ng-container *ngIf="BiographyFacet && showBiography == 1">
            <h5
              class="list-group-item text-walai-default-color border-bottom my-3 fw-normal"
            >
              {{ "Biography" | translate }}
            </h5>
            <ul class="list-group" *ngIf="BiographyFacetLessShow">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of BiographyFacetLess"
                (click)="addFilter('Biography', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a
                  class="a-walai text-primary"
                  *ngIf="BiographyFacetLess.length >= facetLess"
                  (click)="MoreBiography(false)"
                  >more...</a
                >
              </li>
            </ul>

            <!-- When Show More -->
            <ul class="list-group" *ngIf="BiographyFacetLessShow == false">
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai text-walai-default-color a-walai"
                *ngFor="let s of BiographyFacet"
                (click)="addFilter('Biography', s.name)"
              >
                {{ s.name }}
                <span class="badge bg-secondary text-dark fw-normal">{{
                  s.count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-walai a-walai"
              >
                <a class="a-walai text-primary" (click)="MoreBiography(true)"
                  >...less</a
                >
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>

    <main class="col-12 col-lg-9 px-0">
      <nav class="navbar navbar-expand-lg bg-light px-3">
        <p
          *ngIf="bookcount == '...'"
          class="navbar-brand text-walai-default-color my-auto"
        >
          <strong>Loading...</strong>
        </p>
        <p
          class="navbar-brand text-walai-default-color my-auto"
          *ngIf="bookcount != null && bookcount != '...'"
        >
          {{ "Found" | translate }}: {{ bookcount | number }}
          {{ "Titles" | translate }}
        </p>

        <p
          *ngIf="bookcount == null"
          class="navbar-brand text-walai-default-color my-auto"
        >
          <strong>{{ "NotFound" | translate }}</strong>
        </p>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarText">
          <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item"></li>
        </ul> -->

          <div class="navbar-text ms-lg-auto py-0">
            <ul
              class="navbar-nav ml-auto mt-2 mt-lg-0 flex-row justify-content-end"
            >
              <li class="nav-item me-2">
                <select
                  id="inputState"
                  class="form-select rounded-4 py-2"
                  (change)="changeOrderBy($event)"
                >
                  <option value="RELEVANCE" selected>Relevance</option>
                  <option value="TITLE">Title A-Z</option>
                  <option value="PUBYEAR DESC">Pub date (newest)</option>
                  <option value="PUBYEAR ASC">Pub date (oldest)</option>
                  <option value="AUTHOR">Author A-Z</option>
                  <option value="CALLNUMBER">Call Number</option>
                </select>
              </li>
              <li class="nav-item me-2">
                <button
                  class="btn btn-outline-secondary rounded-4 py-2 my-auto"
                  (click)="DisplayStyle('Table')"
                >
                  <i class="fas fa-th-list"></i>
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="btn btn-outline-secondary rounded-4 py-2 my-auto"
                  (click)="DisplayStyle('List')"
                >
                  <i class="fas fa-th"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <!-- content -->
      <div class="container-fluid pt-4 px-5 position-relative">
        <button
          class="btn btn-primary my-3 d-lg-none btn-menu-left rounded-start"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasFilter"
          aria-controls="offcanvasFilter"
        >
          <i class="fa fa-bars text-white"></i> Filter
        </button>
     
        <mat-paginator *ngIf="recordCount>15"
        class="mb-2"
        aria-label="Select page"
        [pageSize]="Size"
        (page)="pageNavigations($event)"
        [length]="recordCount">
    </mat-paginator>

        <div *ngIf="bookcount != null ">
          <div   class="search-results"
          infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScroll()">
      
          <div
            class="search-results">
            <ul class="list-unstyled" *ngIf="DisplayTable">
              <li class="media mb-5 row" *ngFor="let b of bookList">
                <div class="text-center col-md-3">
                  <!-- <img src="{{ b.bookcover }}"
                    class="img-fluid img-thumbnail img-thumbnail-walai custom-img-shadow max-height-180"
                    onError="this.src='assets/images/bookcover/noimage.jpg'" /> -->
                  <app-book-cover-share
                    [bookCoverURL]="b.bookcover"
                    [id]="b.id"
                    [cssStyle]="
                      'img-fluid img-thumbnail img-thumbnail-walai custom-img-shadow '
                    "
                  ></app-book-cover-share>
                </div>
                <div class="col-md-9">
                  <h5 class="mt-3 mt-lg-0 mb-2">
                    <a
                      class="a-walai-book fw-semibold"
                      target="_blank"
                      href="bibitem?bibid={{ b.id }}"
                      >{{ b.Title | shortTitle : 135 }}</a
                    >
                  </h5>

                  <div class="row" *ngIf="b.Author">
                    <div class="col-md-2 text-secondary">
                      {{ "Author" | translate }}
                    </div>
                    <div class="col-md-9 text-dark">
                      {{ b.Author }}
                    </div>
                  </div>
                  <div class="row" *ngIf="b.Publisher">
                    <div class="col-md-2 text-secondary">
                      {{ "Published" | translate }}
                    </div>
                    <div class="col-md-9 text-dark">
                      {{ b.Publisher }}
                    </div>
                  </div>
                  <div class="row" *ngIf="b.CALLNUMBER">
                    <div class="col-md-2 text-secondary">
                      {{ "Callnumber" | translate }}
                    </div>
                    <div class="col-md-9 text-dark">
                      {{ b.CALLNUMBER }}
                    </div>
                  </div>
                  <div class="row" *ngIf="b.LOCATION">
                    <div class="col-md-2 text-secondary">
                      {{ "Location" | translate }}
                    </div>
                    <div class="col-md-9 text-dark">
                      {{ b.LOCATION }}
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-12">
                      <app-electronic-resource
                        [bibid]="b.id"
                      ></app-electronic-resource>

                      <span
                        class="border-end border-1 border-secondary mx-3"
                      ></span>

                      <button
                        type="button"
                        class="btn btn-primary btn-sm py-1 fw-light shadow"
                        (click)="save(b.id)"
                      >
                        <span
                          ><i class="bi bi-file-earmark-arrow-down"></i> Save
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div
              *ngIf="DisplayList"
              class="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-col-xl-4 row-col-xxl-4"
            >
              <figure class="col figure-bookcover" *ngFor="let b of bookList">
                <div class="DivBookCover">
                  <div class="text-center container-bookcover">
                    <a
                      class="a-walai-book"
                      target="_blank"
                      href="bibitem?bibid={{ b.id }}"
                    >
                      <app-book-cover-share
                        [bookCoverURL]="b.bookcover"
                        [id]="b.id"
                        [cssStyle]="'img-fluid custom-img-shadow'"
                      ></app-book-cover-share>
                    </a>
                  </div>
                </div>

                <h6 class="text-center mt-3">
                  <a
                    class="a-walai-book"
                    target="_blank"
                    href="bibitem?bibid={{ b.id }}"
                    >{{ b.Title | shortTitle : 35 }}</a
                  >
                </h6>
                <p class="text-center">
                  <small>{{ b.Author }}</small>
                </p>
              </figure>
            </div>
          </div>

          <mat-paginator *ngIf="recordCount>15"
          class="mb-2"
          aria-label="Select page"
          [pageSize]="Size"
          (page)="pageNavigations($event)"
          [length]="recordCount">
      </mat-paginator>

     </div>

          <!-- spinner -->
          <!-- <div class="row pb-5">
            <div class="col-md-12 p-5">
              <ngx-spinner
                bdOpacity="0.9"
                bdColor=""
                size="default"
                color="#581f87"
                type="ball-pulse-sync"
                [fullScreen]="false"
              >
              </ngx-spinner>
            </div>
          </div> -->

          <!-- Alert no more post -->
          <!-- <div *ngIf="!notEmptyPost" class="row pb-5">
            <div class="col-md-12 d-flex justify-content-center">
              <div class="text-muted alert p-2" style="font-weight: lighter">
                {{ "No more resource to show" | translate }}
              </div>
            </div>
          </div> -->
        </div>

         <!-- *ngIf=" suggesterWord.length>0 && (recordCount<1 ||recordCount>15)" -->

  
        <div class="card w-100 border-0" *ngIf=" suggesterWord.length>0 && (recordCount<1 ||recordCount>15)">
          <div class="card-body">
            <h5 class="card-title">หรือคุณหมายถึง: </h5>
            <span *ngFor="let item of suggesterWord" class="badge rounded-pill bg-primary me-2 mb-2 fw-normal fs-6 a-walai" (click)="search(false,item)"><i class="bi bi-search me-2"></i> {{item}}</span>
         
          </div>
        </div>
  
      </div>
    </main>
  </div>
</div>
