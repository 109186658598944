import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlphapetResult, Count, newsList, newsParam } from 'src/app/models/walai';
import { UtilityService } from 'src/app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  id :any ;
  result: any;

  notEmptyPost = true;
  constructor(
    private util: UtilityService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activateRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
     
    });
    this.search();
  }
  
  search(): void {
    this.spinner.show();
    this.notEmptyPost = false;
   
 
    this.util.getNews(this.id ).subscribe(result => {
    
      this.result = result;
      console.log( this.result)
      if (this.result.length === 0) {
        this.notEmptyPost = false;
        this.spinner.hide();
      } else {
        this.notEmptyPost = true;
        this.spinner.hide();
      }
     
    });
  }



}
