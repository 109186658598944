<div class="row mt-5">
  <div class="col-12">
    <div class="border-bottom mb-3">
      <h5 class="text-walai-default-color">Member Review</h5>
    </div>

    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="default"
      color="#581f87"
      type="ball-pulse-sync"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="card p-2 mb-3" *ngFor="let item of reviewData">
      <div class="row row-cols-auto justify-content-between p-2">
        <p class="card-text mb-0" [innerHTML]="item.REVIEWDETAIL"></p>
        <p class="text-muted mb-0">
          <small> {{ item.REVIEWBy }} : {{ item.REVIEWDATE }}</small>
        </p>
      </div>

      <div class="row row-cols-auto justify-content-end gap-2 pe-3">
        <button
          (click)="showEditModal(item)"
          class="btn btn-sm btn-outline-primary py-1"
          *ngIf="USERID == item.USERID"
          >
          <i class="bi bi-pencil-square"></i> Edit review</button>
        

        <button
          class="btn btn-sm btn-outline-danger py-1"
          *ngIf="USERID == item.USERID"
          (click)="deleteReview(item.REVIEWID)"
          ><i class="bi bi-trash"></i> {{ "Delete review" | translate }}
        </button>
      </div>
    </div>

    <!-- <a
      href="#"
      class="a-walai"
      *ngIf="addStatus && USERID"
      data-bs-toggle="modal"
      data-bs-target="#addModal"
      ><i class="bi bi-plus-square"></i> Add review</a
    > -->

    <button
      type="button"
      *ngIf="addStatus && USERID"
      class="btn btn-primary btn-sm py-1 fw-light shadow mt-2 ms-auto"
      data-bs-toggle="modal"
      data-bs-target="#addModal"
    >
      <i class="bi bi-plus-square me-1"></i> Add review
    </button>
  </div>
</div>

<!-- Modal Add -->
<div
  class="modal fade"
  id="addModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Add review" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form class="example-form" [formGroup]="addFG">
          <ckeditor [editor]="Editor" formControlName="value"></ckeditor>
          <section>
            <div class="sru-button-row" style="margin-top: 10px">
              <button
                (click)="Save('p')"
                [disabled]="!addFG.valid"
                class="btn btn-primary"
                style="margin-right: 4px"
                type="submit"
              >
                Publish review
              </button>
              <!-- <button  (click)="Save('d')"  [disabled]="!addFG.valid" class="btn btn-primary" type="submit">Save review as Draft </button> -->
            </div>
          </section>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close'|translate}}</button>
      --></div>
    </div>
  </div>
</div>

<!-- Modal Edit -->
<div
  class="modal fade"
  id="editModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Edit review" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form class="example-form" [formGroup]="editFG">
          <ckeditor [editor]="Editor" formControlName="value"></ckeditor>
          <section>
            <div class="sru-button-row" style="margin-top: 10px">
              <button
                (click)="edit('p')"
                [disabled]="!editFG.valid"
                class="btn btn-primary"
                style="margin-right: 4px"
                type="submit"
              >
                Publish review
              </button>
              <!-- <button  (click)="edit('d')"  [disabled]="!editFG.valid" class="btn btn-primary" type="submit">Save review as Draft </button> -->
            </div>
          </section>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close'|translate}}</button>
      --></div>
    </div>
  </div>
</div>
