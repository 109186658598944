import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareRoutingModule } from './share-routing.module';
import { BookCoverShareComponent } from './book-cover-share/book-cover-share.component';
import { ShortTitlePipe } from './short-title.pipe';


@NgModule({
  declarations: [
    BookCoverShareComponent,
    ShortTitlePipe
  ],
  imports: [
    CommonModule,
    ShareRoutingModule
  ],
  exports:[BookCoverShareComponent,ShortTitlePipe]
  
})
export class ShareModule { }
