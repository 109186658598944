import { Component, OnInit, Input } from '@angular/core';
import { catchError, multicast, switchMap } from 'rxjs/operators';
import { HomeService } from 'src/app/services/home.service';
import { MemberService } from 'src/app/services/member.service';
import Swal from 'sweetalert2';
import { RdDialogComponent } from 'src/app/bibitem/rd-dialog/rd-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getLocaleNumberSymbol } from '@angular/common';
import { of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpParameterCodec } from '@angular/common/http';
import { NDDtypeOpen } from 'src/app/models/modelNDD';
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css'],
})
export class ItemsComponent implements OnInit {
  @Input() bibid: string;
  @Input() title: string;
  @Input() mattypeID: string;
  locationID: string;
  locationName: string;
  currentUser: any[] = [];
  USERID: string = '';
  itemsLocation: Array<locations> = [];
  items: any[];
  itemsList: any[] = []; //รายการทั้งหมด
  itemsListShow: any[] = []; //ไว้แสดงผล
  itemsListLess: any[] = []; // แสดงขั้นต่ำ เช่น 2 รายการ
  lessItem = 3;
  LoaditemOk: boolean = false;
  getItemCheckOnMap: any;
  viewAll: boolean = false;
  showMap: Array<itemshowMap> = [];
  arrNDDTypeDefault =
    []; /**ตรวจสอบการเปิดใช้งาน RD -> กรณ๊บทความจะเช็คจากค่า default */

  BLOCKUSER = false;
  opacopenRD = false;
  bookListcheckout: any[] = [];
  curentCheckOut: any[] = [];
  NDDUserPrivilege = false;
  NDDTypeOpenSubcription: Subscription = new Subscription();
  constructor(
    private homeService: HomeService,
    private memberService: MemberService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    if (this.NDDTypeOpenSubcription) {
      this.NDDTypeOpenSubcription.unsubscribe();
    }
  }
  async ngOnInit() {
    /* สาขา ที่ถูกและจากด้านบนของ Web*/
    this.locationID = localStorage.getItem('locationIDMain'); /*location หลัก*/
    this.locationName = localStorage.getItem('locationNameMain');

    /**สำหรับตรวจสอบการเปิดใช้งาน RD (Realitem, copy, file) ถ้าปิดจะไม่แสดงปุ่ม RD*/
    this.CheckOpenRD();

    /*ตรวจสอบ user login*/
    this.currentUser =
      JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];

    if (this.currentUser.length != 0) {
      this.USERID = this.currentUser[0].USERID;
      //User มีสิทธิ์ request dd
      this.GetNDDUserPrivilege(this.USERID);
      this.GetBlockNotice(this.USERID);
      this.loadopacMemberBorrow(this.USERID);
    }

    if (this.bibid.indexOf('j') == -1) {
      await this.getShowmapbyItems(this.bibid);

      /**กรณีที่เป็นหนังสือจะมีการแสดงผลที่เพิ่มมามากกว่าบทความวารสาร เช่น บทความจะไม่มี Item*/
      this.getItems(this.bibid);
    }
  }

  //สำหรับแสดง Item ของหนังสือ โดยเวลาดึงจะดึงมาทั้งหมดทุกสาขาแล้วจะแสดงเฉพาะในในสาจาที่เลือกจาก Dropdown ก่อน โดยตั้งต้นจะแสดงสาขาที่เลือกจากด้านบนของ Web ก่อน
  getItems(bibid: string) {
    this.homeService
      .getItems(bibid)
      .pipe(
        switchMap(async (res: any) => {
          switch (res.status) {
            case 200:
              //ตัวแปรเก็บ Item ทั้งหมด
              this.items = res.body;
              //var output = Array[{LOCATIONID: string, LOCATIONNAMETHAI: string}];
              var flags = [],
                output = [],
                i: number;

              var l: number = 0;
              if (!this.items) {
                l = 0;
              } else {
                l = this.items.length;
              }

              for (i = 0; i < l; i++) {
                //วนเพื่อเก็บสาขาที่ไม่ซ้ำใน Dropdown
                if (flags[this.items[i].LOCATIONID]) continue;
                flags[this.items[i].LOCATIONID] = true;
                output.push(this.items[i].LOCATIONID);

                // แสดงข้อมูล Location ของ items
                let LocationObj = new locations();
                LocationObj.LOCATIONID = this.items[i].LOCATIONID;
                LocationObj.LOCATIONNAMETHAI = this.items[i].LOCATIONNAMETHAI;
                this.itemsLocation.push(LocationObj); //ตัวแปรนี้จะถูกแสดงใน Dropdown สาขาที่มี Item เท่านั้น

                /**Flag Default สำหรับเปิดใช้ RD จาก function GetNDDTyeOpenDefault() ;0 => ปิดปุ่ม RD
                 * (เก็บไว้ใน list item ไว้เช็คหน้า html)
                 */
                // this.items[i].FLAGNDDTYPEDEFAULT = 0;
                // if(this.arrNDDTypeDefault[0].FLAGNDDTYPE > 0){
                //   this.items[i].FLAGNDDTYPEDEFAULT = this.arrNDDTypeDefault[0].FLAGNDDTYPE;
                // }
              }

              /**สำหรับตรวจสอบการเปิดใช้งาน RD (Realitem, copy, file และ Home, Point, file) ของแต่ละ item
               * ;0 => ปิดปุ่ม RD*/
              //  await this.GetNDDTypeOpenByItem();

              this.SeleteAndDisplayItem(this.locationID); //แสดงรายการ Item ในสาขาที่ได้เลือก

              break;
            default: //ซ่อนตัวหมุนๆ
              this.LoaditemOk = true;
              this.itemsList = [];
              break;
          }
        }),
        catchError((e) => {
          this.LoaditemOk = true;
          this.itemsList = [];
          return of({});
        })
      )
      .subscribe();
  }

  /**แสดงข้อมูล Item ในสาขาที่ได้เลือก*/
  SeleteAndDisplayItem(LOCATIONID) {
    //  console.log( this.items);
    this.LoaditemOk = true;
    this.itemsList = [];
    this.items.forEach((element, i) => {
      if (LOCATIONID == '') {
        this.itemsList.push(element); //ตัวแปรเก็บข้อมูล items ในสาขาที่ได้เลือก
      } else {
        if (LOCATIONID == element.LOCATIONID) {
          this.itemsList.push(element); //ตัวแปรเก็บข้อมูล items ในสาขาที่ได้เลือก
        }
      }

      try {
        for (let map of this.showMap) {
          if (element.ITEMID == map.ITEMID) {
            this.itemsList[i].SHOWMAP = map.SHOWMAP;
          }
        }
      } catch (error) {}
    });

    //ตัดไว้แสดงผลขั้นต่ำ
    this.itemsListLess = [];
    for (let index = 0; index < this.lessItem; index++) {
      if (index < this.itemsList.length) {
        const element = this.itemsList[index];
        this.itemsListLess.push(element);
      }
    }

    this.itemsListShow = [];
    this.itemsListShow = this.itemsListLess;
    console.log( this.itemsListShow)
    this.viewAll = false;
  }

  //แสดงทุก items
  clickViewAll() {
    this.itemsListShow = [];
    this.itemsListShow = this.itemsList;
    this.viewAll = true;
  }

  /**เปลี่ยนกดเปลี่ยน Location ใน Dropdown*/
  ChangeLocation(LOCATIONID, LOCATIONNAME) {
    this.locationID = LOCATIONID; //location หลัก
    this.locationName = LOCATIONNAME;
    //แสดงข้อมูล Item ในสาขาที่ได้เลือก
    this.SeleteAndDisplayItem(LOCATIONID);
  }

  holdItem(barcode) {
    let val = encodeURIComponent(this.router.url);
    let url = '<a href=login?redirectURL=' + val + '>เข้าสู่ระบบ?</a>';
    if (this.currentUser.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'สมาชิกห้องสมุดเท่านั้นที่สามารถจองได้!',
        footer: url,
      });
    } else {
      //พบการ login
      this.USERID = this.currentUser[0].USERID;

      Swal.fire({
        title: 'ยืนยันการจอง ?',
        text: this.title,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          this.memberService
            .userHoldItem(this.USERID, barcode)
            .subscribe((result: any) => {
              if (result[0].result == 'false') {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed',
                  text: result[0].reason,
                });
              } else {
                Swal.fire({
                  title: result[0].reason,

                  icon: 'success',
                  html:
                    '<b>คิวที่ </b> ' +
                    result[0].queue +
                    '<br>' +
                    '<b> ประมาณการวันรับหนังสือคือวันที่ </b> ' +
                    result[0].daterecieve,
                  showCloseButton: true,
                });
              }
            });
        }
      });
    }
    // end ตรวจสอบ user login
  }

  CheckOpenRD() {
    try {
      this.homeService.CheckOpenRD().subscribe(async (result: boolean) => {
        this.opacopenRD = result;
        console.log(this.opacopenRD);
      });
    } catch (error) {
      this.opacopenRD = false;
    }

    // this.homeService.getParameter(key).subscribe(async (result: any) => {
    //   this.opacopenRD = result[0].VALUE;
    //   if (this.opacopenRD.toLowerCase() == 'true') {
    //     this.GetNDDTyeOpenDefault();
    //   }
    // });
  }

  loadopacMemberBorrow(USERID) {
    this.bookListcheckout = [];
    this.curentCheckOut = [];
    this.memberService.opacMemberBorrow(USERID).subscribe((book: any) => {
      this.bookListcheckout = book;

      this.bookListcheckout.forEach((element) => {
        if (element.BIBID == this.bibid) {
          this.curentCheckOut.push(element);
          // this.curentCheckOut =true;
        }
      });
    });
  }

  //User มีสิทธิ์ request dd
  GetNDDUserPrivilege(USERID) {
    this.NDDUserPrivilege = false;
    this.memberService.getNDDUserPrivilege(USERID).subscribe((value: any) => {
      try {
        this.NDDUserPrivilege = value;
      } catch (error) {}
    });
  }

  GetBlockNotice(USERID) {
    this.memberService.getBlockNotice(USERID).subscribe((value: any) => {
      try {
        if (value.Counts[0].num != '0') {
          this.BLOCKUSER = true;
        }
      } catch (error) {}
    });
  }

  GetNDDTyeOpenDefault() {
    this.homeService.getNDDRequestTypeOpen('').subscribe((requesttype: any) => {
      let NDDtypeopen = new objtypeopen();
      NDDtypeopen.REALITEMFLAG = requesttype[0].realitemflag;
      NDDtypeopen.COPYFLAG = requesttype[0].copyflag;
      NDDtypeopen.FILEFLAG = requesttype[0].fileflag;
      NDDtypeopen.ITEMCLASSID = 0;
      NDDtypeopen.COLLECTIONID = '';
      NDDtypeopen.ITEMID = '';

      let countFlag =
        Number(requesttype[0].realitemflag) +
        Number(requesttype[0].copyflag) +
        Number(requesttype[0].fileflag);
      NDDtypeopen.FLAGNDDTYPE = countFlag;
      this.arrNDDTypeDefault.push(NDDtypeopen);
    });
  }

  CheckNDDTypeOpen(locationidList: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.NDDTypeOpenSubcription = this.memberService
        .NDDTypeOpen(locationidList)
        .pipe(
          switchMap(async (res: any) => {
            switch (res.status) {
              case 200:
                resolve(true);
                break;
              default:
                reject(false);
                break;
            }
          }),
          catchError((e) => {
            reject(false);

            return of([]);
          })
        )
        .subscribe();
    });
  }

  // async GetNDDTypeOpenByItem() {
  //   this.items.forEach((elem) => {
  //     elem.FlagNDDCOUNTOPEN = 0;
  //     elem.FlagNDDRQOPEN = 0;

  //     this.homeService
  //       .getNDDRequestTypeOpen(elem.ITEMID)
  //       .subscribe((requesttype: any) => {
  //         elem.FlagNDDCOUNTOPEN =
  //           Number(requesttype[0].REALITEMFLAG) +
  //           Number(requesttype[0].COPYFLAG) +
  //           Number(requesttype[0].FILEFLAG);

  //         if (elem.FlagNDDCOUNTOPEN > 0) {
  //           this.homeService /**flag เปิด RD (Home, Point, File) ของ item -> 0 == ปิด*/
  //             .getNDDTypeOpen(elem.LOCATIONID)
  //             .subscribe((typeopen: any) => {
  //               let datatype = typeopen.NDDtypeOpen[0];

  //               elem.FlagNDDRQOPEN =
  //                 Number(datatype.HOMEDELFLAG) +
  //                 Number(datatype.POINTDELFLAG) +
  //                 Number(datatype.FILEDELFLAG);
  //             });
  //         }
  //       });
  //   });
  // }

  replaceSpace(str: string) {
    if (str != null) {
      str = str.replace(' ', '');
    }

    return str;
  }

  async getShowmapbyItems(bibid) {
    /*ข้อมูล Showmap ของแต่ละ item */
    await (
      await this.homeService.getItemShowmap(bibid)
    ).subscribe((itemsShowmap: any) => {
      for (let element of itemsShowmap) {
        let itemmap = new itemshowMap();
        itemmap.SHOWMAP = element.SHOWMAP;
        itemmap.ITEMID = element.ITEMID;
        this.showMap.push(itemmap);
      }
    });
  }
}

/**locations.interface*/
export class locations {
  LOCATIONID: string;
  LOCATIONNAMETHAI: string;
}

export class itemshowMap {
  SHOWMAP: string;
  ITEMID: string;
}

export class objtypeopen {
  ITEMCLASSID: number;
  COLLECTIONID: string;
  REALITEMFLAG: number;
  COPYFLAG: number;
  FILEFLAG: number;
  ITEMID: string;
  FLAGNDDTYPE: number;
}

export class objTypeRequest {
  LOCATIONID: number;
  LOCATIONNAME: string;
  HOMEDELFLAG: number;
  POINTDELFLAG: number;
  FILEDELFLAG: number;
  FLAGTYPEOPEN: number;
}
