<div *ngIf="tagCloudData.length>0">
<h1 class="w-100 h1-main mt-5" >Tag cloud</h1>
<div class="mb-4" >
    <a   *ngFor="let item of tagCloudData| slice: 0:100; let i = index"   target="_blank"
    href='results?Ntk=TAGSFACET{{ntkLocation}}&Ntt="{{ item.Value }}"{{nttLocation}}'><span class="badge bg-light {{item.CSS}} ">#{{item.Value}}</span>
</a>

  </div>

  <div class="w-100" style="text-align: center" >
    <!-- Button trigger modal -->
    <button
      type="button"
      id="btnAllTagCloud"
      class="btn btn-light button-radius1 btn-walai-default btn-sm py-1"
      data-bs-toggle="modal"
      data-bs-target="#modalAllTagCloud"
    >
      {{ "SeeAll" | translate }}
    </button>
  </div>

  



</div> 
 <!-- Modal All -->
    <div
    class="modal fade"
    id="modalAllTagCloud"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "Tag cloud" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
                       <a   *ngFor="let item of tagCloudData"   target="_blank"
            href='results?Ntk=TAGSFACET{{ntkLocation}}&Ntt="{{ item.Value }}"{{nttLocation}}'><span class="badge bg-light {{item.CSS}} ">#{{item.Value}}</span>
        </a>
            </div>
     
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>