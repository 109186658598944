import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MemberService } from 'src/app/services/member.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { HomeService } from 'src/app/services/home.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private memberService: MemberService,
    private translate: TranslateService,
    private data: DataService,
    private homeService: HomeService,
    private adminService: AdminService
  ) {
    this.adminService.getLogoBanner('logo').subscribe(async (data: any) => {
      this.logo = data;
    });

    this.adminService
      .GetOpacConfigValue('Sitename')
      .subscribe(async (data: any) => {
        if (data == 'SRU Library') {
          this.userLogin = 'MemberID';
        }
      });
  }

  loginForm: UntypedFormGroup;

  login: login;

  submitted = false;

  Onlogin = false;
  logo = '';
  redirectURL = null;

  userLogin = 'EmailAddressOrUsername';

  onSubmit() {
    this.submitted = true;
  }

  ngOnInit(): void {
    try {
      this.redirectURL =
        this.activateRoute.snapshot.queryParamMap.get('redirectURL');
    } catch (error) {
      this.redirectURL = null;
    }

    if (this.redirectURL != null) {
      this.redirectURL = decodeURIComponent(this.redirectURL);
    }

    this.loadForm();
    
  }

  // ประกาดตัวแปร Form และภายใน Form
  loadForm() {
    this.loginForm = this.fb.group({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  // กำหนดค่าให้แก่ ตัวแปร Username + Password
  prepareData() {
    this.login = new login();
    this.login.username = this.loginForm.controls.username.value;
    this.login.password = this.loginForm.controls.password.value;
  }

  // กดปุ่ม Submit
  loginOK() {
    this.Onlogin = true;
    this.prepareData();

    this.memberService.userLogin(this.login).subscribe(
      (getData: any) => {
        if (getData) {
        
          if (getData.length > 0) {
            if (getData[0].LOCKED == 1) {
              this.ErrorlogIn(
                this.translate.instant('This username has locked!')
              );
            } else {
              if (getData[0].USERSTATUS == 2 || getData[0].USERSTATUS == 4) {
                if (getData[0].USERSTATUS == 2) {
                  this.ErrorlogIn(
                    this.translate.instant('This username has expired!')
                  );

                  setTimeout(() => {
                    this.redirectToMain(getData)
                }, 1000);
                 
                }
                if (getData[0].USERSTATUS == 4) {
                  this.ErrorlogIn(
                    this.translate.instant('This username is retired!')
                  );
                }
              } else {
                this.redirectToMain(getData)
              }
            }
          } else {
            this.ErrorlogIn(
              this.translate.instant(
                'Invalid email or password, please try again!'
              )
            );
          }
        } else {
          this.ErrorlogIn(
            this.translate.instant(
              'Invalid email or password, please try again!'
            )
          );
        }
      },
      (error) => {
        this.ErrorlogIn(
          this.translate.instant('Invalid email or password, please try again!')
        );
      }
    );
  }

redirectToMain(getData:any){
  this.Onlogin = false;
  localStorage.setItem(
    'WALAIcurrentUser',
    JSON.stringify(getData)
  );
  this.data.updateMemberImg(getData[0].PATHPIC);

  if (this.redirectURL != null) {
    window.location.href = this.redirectURL;
  } else {
    window.location.href = 'member';
  }
}

  ErrorlogIn(msg: string) {
    this.Onlogin = false;
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: msg,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  help() {
    let help = '';
    this.homeService.getParameter('loginHelp').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        help = VALUE[0].VALUE;

        Swal.fire({
          title: '<strong>' + this.translate.instant('login') + '</strong>',
          icon: 'info',
          html: help,
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
        });
      }
    });
  }


  CloseMember(){
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'ระบบสมาชิก ไม่พร้อมให้บริการ!',
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
      allowOutsideClick: false
      //timer: 30000
    }).then((result) => {
      if (result.isConfirmed) {
        this.route.navigateByUrl('/home')
      }
    });

  }
}

export class login {
  public username: string;
  public password: string;
}

