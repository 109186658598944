
import { Component, Inject, Renderer2 } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HomeService } from './services/home.service';
import {Title} from "@angular/platform-browser";
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
 // title = 'opac1234';
  networkOk = true;

  constructor(
    private homeService: HomeService,
    private titleService:Title, 
    public router: Router,
    private _renderer2 : Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {

    //this.CookieConsent()

    this.networkOk =true

    this.homeService
      .getParameterPipe('TitleEng')
      .pipe(
        switchMap(async (res: any) => {
          switch (res.status) {
            case 200:
              this.titleService.setTitle(res.body[0].VALUE);
          }
        }),
        catchError((e) => {
          this.titleService.setTitle('OPAC...');
          return of({});
        })
      )
      .subscribe();



    this.homeService
      .getParameterPipe('LanguageCode')
      .pipe(
        switchMap(async (res: any) => {
          switch (res.status) {
            case 200:
              this.networkOk =true
              if (res.body.length == 1) {
                if (res.body[0].VALUE === 'en-GB') {
                  localStorage.setItem('lang', 'en');
                } else {
                  localStorage.setItem('lang', 'th');
                }
              } else {
                localStorage.setItem('lang', 'en');
              }
              break;
            default:
              break;
          }
        }),
        catchError((e) => {
          this.networkOk=false;
          return of({});
        })
      )
      .subscribe();

      //Google Analytics
      // this.router.events.subscribe((event) => {
      //   if (event instanceof NavigationEnd) {
      //     gtag('config', 'MEASUREMENT-ID', { 'page_path': event.urlAfterRedirects });
      //   }      
      // })

      
      //this.$gaService.pageView('/opac')

     //this.CookieConsent()

  }

  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // let scrollToTop = window.setInterval(() => {
    //     let pos = window.pageYOffset;
    //     if (pos > 0) {
    //       window.scrollTo(0, pos - 100); // how far to scroll on each step
    //     } else {
    //         window.clearInterval(scrollToTop);
    //     }
    // }, 16);
  }


  ngOnDestroy(): void {
    const scriptElement = document.getElementById('cookieWow');
    console.log(scriptElement)
    if (scriptElement) {
      scriptElement.remove();
    }
  }


}
