import { Component } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-cookiepolicy-setting',
  templateUrl: './cookiepolicy-setting.component.html',
  styleUrl: './cookiepolicy-setting.component.css'
})
export class CookiepolicySettingComponent {
  pdpaUrl: '';

  constructor(private homeService : HomeService){
    let result =  []
    this.homeService.getParameter('PDPAUrl').subscribe({
      next:(VALUE) => {
        result = VALUE
      },
      complete:() => {
        if (result.length == 1) {
          this.pdpaUrl = result[0].VALUE;
        } 
      },
      error:() => {
        this.pdpaUrl =  ''
      }
    });
  }
}
