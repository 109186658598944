<header class="p-3 border-bottom">
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start"
    >
      <a 
        class="btn text-dark text-decoration-none h6 mt-lg-3"
        [routerLink]="['/']"
      >
        <span style="vertical-align:middle">
          <img
            src="{{ logo }}"
            onError="this.src='assets/images/logo_loading.png'"
       
            class="d-inline-block  me-1 logo"
            alt=""
          />
          <b class="align-middle ">{{ brandheading }}</b>
      
        </span>
        <!-- <small>Demo OPAC</small> -->
      </a>

      <ul
        class="nav col-12 col-lg-6 col-xl-auto text-start me-xl-auto mb-3 ms-3 justify-content-center justify-content-lg-start mb-lg-0 mt-lg-3"
      >
        <li>
          <button
            type="button"
            class="btn btn-outline-primary py-1 px-2 me-2"
            data-bs-toggle="modal"
            data-bs-target="#browseModal"
          >
            <i class="bi bi-globe"></i> Browse
          </button>
        </li>

        <li class="nav-item dropdown">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle py-1 px-2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-geo-alt"></i> {{ locationNameMain }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                href="#"
                (click)="ChangeLocation('', 'ทุกสาขา')"
                ><i class="bi bi-geo-alt"></i>
                {{ "AllLocation" | translate }}</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                *ngFor="let l of locationList"
                (click)="ChangeLocation(l.LOCATIONID, l.LOCATIONNAME)"
                ><i class="bi bi-geo-alt"></i> {{ l.LOCATIONNAME }}</a
              >
            </li>
          </ul>
        </li>
      </ul>

      <form
        class="col-12 col-lg-auto mb-3 mb-xl-0 mt-lg-3"
        role="search"
        [formGroup]="qsearchForm"
      >
        <input
          type="search"
          class="form-control"
          placeholder="{{ 'Search' | translate }}..."
          formControlName="textSearch"
          id="TextSearch"
          (keydown.enter)="search()"
          aria-label="Search"
        />
      </form>

      

      <div class="ms-lg-auto row row-cols-auto me-lg-1 mt-lg-3 me-xl-0 gap-2">
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle py-1 px-2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              *ngIf="CurrentLang == 'en'; else lang_th"
              width="24px"
              src="assets/images/lang/United-kingdom_29738.png"
            />
            <ng-template #lang_th
              ><img width="24px" src="assets/images/lang/th_icon_127999.png"
            /></ng-template>
          </button>
  
          <ul class="dropdown-menu">
            <li>
              <button
                type="button"
                class="dropdown-item"
                (click)="changeLanguage('th')"
              >
                <img width="24px" src="assets/images/lang/th_icon_127999.png" />
                ภาษาไทย
              </button>
            </li>
            <li>
              <button
              type="button" class="dropdown-item"  (click)="changeLanguage('en')">
                <img
                  width="24px"
                  src="assets/images/lang/United-kingdom_29738.png"
                />
                English</button
              >
            </li>
          </ul>
        </div>


        <button
          type="button"
          (click)="export()"
          title="บันทึกเพื่อส่งออก"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          class="btn btn-outline-primary position-relative py-1 px-2"
        >
          <i class="bi bi-file-earmark-arrow-down"></i>
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          >
            {{ listBib.length }}
          </span>
        </button>

        <button
          class="btn btn-primary py-1 px-2"
          type="submit"
          [routerLink]="['/login']"
          *ngIf="currentUser.length == 0"
        >
          Log in
        </button>

        <div class="dropdown" *ngIf="currentUser.length > 0">

          <button
            type="button"
            class="btn btn-outline-primary p-1 pe-2 dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              [routerLink]="['/member']"
              src="{{ MemberImg }}"
              onError="this.src='assets/images/member-icon.jpg'"
              width="25"
              height="25"
              class="rounded-circle"
              id="dropdownMenuButtonUser"
              data-bs-toggle="dropdown"
            />
          </button>

          <ul class="dropdown-menu text-small">
            <li>
              <a class="dropdown-item" href="member"
                ><i class="bi bi-person-lines-fill"></i> Profile</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="admin" *ngIf="isAdmin == '1'"
                ><i class="bi bi-gear"></i> Admin settings</a
              >
            </li>

            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a class="dropdown-item" href="#" (click)="SiginOut()"
                ><i class="bi bi-box-arrow-right"></i> Sign out</a
              >
            </li>
          </ul>
        </div>


      </div>
    </div>
  </div>
</header>

<!-- Modal -->
<div
  class="modal fade"
  id="browseModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Browse</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="accordion" id="accordionExample">

        
        


          <!-- LC & NLM call number -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{ "LC & NLM call number" | translate }}
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let lc of callNoLC">
                    <a
                      class="wl-font-color-gray-v1"
                      href="results?Ntk=CALLNUMBERLC&Ntt={{ lc.value }}*"
                      >{{ lc.display }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- DC call number -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading4">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordioncallNoDC"
                aria-expanded="false"
                aria-controls="accordioncallNoDC"
              >
                {{ "DC call number" | translate }}
              </button>
            </h2>
            <div
              id="accordioncallNoDC"
              class="accordion-collapse collapse"
              aria-labelledby="heading4"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let lc of callNoDC">
                    <a
                      class="wl-font-color-gray-v1"
                      href="results?Ntk=CALLNUMBERDC&Ntt={{ lc.value }}*"
                      >{{ lc.display }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Local call number -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading5">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionCALLNUMBERLOCAL"
                aria-expanded="false"
                aria-controls="accordionCALLNUMBERLOCAL"
              >
                {{ "Local call number" | translate }}
              </button>
            </h2>
            <div
              id="accordionCALLNUMBERLOCAL"
              class="accordion-collapse collapse"
              aria-labelledby="heading5"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let lc of callNoLocal">
                    <a
                      class="wl-font-color-gray-v1"
                      href="results?Ntk=CALLNUMBERLOCAL&Ntt={{ lc.value }}*"
                      >{{ lc.display }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Collection -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading6">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCollection"
                aria-expanded="false"
                aria-controls="collapseCollection"
              >
                {{ "Collection" | translate }}
              </button>
            </h2>
            <div
              id="collapseCollection"
              class="accordion-collapse collapse"
              aria-labelledby="heading6"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="mt-4" *ngFor="let lo of locationCollectionResponse">
                  <p class="h5">{{ lo.LOCATIONNAMETHAI }}</p>
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item"
                      *ngFor="let col of lo.collections"
                    >
                      <a
                        class="wl-font-color-gray-v1"
                        href="results?Ntk=LOCATION|COLLECTION&Ntt={{
                          lo.locationid
                        }}|{{ col.Value }}"
                        >{{ col.DISPLAY }}</a
                      >
                      <!-- nPage=1&Ntk=LOCATION|COLLECTION&Ntt=1|76 -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

                    <!-- SourceType -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSourceType"
                          aria-expanded="true"
                          aria-controls="collapseSourceType"
                        >
                          {{ "SourceType" | translate }}
                        </button>
                      </h2>
                      <div
                        id="collapseSourceType"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item"
                              *ngFor="let type of materialTypes"
                            >
                              <a
                                class="wl-font-color-gray-v1"
                                href="results?Ntk=MEDIATYPE&Ntt={{ type.MATTYPEID }}"
                                >{{ type.MATTYPENAME }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
          
                    <!-- Language -->
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseLanguage"
                          aria-expanded="false"
                          aria-controls="collapseLanguage"
                        >
                          {{ "Language" | translate }}
                        </button>
                      </h2>
                      <div
                        id="collapseLanguage"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ng-container
                            *ngIf="moreLang === false; then show10Lang; else showAllLang"
                          ></ng-container>
                          <ng-template #show10Lang>
                            <ul class="list-group list-group-flush">
                              <li
                                class="list-group-item"
                                *ngFor="let lang of langs | slice: 0:10; let i = index"
                              >
                                <a
                                  class="wl-font-color-gray-v1"
                                  href="results?Ntk=LANG&Ntt={{ lang.LANGCODE }}"
                                  >{{ lang.Display }}</a
                                >
                              </li>
                              <li class="list-group-item">
                                <a class="wl-font-color-default" (click)="showMore()"
                                  >more..</a
                                >
                              </li>
                            </ul>
                          </ng-template>
                          <ng-template #showAllLang>
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item" *ngFor="let lang of langs">
                                <a
                                  class="wl-font-color-gray-v1"
                                  href="results?Ntk=LANG&Ntt={{ lang.LANGCODE }}"
                                  >{{ lang.Display }}</a
                                >
                              </li>
                              <li class="list-group-item">
                                <a class="wl-font-color-default" (click)="showLess()"
                                  >..less</a
                                >
                              </li>
                            </ul>
                          </ng-template>
                        </div>
                      </div>
                    </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
