import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import {marcHolding,marc} from 'src/app/models/walai';
@Component({
  selector: 'app-marcholding',
  templateUrl: './marcholding.component.html',
  styleUrls: ['./marcholding.component.css']
})
export class MarcholdingComponent implements OnInit {
  @Input() bibid: string;
  holdings :marcHolding[];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.getMarcHolding(this.bibid).subscribe((holdings: marcHolding[]) => {
      //ตัวแปรเก็บ Item ทั้งหมด
     this.holdings = holdings;

   });
  }

}
