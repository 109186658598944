import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AlphapetResult, AuthSeeAlso, AuthSymbol, SearchParameter } from '../models/walai';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // authorization: ''
  })
};

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseUrl = '';

  header = new HttpHeaders();
  constructor(private http: HttpClient) { this.header.append('Content-Type', 'application/json'); }


  keywordsearch(val: any) {
    this.baseUrl = `${environment.apiurl}/api/BookKeywordSearch`;
   // console.log(val);
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });

  }


  autoSuggest(val: any, num: number) {
    this.baseUrl = `${environment.apiurl}/api/autosuggest/${num}`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });

  }
  alphabetSearch(val: SearchParameter): Observable<AlphapetResult>{
  
    return this.http.post<any>(`${environment.apiurl}/api/AlphabeticSearch`, val, { headers: this.header });
  }


  AuthSeeAlso(val: any) {
    this.baseUrl = `${environment.apiurl}/api/CheckSeeAlso`;
    return this.http.post<AuthSeeAlso[]>(this.baseUrl, val, { headers: this.header });
  }

  AuthSymbol(authid: string,Type:string) {
    this.baseUrl = `${environment.apiurl}/api/GetAuthSymbol/${authid}/${Type}`;
    return this.http.post<AuthSymbol[]>(this.baseUrl, { headers: this.header });
  }

  addDownloadHistory(val: any) {
  
    this.baseUrl = `${environment.apiurl}/api/DownloadHistory`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });

  }

  keywordSuggester(val: any) {
    this.baseUrl = `${environment.apiurl}/api/suggester`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });

  }

}
