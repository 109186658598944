import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { newsParam } from '../models/walai';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  header = new HttpHeaders();
  token = environment.token;
  apiUrl = `${environment.apiurl}`;
  headers = {
    headers: new HttpHeaders().set('token', this.token).set('Content-Type', 'application/json')
  };
  constructor(
    private http: HttpClient
  ) { }
  public getWalaiMaterailType(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/MaterialType`, this.headers);
  }
  public getWalaiCollection(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/Collection`, this.headers);
  }
  public getWalaiLang(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/getlange`, this.headers);
  }
  public getWalaiCountry(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/Country`, this.headers);
  }

  getNewsList(val: newsParam): Observable<any>{
    return this.http.post<any>(`${environment.apiurl}/api/getNews`, val, { headers: this.header });
  }

  public getNews(id:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/getNews/${id}`, this.headers);
  }


  public getBookTha(isbn: string) {
    var _cover = 'assets/images/bookcover/noimage.jpg';
    if (isbn != '') {
      let p1 = isbn.substring(0, 6);
      let p2 = isbn.substring(6, 9);
      // console.log(isbn);
      _cover =
        'https://images-se-ed.com/ws/Storage/Originals/' +
        p1 +
        '/' +
        p2 +
        '/' +
        isbn +
        'L.jpg';
    }

    return _cover

  }

  public getISBN(isbn: string) {
    // var _cover = 'assets/images/bookcover/noimage.jpg';
     var isbn13 = isbn;
     if (isbn.match(/^\d+$/) !== null) {
     } else {
       if (isbn.length > 13) {
         var isbnlis;
         isbnlis = isbn.split(',');
         isbnlis.forEach((element) => {
           try {
             if (isbn13.length != 13) {
               isbn13 = element.substring(0, 13);
             }
           } catch (error) {}
         });
       }
 
     }
 
     return isbn13
    
   }

  public getBookEng(isbn: string) {
   // var _cover = 'assets/images/bookcover/noimage.jpg';
    var isbn13 = isbn;
    if (isbn.match(/^\d+$/) !== null) {
    } else {
      if (isbn.length > 13) {
        var isbnlis;
        isbnlis = isbn.split(',');
        isbnlis.forEach((element) => {
          try {
            if (isbn13.length != 13) {
              isbn13 = element.substring(0, 13);
            }
          } catch (error) {}
        });
      }

    }
    if(isbn13==''){
      return 'assets/images/bookcover/noimage.jpg';
    }else{
      return 'https://covers.openlibrary.org/b/isbn/' + isbn13 + '-L.jpg';
    }
   
  }

     // CHECK IF IMAGE EXISTS
     checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
  
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
  
        img.onerror = () => {
          callback(false);
        };
      }
    }
 

}
