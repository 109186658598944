<div class="container mt-4">
    <div class="d-flex gap-3 align-items-center mb-3">
           <p class="h2 text-primary my-2">นโยบายคุกกี้ (Cookies Policy)</p> 
           <a [href]="pdpaUrl" [hidden]="pdpaUrl == null || pdpaUrl  == ''" class="btn btn-primary" target="_blank" rel="Privacy Policy"><span> นโยบายส่วนบุคคล (Privacy Policy)</span></a>
    </div>


    <p class="text-wrap">
        <span class="ps-4"></span> เมื่อท่านได้เข้าสู่เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
        ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ ของท่านจะถูกบันทึกไว้ในรูปแบบของคุกกี้
        โดยนโยบายคุกกี้นี้จะอธิบายถึงความหมาย การทำงาน วัตถุประสงค์ รวมถึงการลบและการปฏิเสธการเก็บคุกกี้
        เพื่อความเป็นส่วนตัวของท่าน
        โดยการเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ที่มีรายละเอียดดังต่อไปนี้
    </p>

    <p class="h3 text-primary">คุกกี้คืออะไร</p>
    <blockquote class="border-start border-primary border-2 ps-3">
        <span class="ps-4"></span> คุกกี้ คือ ไฟล์เล็ก ๆ เพื่อจัดเก็บข้อมูลการเข้าใช้งานเว็บไซต์ เช่น วันเวลา
        ลิงค์ที่คลิก หน้าที่เข้าชม เงื่อนไขการตั้งค่าต่าง ๆ โดยจะบันทึกลงไปในอุปกรณ์คอมพิวเตอร์ และ/หรือ
        เครื่องมือสื่อสารที่เข้าใช้งานของท่าน เช่น โน๊ตบุ๊ค แท็บเล็ต หรือ สมาร์ทโฟน
        ผ่านทางเว็บเบราว์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์ โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์คอมพิวเตอร์ และ/หรือ
        เครื่องมือสื่อสารของท่าน ในกรณีดังต่อไปนี้
        ข้อมูลส่วนบุคคลของท่านอาจถูกจัดเก็บเพื่อใช้เพิ่มประสบการณ์การใช้งานบริการทางออนไลน์
        โดยจะจำเอกลักษณ์ของภาษาและปรับแต่งข้อมูลการใช้งานตามความต้องการของท่าน เป็นการยืนยันคุณลักษณะเฉพาะตัว
        ข้อมูลความปลอดภัยของท่าน รวมถึงบริการที่ท่านสนใจ
        นอกจากนี้คุกกี้ยังถูกใช้เพื่อวัดปริมาณการเข้าใช้งานบริการทางออนไลน์
        การปรับเปลี่ยนเนื้อหาตามการใช้งานของท่านโดยพิจารณาจากพฤติกรรมการเข้าใช้งานครั้งก่อน ๆ และ ณ ปัจจุบัน
        และอาจมีวัตถุประสงค์เพื่อการโฆษณาประชาสัมพันธ์
        ทั้งนี้ท่านสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ได้ที่ www.allaboutcookies.org
    </blockquote>


    <p class="h3 text-primary">ระบบห้องสมุดอัตโนมัติ Walai AutoLib ใช้คุกกี้อย่างไร</p>
    <blockquote>
        <p><span class="ps-4"></span>
            ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ใช้คุกกี้ เพื่อบันทึกการเข้าเยี่ยมชมและเข้าใช้งานเว็บไซต์ของท่าน
            โดยทำให้ระบบห้องสมุดอัตโนมัติ WALAI AutoLib สามารถจดจำการใช้งานเว็บไซต์ของท่านได้ง่ายขึ้น
            และข้อมูลเหล่านี้จะถูกนำไปเพื่อปรับปรุงเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
            ให้ตรงกับความต้องการของท่านมากยิ่งขึ้น เพื่ออำนวยความสะดวกให้เกิดความรวดเร็วในการใช้งานเว็บไซต์ของท่าน
            และในบางกรณี ระบบห้องสมุดอัตโนมัติ WALAI AutoLib จำเป็นต้องให้บุคคลที่สามช่วยดำเนินการดังกล่าว ซึ่งอาจจะต้องใช้
            อินเตอร์เน็ตโปรโตคอลแอดเดรส (IP Address) และคุกกี้เพื่อวิเคราะห์ทางสถิติ ตลอดจนเชื่อมโยงข้อมูล
            และประมวลผลตามวัตถุประสงค์ทางการตลาด
        </p>
        <p class="fw-bold">คุกกี้ที่ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ใช้ อาจจะแบ่งได้ 2 ประเภทตามการจัดเก็บ ดังนี้</p>
        <ul class="list-unstyled">
            <li>
                1. <span class="font-weight-bold">Session Cookies</span>
                เป็นคุกกี้ที่จะอยู่ชั่วคราวเพื่อจดจำท่านในระหว่างที่ท่านเข้าเยี่ยมชมเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI
                AutoLib เช่น เฝ้าติดตามภาษาที่ท่านได้ตั้งค่าและเลือกใช้ เป็นต้น
                และจะมีการลบออกจากเครื่องคอมพิวเตอร์หรืออุปกรณ์ของท่าน เมื่อท่านออกจากเว็บไซต์หรือได้ทำการปิดเว็บเบราว์เซอร์
            </li>
            <li>
                2. <span class="font-weight-bold">Persistent Cookie</span>
                เป็นคุกกี้ที่จะอยู่ตามระยะเวลาที่กำหนดหรือจนกว่าท่านจะลบออก
                คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib จดจำท่านและการตั้งค่าต่าง ๆ
                ของท่านเมื่อท่านกลับมาใช้บริการเว็บไซต์อีกครั้ง
                ซึ่งจะช่วยให้ท่านเข้าใช้บริการเว็บไซต์ได้สะดวกรวดเร็วยิ่งขึ้น
            </li>
        </ul>
        <p class="fw-bold">วัตถุประสงค์ในการใช้งานคุกกี้ที่ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ใช้
            มีรายละเอียดดังนี้</p>
    
        <ul>
            <li><span class="fw-bold">คุกกี้ที่มีความจำเป็น (Strictly Necessary Cookies)</span>
                คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้ รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์
                การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ท่านไม่สามารถใช้บริการในสาระสำคัญของระบบห้องสมุดอัตโนมัติ WALAI
                AutoLib ซึ่งจำเป็นต้องเรียกใช้คุกกี้ได้</li>
            <li><span class="ffw-bold">คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน (Performance Cookies)</span>
                คุกกี้ประเภทนี้ช่วยให้ระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                ทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ
                ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์
                และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น ถึงแม้ว่า
                ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น
                การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                ไม่สามารถทราบปริมาณผู้เข้าเยี่ยมชมเว็บไซต์ และไม่สามารถประเมินคุณภาพการให้บริการได้</li>
            <li><span class="fw-bold">คุกกี้เพื่อการใช้งานเว็บไซต์ (Functional Cookies)
                </span>คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib จดจำตัวเลือกต่าง ๆ
                ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้ เช่น
                ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน หรือจดจำการเปลี่ยนแปลงการตั้งค่าขนาดฟอนต์หรือการตั้งค่าต่าง ๆ
                ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้
                การปิดการใช้งานคุกกี้ประเภทนี้อาจส่งผลให้เว็บไซต์ไม่สามารถทำงานได้อย่างสมบูรณ์</li>
            <li><span class="fw-bold">คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย (Targeting Cookies)
                </span>คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม
                ซึ่งเก็บข้อมูลการเข้าใช้งานและเว็บไซต์ที่ท่านได้เข้าเยี่ยมชม
                เพื่อนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์ของ ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ทั้งนี้
                หากท่านปิดการใช้งานคุกกี้ประเภทนี้จะไม่ส่งผลต่อการใช้งานเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                แต่จะส่งผลให้การนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่น ๆ ไม่สอดคล้องกับความสนใจของท่าน</li>
        </ul>
    
        <table class="table table-bordered">
            <thead>
                <tr class="bg-light">
                    <th scope="col" class="text-center">ประเภทของคุกกี้</th>
                    <th scope="col" class="text-center">รายละเอียด</th>
                    <th scope="col" class="text-center">ตัวอย่าง</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>คุกกี้ที่มีความจำเป็น (Strictly Necessary Cookies)</td>
                    <td>คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                        เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้
                        รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์
                        การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ท่านไม่สามารถใช้บริการในสาระสำคัญของ ระบบห้องสมุดอัตโนมัติ
                        WALAI AutoLib ซึ่งจำเป็นต้องเรียกใช้คุกกี้ได้</td>
                    <td>
                        <ul>
                            <li>PHPSESSID</li>
                            <li>JSESSIONID</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน (Performance Cookies)</td>
                    <td>คุกกี้ประเภทนี้ช่วยให้ระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                        ทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                        รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ
                        ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์
                        และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น ถึงแม้ว่า
                        ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น
                        การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                        ไม่สามารถทราบปริมาณผู้เข้าเยี่ยมชมเว็บไซต์ และไม่สามารถประเมินคุณภาพการให้บริการได้</td>
                    <td>
                        <ul>
                            <li>__utmc</li>
                            <li>_hjIncludedInPageviewSample</li>
                            <li>_hjTLDTest</li>
                            <li>_gid</li>
                            <li>__utma</li>
                            <li>__utmb</li>
                            <li>__utmt</li>
                            <li>__utmz</li>
                            <li>_hjid</li>
                            <li>_ga</li>
                            <li>_hjAbsoluteSessionInProgress</li>
                            <li>_hjFirstSeen</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>คุกกี้เพื่อการใช้งานเว็บไซต์ (Functional Cookies)</td>
                    <td>คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของ ระบบห้องสมุดอัตโนมัติ WALAI AutoLib จดจำตัวเลือกต่าง ๆ
                        ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้
                        เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน หรือจดจำการเปลี่ยนแปลงการตั้งค่าขนาดฟอนต์หรือการตั้งค่าต่าง ๆ
                        ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้
                        การปิดการใช้งานคุกกี้ประเภทนี้อาจส่งผลให้เว็บไซต์ไม่สามารถทำงานได้อย่างสมบูรณ์</td>
                    <td>
                        <ul>
                            <li>fbsr_340486642645761</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย (Targeting Cookies)</td>
                    <td>คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม
                        ซึ่งเก็บข้อมูลการเข้าใช้งานและเว็บไซต์ที่ท่านได้เข้าเยี่ยมชม
                        เพื่อนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
                        ทั้งนี้ หากท่านปิดการใช้งานคุกกี้ประเภทนี้จะไม่ส่งผลต่อการใช้งานเว็บไซต์ของระบบห้องสมุดอัตโนมัติ
                        WALAI AutoLib แต่จะส่งผลให้การนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่น ๆ ไม่สอดคล้องกับความสนใจของท่าน
                    </td>
                    <td>
                        <ul>
                            <li>gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx</li>
                            <li>_utmv#########</li>
                            <li>TESTCOOKIESENABLED</li>
                            <li>YSC</li>
                            <li>NID</li>
                            <li>test_cookie</li>
                            <li>GPS</li>
                            <li>VISITOR_INFO1_LIVE</li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
    
    
    
    </blockquote>
    

    <p class="h3 text-primary">ท่านจะจัดการคุกกี้ได้อย่างไร</p>
    <blockquote>
        <p>
            <span class="ps-4"></span>บราวเซอร์ส่วนใหญ่จะมีการตั้งค่าให้มีการยอมรับคุกกี้เป็นค่าเริ่มต้น อย่างไรก็ตาม
            ท่านสามารถปฏิเสธการใช้งานหรือลบคุกกี้ในหน้าการตั้งค่าของบราวเซอร์ที่ท่านใช้งานอยู่ ทั้งนี้
            หากท่านทำการปรับเปลี่ยนการตั้งค่าบราวเซอร์ของท่านอาจส่งผลกระทบต่อรูปแบบและการใช้งานบนหน้าเว็บไซต์ของเราได้
            หากท่านประสงค์ที่จะทำการปรับเปลี่ยนการตั้งค่า
            ท่านสามารถตรวจสอบรายละเอียดเพิ่มเติมได้ตามลิงก์ที่ได้ระบุไว้ข้างล่าง
        </p>
    
        <ul>
            <li class="mb-3">
                <p class="mb-0">Android (Chrome)</p>
                <p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1</p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Apple Safari</p>
                <p>https://support.apple.com/en-gb/guide/safari/sfri11471/mac</p>
    
            </li>
            <li class="mb-3">
                <p class="mb-0">Blackberry</p>
                <p>https://docs.blackberry.com/content/dam/docs-blackberry-com/release-pdfs/en/device-user-guides/BlackBerry-Classic-Smartphone-10.3.3-User-Guide-en.pdf
                </p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Google Chrome</p>
                <p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en</p>
    
            </li>
            <li class="mb-3">
                <p class="mb-0">Microsoft Edge</p>
                <p>https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
                </p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Microsoft Internet Explorer</p>
                <p>https://support.apple.com/en-gb/guide/safari/sfri11471/mac</p>
            </li>
    
            <li class="mb-3">
                <p class="mb-0">Mozilla Firefox</p>
                <p class="margin-bottom-10">
                    https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US
                </p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Opera</p>
                <p>https://help.opera.com/en/latest/web-preferences/</p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Iphone or Ipad (Chrome)</p>
                <p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1</p>
            </li>
            <li class="mb-3">
                <p class="mb-0">Iphone or Ipad (Safari)</p>
                <p>https://support.apple.com/en-us/HT201265</p>
            </li>
        </ul>
    
    
        <p class="mt-3">
           <span class="ps-4"></span> ทั้งนี้ โปรดทราบว่า หากท่านเลือกที่จะปิดการใช้งานคุกกี้บนเบราว์เซอร์หรืออุปกรณ์ของท่าน
            อาจส่งผลกระทบกับการทำงานบางส่วนของเว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
            ที่ไม่สามารถทำงานหรือให้บริการได้เป็นปกติ
        </p>
    
        <p>
            <span class="ps-4"></span> ระบบห้องสมุดอัตโนมัติ WALAI AutoLib จะไม่รับผิดชอบและระบบห้องสมุดอัตโนมัติ WALAI AutoLib
            ไม่ได้มีความเกี่ยวข้องกับเว็บไซต์ รวมทั้งเนื้อหาในเว็บไซต์ต่าง ๆ ที่กล่าวมาข้างบน
        </p>
    
        <p>สำหรับข้อมูลอื่น ๆ เพิ่มเติมในเรื่องนี้ ท่านสามารถเข้าไปอ่านได้ที่ 
            <a  href="https://www.aboutcookies.org/how-to-delete-cookies">https://www.aboutcookies.org/how-to-delete-cookies</a>
        </p>

    </blockquote>
   


    <p class="h3 text-primary">การเชื่อมโยงข้อมูลกับเว็บไซต์อื่น</p>
    <blockquote class="mb-3">
        <span class="ps-4"></span>เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib อาจมีการเชื่อมโยงไปยังเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอก
        รวมถึงอาจมีการฝังเนื้อหาหรือวีดีโอที่มาจากโซเชียลมีเดีย เช่น YouTube หรือ Facebook เป็นต้น
        ซึ่งจะช่วยให้ท่านเข้าถึงเนื้อหาและสร้างการปฏิสัมพันธ์กับบุคคลอื่นบนโซเชียลมีเดียจากเว็บไซต์ของระบบห้องสมุดอัตโนมัติ
        WALAI AutoLib ได้ ซึ่งเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอกจะมีการกำหนดและตั้งค่าคุกกี้ขึ้นมาเอง
        โดยที่ระบบห้องสมุดอัตโนมัติ WALAI AutoLib ไม่สามารถควบคุมหรือรับผิดชอบต่อคุกกี้เหล่านั้นได้
        และขอแนะนำให้ท่านควรเข้าไปอ่านและศึกษานโยบายหรือประกาศการใช้คุกกี้ของบุคคลภายนอกเหล่านั้นด้วย
    </blockquote>

    <p class="h3 text-primary">ติดต่อ ระบบห้องสมุดอัตโนมัติ WALAI AutoLib</p>
    <blockquote class="mb-3">
        <p>
            ในกรณีที่ท่านมีคำถามเกี่ยวกับนโยบายคุกกี้ของเรา ท่านสามารถติดต่อสอบถามได้ที่
        </p>
        <ul class="list-unstyled">
            <li>Call Center </li>
            <li>walai.support&#64;Gmail.com</li>
            <li>ศูนย์ความเป็นเลิศด้านนวัตกรรมสารสนเทศ</li>
            <li>สำนักวิชาสารสนเทศศาสตร์ มหาวิทยาลัยวลัยลักษณ์</li>
        </ul>
    </blockquote>

   


</div>