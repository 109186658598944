import { Component, OnInit } from '@angular/core';
import { TagCloudModel } from 'src/app/models/walai';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.css'],
})
export class TagCloudComponent implements OnInit {
  tagCloudData: TagCloudModel[] = [];
  public locationID: string = '';

  ntkLocation = '';
  nttLocation = '';
  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    try {
      this.locationID = localStorage.getItem('locationIDMain'); //location หลัก

      if (this.locationID != '') {
        this.ntkLocation = '|LOCATION';
        this.nttLocation = '|' + this.locationID;
      }
    } catch (error) {}

    this.load();
  }

  load() {
    //this.spinner.show(); // หมุนๆ
    this.homeService.getTagCloudAll().subscribe((data: TagCloudModel[]) => {
      this.tagCloudData = data;
      //  this.spinner.hide(); // ซ่อนหมุนๆ
    });
  }
}
