import { Pipe, PipeTransform } from '@angular/core';
import { Mattype } from '../models/walai';
import { HomeService } from '../services/home.service';

@Pipe({
  name: 'mattPic'
})
export class MattPicPipe implements PipeTransform {
  constructor(private homeService: HomeService) { }

  async transform(b: any): Promise<string> {
    const typeid = b.MATTYPENAME;
    const bibid = b.id;
    const matType = new Mattype();
    let matName: string;
    const prefix = 'assets/images/Mattype/';
    const subfix = `" />`;
    const matUrl = `<img width="24" src = "assets/images/Mattype/`;
    return new Promise((resolve, reject) => {
      if (Number(typeid) !== 1 && Number(typeid) !== 2) {
        matName = matType.GetMattypeName(typeid);
        resolve(`${prefix}${matName}.png`);
      } else {
        this.homeService.getBookNewMatType(bibid).subscribe((mid: any) => {
          matName = matType.GetMattypeName(mid);
          resolve(`${prefix}${matName}.png`);
        });
      }

    });
  }

}
