<div class="container mt-4">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
    <li ngbNavItem="1">
      <a ngbNavLink>Street view</a>
      <ng-template ngbNavContent>
        <div class="card-body mt-4">
          <img
            alt=""
            [src]="this.imgMap"
            class="img-fluid custom-img-shadow w-100"
          />
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="2">
      <a ngbNavLink>Bookcase</a>
      <ng-template ngbNavContent>
        <div class="card-body mt-4">
          <div class="row justify-content-between">
            <div class="col-6 col-lg-6 row">
              <div class="col-lg-6 wl-fs-18  text-secondary">
                <span class="text-secondary">Location : </span
                >{{ bookshelf.LOCATION }}
              </div>
              <div class="col-lg-6 wl-fs-18 text-secondary">
                <span class="text-secondary">Area : </span>{{ bookshelf.AREA }}
              </div>
              <div class="col-lg-6 wl-fs-18 text-secondary">
                <span class="text-secondary">Bookcase : </span
                >{{ bookshelf.BOOKCASENAME }}
              </div>
            </div>

          </div>
        
            <div class="row">
              <div class="col-lg-8 ">
             
              </div>
           
              <div class="col align-self-end mt-2">
                <!-- <form class=" Bg-Search" [formGroup]="qsearchForm">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'Search' | translate }}..."
                    formControlName="textSearch"
                    id="TextQSearch"
                    (keydown.enter)="search()"
                    (input)="auto($event.target.value)"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
             
                  <ul class="dropdown-menu">
                    <li *ngFor="let item of listAuto" class="w-100 pt-2">
                      <a class="dropdown-item" (click)="EnterSearch(item.word)">{{
                        item.word
                      }}</a>
                    </li>
                  </ul>
                </form> -->
              </div>
            </div>
       

          <div class="card mt-5 wl-bg-gray-v4">
            <div class="card-body ">
              <table class="table table-bordered mb-auto mt-auto">
                <!-- <tr *ngFor="let Row of bookshelf.HEIGHT"> -->
                <tr
                  *ngFor="
                    let Row of [].constructor(bookshelf.HEIGHT);
                    let r = index
                  "
                >
                  <td
                    class="shelf wl-brd-width text-center"
                    *ngFor="
                      let Col of [].constructor(bookshelf.WIDTH);
                      let j = index
                    "
                    [ngClass]="{
                      markshelf: r + 1 == currentRow && j + 1 == currentCol
                    }"
                  >
               
                    <ng-container
                      *ngIf="
                        r + 1 == currentRow && j + 1 == currentCol;
                        else elseNotDone
                      "
                    >
                      <a (click)="tabitem()" class="btn-walai-default a-walai"
                        ><b>Here</b></a
                      >
                    </ng-container>
                    <ng-template #elseNotDone>
                      <a
                        disabled
                        class="btn text-muted text-opacity-50"
                        style="cursor: unset"
                        ><small
                          >row {{ r + 1 }}<br />
                          col {{ j + 1 }}</small
                        >
                      </a>
                    </ng-template>
                  </td>
                </tr>
              </table>
              <!-- <table class="table table-bordered mb-auto mt-auto">
                <tr *ngFor="let Row of bookcaseRow">
                  <td class="shelf wl-brd-width text-center" *ngFor="let Column of bookcaseCol"
                    [ngClass]="{'markshelf': Row.item==1&&Column.item==1}">

                    <ng-container *ngIf="Row.item==1&&Column.item==1; else elseNotDone">
                      <a (click)="tabitem()" 
                      class="btn-walai-default a-walai"><b>Here</b></a>
                    </ng-container>
                    <ng-template #elseNotDone>
                      <a  
                      class="btn ">shelf {{Row.item}}, {{Column.item}}</a>
                    </ng-template>
                  </td>
                </tr>
              </table> -->
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="3">
      <a ngbNavLink>Bookshelf</a>
      <ng-template ngbNavContent>
        <div class="card-body mt-4">
          <div class="row  pb-4">
            <div class="col-lg-6 wl-fs-18 text-secondary">
              <span class="text-secondary">Location : </span
              >{{ bookshelf.LOCATION }}
            </div>
            <div class="col-lg-6 wl-fs-18 text-secondary">
              <span class="text-secondary">Area : </span>{{ bookshelf.AREA }}
            </div>
            <div class="col-lg-6 wl-fs-18 text-secondary">
              <span class="text-secondary">Bookcase : </span
              >{{ bookshelf.BOOKCASENAME }}
            </div>
            <div class="col-lg-6 wl-fs-18 text-secondary">
              <span class="text-secondary">Bookshelf : </span
              >{{ bookshelf.BOOKSHELFNAME }}
            </div>
            <div class="col-12 wl-fs-18 text-secondary">
              <span class="text-secondary">CallNo length : </span
              >{{ bookshelf.STARTCALLNO }} <span class="text-opacity-25">to</span> {{ bookshelf.ENDCALLNO }}
            </div>
          </div>

          <div class="card mt-5 wl-bg-gray-v4 py-4">
            <div
              class="scroll-container"
              (mouseleave)="showScroll = false"
              (mouseenter)="showScroll = true"
              [ngClass]="{ 'show-scroll': showScroll }"
            >
              <!-- <div class="div-scroll" *ngFor="let item of scrollItems">
                {{ item }}
              </div> -->
              <div
                (click)="getiteminfo(img.ITEMID)"
                class="div-scroll me-2 col-3 col-lg-auto mb-2"
                *ngFor="let img of bookcovers; let i = index;"
              >
                <div
                  class="card-bookcover shadow-sm p-2"
                
                  [ngClass]="{'active1': img.ITEMID === itemid}"
                  >
                  <img
                    [src]="img.BOOKCOVER"
                    (error)="onImgError($event)"
                    alt="{{ img.BOOKCOVER }}"
                    class="card-img-top"
                    alt="..."
                  />  
                  <div *ngIf="img.ITEMID === itemid" class="card-body"><b>Here</b> </div>
                  <div *ngIf="img.ITEMID != itemid" class="card-body card-bookcover-body">{{i+1}}</div>
                </div>
                <!-- <ngx-spinner bdOpacity="0.9" class="d-block" bdColor="white" size="small" color="#428DB6"
                  type="ball-pulse-sync" [fullScreen]="false">
                </ngx-spinner>
                <a class="card mb-2" (click)="getiteminfo(img.ITEMID)"
                  [ngClass]="{'btn-walai-default': img.ACTIVE === 'True'}">
                  <div class="card-header bg-transparent p-2">
                    <img class="card-img-top w-auto img-bookcover-vc mx-auto d-block" [src]="img.BOOKCOVER"
                    (error)="onImgError($event)" alt="{{ img.BOOKCOVER }}">
                  </div>
                  <div class="card-body p-0 py-1">
                    <p class="card-title d-none d-md-flex justify-content-center">{{img.ITEMID}}</p>
                  </div>
                </a> -->
              </div>
            </div>

            <!-- <div id="multi-item-example" class="carousel slide carousel-multi-item" data-interval="false">
            
              <a *ngIf="this.citemsBookcover.length > 1" class="carousel-control-prev bg-dark btn-carousel"
                href="#multi-item-example" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a *ngIf="this.citemsBookcover.length > 1" class="carousel-control-next bg-dark btn-carousel"
                href="#multi-item-example" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
           
              <div class="carousel-inner" role="listbox">

                <div class="carousel-item" data-slide="false" *ngFor="let item of this.citemsBookcover; let i = index;"
                  [ngClass]="{ active: item.ACTIVE === 'True' }">

                  <div class="row justify-content-center">
                    <div class="col-3 col-lg-auto" *ngFor="let img of item.ITEM;">
                      <ngx-spinner bdOpacity="0.9" class="d-block" bdColor="white" size="small" color="#428DB6"
                        type="ball-pulse-sync" [fullScreen]="false">
                      </ngx-spinner>
                      <a class="card mb-2" (click)="getiteminfo(img.ITEMID)"
                        [ngClass]="{'btn-walai-default': img.ACTIVE === 'True'}">
                        <div class="card-header bg-transparent p-2">
                          <img class="card-img-top w-auto img-bookcover-vc mx-auto d-block" [src]="img.BOOKCOVER"
                          (error)="onImgError($event)" alt="{{ img.BOOKCOVER }}">
                        </div>
                        <div class="card-body p-0 py-1">
                          <p class="card-title d-none d-md-flex justify-content-center">{{img.COUNT}}</p>
                        </div>
                      </a>
                    </div>

                  </div>

                </div>

              </div>

            </div> -->
      
            <div class="row justify-content-between m-4 ">
             

              <div class="col-8 col-lg-8 mt-3">
                <p class="wl-fs-18 mb-0 text-secondary">{{ itembookshelf.TITLE }}</p>
                <div class="text-secondary"><span class="text-secondary">Author</span> : {{ itembookshelf.AUTHOR }}</div>
                <div class="text-secondary"><span class="text-secondary">No.</span> : {{ itembookshelf.BARCODE }}</div>
                <div class="text-secondary"><span class="text-secondary">CallNo</span>: {{ itembookshelf.CALLNO }}</div>
                <div class="text-secondary" [ngClass]="{ 'd-none': this.itembookshelf.COPY === null }">
                 <span class="text-secondary">Copy</span> : {{ itembookshelf.COPY }}
                </div>
                <div class="text-secondary"
                  [ngClass]="{
                    'd-none':
                      this.itembookshelf.UNIT === ' ' ||
                      this.itembookshelf.UNIT === null
                  }"
                >
                  Unit: {{ itembookshelf.UNIT }}
                </div>
              </div>
              <div
            
                class="col-md-3 mt-3"

                [ngClass]="{ 'd-none': this.currentUser.length === 0 }"
              >
                <div class="text-md-center mt-3">
                  <button type="button" class="btn btn-outline-primary mb-2"  (click)="pinItem(itembookshelf.BARCODE)">  <img
                    src="assets/images/icon/Pin.png"
                    class="icon-item "
                    title="Pin" style="width: 25px;"
                 
                    /> Click to pin</button>
             

                  <qrcode
                    [qrdata]="QrCodeIteminfo"
                    [width]="100"
                    [errorCorrectionLevel]="'M'"
                  ></qrcode>
                  Scan to pin
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </ng-template>
    </li>
  </ul>
  <div>
    <div [ngbNavOutlet]="nav" class="card wl-brd-top-0">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="default"
        color="#581f87"
        type="ball-pulse-sync"
        [fullScreen]="false"
      >
      </ngx-spinner>
    </div>
  </div>
</div>
