<div class="mt-5 pt-5 footer" [style.color]="colorFooter" [style.background-color]="backgroundFooter">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-xs-12 about-company">
        <h5>About us</h5>
        <p class="pr-5">
          {{AboutUsFooter}}
        </p>
        <div class="row row-cols-auto gap-2 px-3">

          <!-- <button type="button" class="btn btn-sm btn-outline-secondary-footer p-0"><i class="bi bi-linkedin"></i></button> -->
        </div>
      </div>
      <div class="col-lg-3 col-xs-12 links">
        <h5 class="mt-lg-0 mt-sm-3">Links</h5>
        <ul class="m-0 p-0">
          <li>- <a [routerLink]="'./search/alphabetic'">A-Z SEARCH</a></li>
          <li>- <a [routerLink]="'./search/advance'">ADVANCED SEARCH</a></li>
          <li>- <a [routerLink]="'news'">NEWS</a></li>
          <li>- <a [routerLink]="'aboutus'">ABOUT US</a></li>
          <li>- <a [routerLink]="'regulation'">REGULATION</a></li>
          <li>- <a [routerLink]="'contactus'">CONTACT US</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-xs-12 location">
        <h5 class="mt-lg-0 mt-sm-4">Location</h5>
        <p>{{ addressEN }}</p>
        <p class="mb-1"><i class="bi bi-telephone me-2"></i>{{ Telephone }}</p>
        <p class="mb-0"><i class="bi bi-envelope me-2"></i>{{ EmailContact }}</p>
        <a href="{{facebook}}" target="_blank" class="btn btn-sm btn-outline-secondary-footer p-0"><i
            class="bi bi-facebook me-2"></i> Facebook</a>
      </div>
    </div>



    <div class="mt-5 d-flex gap-2 pb-2">
      <p class="border-end boder-white pe-2">Copyright © IICE 2024</p>
      <p class="border-end boder-white pe-2">Version  <a href="version" class="a-walai text-white">20240126</a></p>
      <p>
        <a [routerLink]="'/policy'" rel="Cookie Policy" class="me-2 pe-2">นโยบายคุกกี้ (Cookie
          Policy)</a>
        <a [href]="pdpaUrl" [hidden]="pdpaUrl == null || pdpaUrl  == ''" class="border-start border-white ps-2" target="_blank" rel="Privacy Policy">นโยบายส่วนบุคคล (Privacy Policy)</a>
      </p>
    </div>
  </div>

  <!-- <div class="offcanvas offcanvas-bottom show text-primary" *ngIf="OpenConsent" tabindex="-1" id="offcanvascookieConsent"
    aria-labelledby="offcanvascookieConsentLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvascookieConsentLabel">เราใช้คุกกี้ (cookie)</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-4">
      <p><span class="p-2"></span>เมื่อท่านได้เข้าสู่เว็บไซต์ของระบบห้องสมุดอัตโนมัติ WALAI AutoLib
        ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ ของท่านจะถูกบันทึกไว้ในรูปแบบของคุกกี้
        โดยนโยบายคุกกี้นี้จะอธิบายถึงความหมาย การทำงาน วัตถุประสงค์ รวมถึงการลบและการปฏิเสธการเก็บคุกกี้
        เพื่อความเป็นส่วนตัวของท่าน โดยการเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ของเรา</p>

      <div class="row justify-content-between">
        <div class="col-auto  align-content-center d-flex gap-3 text-primary">
          <a [routerLink]="'/policy'" target="_blank" rel="noopener noreferrer">นโยบายคุกกี้ (Cookie Policy)</a>
          <a [href]="pdpaUrl" [hidden]="pdpaUrl == null || pdpaUrl  == ''" target="_blank" rel="Privacy Policy">นโยบายส่วนบุคคล (Privacy Policy)</a>

        </div>
        <div class="col-auto align-content-center"><button id="btnAcceptCookie" class="btn btn-primary" (click)="acceptPDPA()">ยอมรับ</button></div>
      </div>

    </div>
  </div> -->
</div>




<!-- <div class="Cookies-footer alert alert-primary alert-dismissible fade show" role="alert">
      <h1 class="display-4">Hello</h1>
      Your privacyWe use cookies to ensure the functionality of our website, to personalize content and advertising, to provide social media features, and to analyze our traffic. If you allow us to do so, we also inform our social media, advertising and analysis partners about your use of our website. You can decide for yourself which categories you want to deny or allow. Please note that based on your settings not all functionalities of the site are available. View our privacy policy
      <a class="btn btn-primary btn-lg" href="#" role="button">Accept All Cookies</a>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->