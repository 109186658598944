import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/services/home.service';
import {
  book773t,
  book856,
  bookStatic,
  HoldingAvailableIssues,
  Keywords,
} from '../models/walai';
import { Meta } from '@angular/platform-browser';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { ReturnStatement } from '@angular/compiler';
import { DataService } from '../services/data.service';
import { UtilityService } from '../services/utility.service';
import { SearchService } from '../services/search.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bibitem',
  templateUrl: './bibitem.component.html',
  styleUrls: ['./bibitem.component.css'],
})
export class BibitemComponent implements OnInit {
  public bibid: string;
  public title: string;
  public author: string;
  public locationID: string = '';
  public mattypeID: string;
  public mattyeIcon: String;
  public abstract: string;
  public contents: any[] = [];
  public havesubject: boolean = false;
  public haveISBN: boolean = false;
  public addedAuthor: boolean = false;
  public bookcoverUrl: string = '';
  public infoList: any[] = [];
  public subjectList: any[];
  public bookRelated: any[] = [];
  public AllTitleInIssue: any[] = [];
  public book856: book856[] = [];
  public objBook773t: book773t = {
    label: '',
    data: '',
  };

  public bookStatic: bookStatic = {
    LASTUSE: '',
    CHECKOUTCOUNT: 0,
    OPACVIEW: 0,
    MYLIST: 0,
    DOWNLOAD: 0,
  };
  validParameter = true;
  public isBook = true;
  public isSerial = false;
  public isArticle = false;

  public HoldingAvailableIssues: HoldingAvailableIssues[];

  public covers: string[] = [];
  public bookCoverPath: string = '';
  // filterargs = {LOCATIONID: '1'};
  public inLoop = true;
  url = '';
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private meta: Meta,
    private data: DataService,
    private fc: UtilityService,
    private searchService: SearchService,
    private router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this.url = window.location.href;

    // bibid ที่ถูกส่งมาแบบ  Get ผ่าน Url
    try {
      this.bibid = this.route.snapshot.queryParamMap.get('bibid');
    } catch (error) {
      try {
      } catch (error) {}
    }
    //alert(this.bibid)
    if (this.bibid == null) {
      try {
        this.bibid = this.route.snapshot.queryParamMap.get('BibID');
      } catch (error) {}
    }

    if (this.bibid == null) {
      try {
        this.bibid = this.route.snapshot.queryParamMap.get('JMarcID');
      } catch (error) {}
    }

    if (this.bibid.indexOf('j') != -1) {
      this.isArticle = true;
    }

    this.locationID = localStorage.getItem('locationIDMain'); //location หลัก

    //เช็คว่าเป็นวารสารหรือไม่
    this.homeService.checkSerial(this.bibid).subscribe((isSerial: boolean) => {
      this.isSerial = isSerial;
      if (this.isSerial) {
        //   this.getSerialItemForOPAC(this.bibid);
      }
    });

    // แสดงรายละเอียดเบื้องต้นของหนังสือโดยมีการแยกไว้เป็น Set เพื่อความสะดวก
    await this.getInfo(this.bibid);

    if (this.bibid.indexOf('j') == -1) {
      // กรณีที่เป็นหนังสือจะมีการแสดงผลที่เพิ่มมามากกว่าบทความวารสาร เช่น บทความจะไม่มี Item

      await this.getBookRelated(this.bibid);
      await this.getBookNewMatType(this.bibid);
    } else {
      // บทความ
      this.isBook = false; // ไม่ใช่หนังสือนะ
      this.mattyeIcon = 'assets/images/Mattype/ArticleGrey.png'; // บทความ=9ไม่ต้องไปวนตรวจสอบ

      await this.GetAllTitleInIssue(this.bibid);
    }

    // สถิติของหนังสือ
    this.getBookStatic(this.bibid);

    this.getBookCoverAll(this.bibid);
    this.genDublinCoreMetadata(this.bibid);
  }

  getSerialItemForOPAC(bibid: string) {
    this.homeService
      .getSerialItemForOPAC(this.bibid)
      .subscribe((HoldingAvailableIssues: HoldingAvailableIssues[]) => {
        this.HoldingAvailableIssues = HoldingAvailableIssues;
        //   console.log(this.HoldingAvailableIssues);
      });
  }

  // แสดงรายละเอียดเบื้องต้นของหนังสือโดยมีการแยกไว้เป็น Set เพื่อความสะดวก
  public async getInfo(bibid: string) {
    this.spinner.show(); // หมุนๆ

    // ส่งค่าไปยัง API
    this.subjectList = [];
    (await this.homeService.getBibInfo(bibid)).subscribe(
      (book: any) => {
        // รายละเอียดของหนังสือเช่น ผู้แต่งรูป สำหนักพิมพ์
        this.infoList = book.BookInfo;

        if (this.infoList.length == 0) {
          this.validParameter = false;
        }
        // ตรวจสอบบางค่าเช่น Subject ที่มีการแยกการแสดงผลเพื่อความสวยงามว่ามีอยู่หรือไม่
        // ซึ่งถ้าไม่มีก็จะไม่ต้องแสดง Label ...ไว้สำหรับตัดข้อมูลแยกแสดงผล
        // เช่นหัวเรื่องอาจจะต้องตัด Lebel ด้านซ้ายออกไปด้วยแล้วให้มาแสดงผลใน บันทัดเดียว
        this.infoList.forEach((element) => {
          // เช็คว่ามี Subject หรือไม่
          if (element.FIELD === 'Subject') {
            this.havesubject = true;
            this.subjectList.push(element);
          }
          if (element.FIELD === 'ISBN') {
            this.haveISBN = true;
          }
          if (element.FIELD === 'Added Author') {
            this.addedAuthor = true;
          }
        });

        // หลายค่าจะส่งแยกออกจาก Json หลักเพื่อง่ายต่อการแสดงผล
        this.title = book.Books[0].title;
        this.author = book.Books[0].author;
        this.bookcoverUrl = book.Books[0].bookcover;
      console.log( this.bookcoverUrl)
        this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
          if (!exists) {
            this.getCoverOnError();
          }
        });

        this.abstract = book.Books[0].abstract; // บทคัดย่อ

        // แสดงผล Url ต่างๆ
        if (book.Books856) {
          // this.book856 = book.Books856;
          this.getGetBook856();
        }

        if (book.Books773t) {
          this.objBook773t = book.Books773t[0];
        }

        // สารบัญ นั้นบรรณารักษ์มักจะคั่นด้วย -- ของแต่ละบทไว้จึงใช้ตัวนี้มาตัดแบ่งการแสดงผล

        const str = book.Books[0].Contents;
        if (str) {
          //  this.contents = str.split('--');
          let t = str.split('<br/>');

          t.forEach((element) => {
            if (element != '') {
              let e = element.split('--');

              e.forEach((ee) => {
                this.contents.push(ee);
              });
            }
          });
        }
    

        this.spinner.hide(); // ซ่อนหมุนๆ
      },
      (error) => {
     
        // ซ่อนหมุนๆ
        this.spinner.hide();
        this.validParameter = false;
      }
    );
  }

  // แสดงรายการหนังสือที่มี Relat กันโดยในเบื้องต้นจะยึด Subject
  public getBookRelated(id) {
    this.homeService.getBookRelated(id, 6).subscribe((book: any) => {
      this.bookRelated = book;
    });
  }

  public GetAllTitleInIssue(id) {
    this.homeService.getAllTitleInIssue(id).subscribe((book: any) => {
      this.AllTitleInIssue = book;
    });
  }



  // สถิติต่างๆ ของหนังสือ เช่นการวิว การยืมและการเพิ่มใน My List
  public getBookStatic(id) {
    this.homeService.getBookStatic(id).subscribe((book: any) => {
      if (book) {
        console.log()
        this.bookStatic = book[0];
        if (this.bookStatic.CHECKOUTCOUNT == null) {
          this.bookStatic.CHECKOUTCOUNT = 0;
        }
      }
    });
  }

  // แสดงข้อมูลประเภททรัพยากร
  public getBookNewMatType(id) {
    this.homeService.getBookNewMatType(id).subscribe((mattype: string) => {
      this.mattypeID = mattype;

      switch (this.mattypeID) {
        case '1': {
          this.mattyeIcon = 'assets/images/Mattype/Book.png';
          break;
        }
        case '2': {
          this.mattyeIcon = 'assets/images/Mattype/SerialGrey.png';
          break;
        }
        case '3': {
          this.mattyeIcon = 'assets/images/Mattype/MusicGrey.png';
          break;
        }
        case '4': {
          this.mattyeIcon = 'assets/images/Mattype/MapGrey.png';
          break;
        }
        case '5': {
          this.mattyeIcon = 'assets/images/Mattype/VisualGrey.png';
          break;
        }
        case '6': {
          this.mattyeIcon = 'assets/images/Mattype/ComputerFileGrey.png';
          break;
        }
        case '7': {
          this.mattyeIcon = 'assets/images/Mattype/MixedGrey.png';
          break;
        }
        case '8': {
          this.mattyeIcon = 'assets/images/Mattype/Book.png';
          break;
        }
        case '9': {
          this.mattyeIcon = 'assets/images/Mattype/ArticleGrey.png';
          break;
        }
        case '10': {
          this.mattyeIcon = 'assets/images/Mattype/eBookGrey.png';

          break;
        }
        case '11': {
          this.mattyeIcon = 'assets/images/Mattype/ThesisGrey.png';
          break;
        }
        default: {
          this.mattyeIcon = 'assets/images/Mattype/Book.png';
          break;
        }
      }
    });
  }

  getBookCoverAll(bibid: string) {
    this.covers = [];
    this.homeService.getBookCoverPath().subscribe((covers: any) => {
      this.bookCoverPath = covers;

      const idNumber = Number(bibid.replace('b', ''));

      //this.covers.push[ this.bookCoverPath + idNumber +'/'+idNumber +'-fc-a.jpg'];
      //this.checkImage(this.bookCoverPath + idNumber +'/'+idNumber +'-fc-a.jpg')

      this.checkIfImageExists1(
        this.bookCoverPath + idNumber + '/' + idNumber + '-fc-a.jpg',
        (exists) => {
          if (exists) {
            // this.covers.push(
            //   this.bookCoverPath + idNumber + '/' + idNumber + '-fc-a.jpg'
            // );
          }
        }
      );

      this.checkIfImageExists1(
        this.bookCoverPath + idNumber + '/' + idNumber + '-bc-a.jpg',
        (exists) => {
          if (exists) {
            // this.covers.push(
            //   this.bookCoverPath + idNumber + '/' + idNumber + '-bc-a.jpg'
            // );
          } else {
          }
        }
      );

      this.checkIfImageExists1(
        this.bookCoverPath + idNumber + '/' + idNumber + '-tp-a.jpg',
        (exists) => {
          if (exists) {
            // this.covers.push(
            //   this.bookCoverPath + idNumber + '/' + idNumber + '-tp-a.jpg'
            // );
          }
        }
      );

      //สารบัญ
      this.checkIfImageExists1(
        this.bookCoverPath + idNumber + '/' + idNumber + '-tc-a.jpg',
        (exists) => {
          if (exists) {
            // this.covers.push(
            //   this.bookCoverPath + idNumber + '/' + idNumber + '-tc-a.jpg'
            // );

      

            let tableOfContentsCount = 1;
            for (
              tableOfContentsCount = 1;
              tableOfContentsCount < 100;
              tableOfContentsCount++
            ) {
              this.checkIfImageExists1(
                this.bookCoverPath +
                  idNumber +
                  '/' +
                  idNumber +
                  '-tc' +
                  '0' +
                  tableOfContentsCount +
                  '-a.jpg',
                (exists) => {
                  if (exists) {
                    // this.covers.push(
                    //   this.bookCoverPath +
                    //     idNumber +
                    //     '/' +
                    //     idNumber +
                    //     '-tc' +
                    //     '0' +
                    //     tableOfContentsCount +
                    //     '-a.jpg'
                    // );
                  }
                }
              );
            }
          }
        }
      );
    
    });

   
  }

  genDublinCoreMetadata(id) {
    let marc21data = [];
    this.homeService.getgenDublinCoreMetadata(id).subscribe((meta: any) => {
      if (meta) {
        this.meta.addTags(meta);
      }
    });
  }

  // CHECK IF IMAGE EXISTS

  checkIfImageExists1(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      this.covers.push(url
        );
      callback(true);
    } else {
      img.onload = () => {
        this.covers.push(url
        );
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }

  // checkIfImageExists(url, callback) {
  //   const img = new Image();
  //   img.src = url;

  //   if (img.complete) {
  //     callback(true);
  //   } else {
  //     img.onload = () => {
  //       console.log(url)
  //       callback(true);
  //     };

  //     img.onerror = () => {
  //       callback(false);
  //     };
  //   }
  // }

  save(bibid: string) {
    this.data.saveForExport(bibid);
  }

  getCoverOnError() {
    this.bookcoverUrl = 'assets/images/bookcover/loading.jpg';
    var keyword_ = new Keywords();
    keyword_.CurrentPage = 1;
    keyword_.RowPerPage = 1;
    keyword_.Ntk = 'ID';
    keyword_.Ntt = this.bibid;
    keyword_.Orderby = 'RELEVANCE';
    keyword_.Nto = '';

    this.searchService.keywordsearch(keyword_).subscribe(
      (results: any) => {
        if (results) {
          if (results.Display.length > 0) {
            let useWalaiBookCover = `${environment.useWalaiBookCover}`;
            if (useWalaiBookCover == '1') {
              this.checkWALAICOVER(results);
            } else {
              this.getCoverByLang(results);
            }

            //   this.getCoverByLang(results)
            // if (results.Display[0].LANG == 'ภาษาไทย') {
            //   this.bookcoverUrl = this.fc.getBookTha(
            //     results.Display[0].ISBNISSN
            //   );

            //   this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
            //     if (!exists) {
            //       this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
            //     }
            //   });
            // } else {
            //   this.bookcoverUrl = this.fc.getBookEng(
            //     results.Display[0].ISBNISSN
            //   );

            //   this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
            //     if (!exists) {
            //       this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
            //     }
            //   });
            // }
          }
        }
      },
      (error) => {
        this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
      }
    );
  }

  getCoverByLang(results: any) {
    if (results.Display[0].LANG == 'ภาษาไทย') {
      this.bookcoverUrl = this.fc.getBookTha(results.Display[0].ISBNISSN);

      this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
        if (!exists) {
          this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
        }
      });
    } else {
      this.bookcoverUrl = this.fc.getBookEng(results.Display[0].ISBNISSN);

      this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
        if (!exists) {
          this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
        }
      });
    }
  }
  checkWALAICOVER(results: any) {
    let isbn13 = this.fc.getISBN(results.Display[0].ISBNISSN);
    fetch(
      'https://opac-api.wu.ac.th/synccoverbook/v1/api/GetBookCover/' + isbn13
    )
      .then((response) => response.text())
      .then((result) => {
        this.checkCover(result, results);
      })
      .catch((error) => this.getCoverByLang(results));
  }

  checkCover(url: string, results: any) {
    if (url == '') {
      this.getCoverByLang(results);
    } else {
      this.bookcoverUrl = url;

      this.fc.checkIfImageExists(this.bookcoverUrl, (exists) => {
        if (!exists) {
          this.bookcoverUrl = 'assets/images/bookcover/noimage.jpg';
        }
      });
    }
  }

  //ประวัติการ Download
  addHistory(url: string) {
    this.data.addDownloadHistory(this.bibid, url);
  }

  replaceBR(text:String){
    return text.replace('<br/>','');
  }

  // แสดงรายการ 856
  getGetBook856() {
    this.homeService.getBook856(this.bibid).subscribe((book856: any) => {
      //console.log(book856)
      if (book856) {
        this.book856 = book856;
      } else {
        this.book856 = [];
        // this.book856[0].URL
      }
    });
  }
}
