import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import {
  aquisition,
  DeleteTagsParam,
  Hold,
  HoldList,
  memberData,
  memberDataEdit,
  ResponseBlock,
  ResponseHoldList,
  ResponseHolds,
  ResponseLostItemHistory,
  ResponseMissing,
  responseReviewList,
  ResponseSuggestList,
  responseTagsCloudBookList,
  reviewDataAdd,
  SuggestFriendModel,
  SuggestFriendParam,
  TagCloudDataAdd,
  TagCloudNameList,
  UserPassword,
} from '../models/walai';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NDDBasketData, NDDBasketlistResponse } from '../models/modelNDD';
import { NDDRequestData } from '../models/ReqDetail';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //authorization: ''
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private baseUrl = '';
  //header = new HttpHeaders();

  header = new HttpHeaders();
  token = environment.token;
  headers = {
    headers: new HttpHeaders()
      .set('token', this.token)
      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) {
    //this.header.append('authorization','');
    this.header.append('Content-Type', 'application/json');
  }
  private handleError(err: HttpErrorResponse): Observable<never> {
    // just a test ... more could would go here
    return throwError(() => err);
  }
  //log in เข้าสู่ระบบ
  userLogin(val: any) {
    this.baseUrl = `${environment.apiurl}/api/opacLogin`;
    return this.http.post<any>(this.baseUrl, val, { headers: this.header });
  }

  //แก้ไขข้อมูล
  userEdit(val: memberDataEdit) {
    //console.log(val);
    this.baseUrl = `${environment.apiurl}/api/opacMemberUpdateData`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  // public UploadProfilePicture(file: FormData, id: string): Observable<any> {
  //   const headers = {
  //     headers: new HttpHeaders().set('accept', 'application/json'),
  //   };
  //   return this.http.post<any>(
  //     `${environment.apiurl}/api/UploadProfilePicture/${id}`,
  //     file,
  //     headers
  //   );
  // }

  public UploadProfilePicture(file: FormData, id: string): Observable<any> {
    return this.http.post<any>( `${environment.apiurl}/api/UploadProfilePicture/${id}`,
    file,
      { observe: 'response' }).pipe(catchError(this.handleError));
  }

  //แสดงประวัติการยืม
  opacMemberHistory(id) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberHistory/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //MemberData
  getMemberData(id) {
    this.baseUrl = `${environment.apiurl}/api/MemberData/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<memberData[]>(this.baseUrl, httpOptions);
  }

  //แสดงรายการยืม
  opacMemberBorrow(id) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberBorrow/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //แสดงการใช้งานเงินมัดจำ
  opacMemberGetBalance(id) {
    this.baseUrl = `${environment.apiurl}/api/GetBalance/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //แสดงการใช้งานเงินมัดจำ
  opacMemberGetNotice(id) {
    this.baseUrl = `${environment.apiurl}/api/GetNotice/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //แสดงรายการจอง
  opacMemberGetItemHold(id) {
    this.baseUrl = `${environment.apiurl}/api/GetItemHold/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<ResponseHolds>(this.baseUrl, httpOptions);
  }

  //แสดงรายการ ฺBlock
  GetBlockNotice(id) {
    this.baseUrl = `${environment.apiurl}/api/GetBlockNotice/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<ResponseBlock>(this.baseUrl, httpOptions);
  }

  //แสดงรายการ ฺ Missing
  GetLostItem(id) {
    this.baseUrl = `${environment.apiurl}/api/GetLostItem/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<ResponseMissing>(this.baseUrl, httpOptions);
  }

  //แสดงรายการ ฺ Lost Item History
  GetLostItemHistory(id) {
    this.baseUrl = `${environment.apiurl}/api/GetLostItemHistory/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<ResponseLostItemHistory>(this.baseUrl, httpOptions);
  }

  //แสดงรายการค่าปรับ
  opacMemberGetRfine(id) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberRFine/${id}`;
    //console.log(environment.apiurl);
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //แสดงรายการ Collection
  opacMemberGetCollection(id, bibid) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollection/${id}/${bibid}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  opacMemberCollectionAdd(val: any) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionAdd`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  opacMemberCollectionEdit(val: any) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionEdit`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  opacMemberCollectionDelete(mlid) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionDelete/${mlid}`;
    return this.http.delete<any>(this.baseUrl, { headers: this.header });
  }

  opacMemberCollectionAddBib(val: any) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionAddBib`;
    return this.http.put<any>(this.baseUrl, val, { headers: this.header });
  }

  opacMemberCollectionDeleteBib(mlid, bibid) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionDeleteBib/${mlid}/${bibid}`;
    return this.http.delete<any>(this.baseUrl, { headers: this.header });
  }

  opacMemberCollectionDeleteBibByMBID(MBID) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionDeleteBibBymbid/${MBID}`;
    return this.http.delete<any>(this.baseUrl, { headers: this.header });
  }

  opacMemberGetCollectionDetail(mlid) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberCollectionDetail/${mlid}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }

  //จองทรัพยากร
  userHoldItem(id, barcode) {
    //ไว้ส่งค่า
    // let body = new URLSearchParams();
    // body.set('barcode', barcode);
    const payload = new HttpParams().set('barcode', barcode);

    this.baseUrl = `${environment.apiurl}/api/HoldItem/${id}`;

    //Api จากพี่รัตรรับค่าเป็น x-www-form-urlencoded
    return this.http.post<any>(this.baseUrl, payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  userCancelHoldItem(id, holdid: string) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberDeleteHold/${id}/${holdid}`;
    return this.http.delete<any>(this.baseUrl, { headers: this.header });
  }

  userCancelHoldItemList(id, Holds: HoldList[]) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberDeleteHoldList/${id}`;
    return this.http.post<ResponseHoldList[]>(this.baseUrl, Holds, {
      headers: this.header,
    });
  }

  //Renew หนังสือ
  userRenew(id, barcode) {
    //ไว้ส่งค่า
    const payload = new HttpParams().set('barcode', barcode);
    this.baseUrl = `${environment.apiurl}/api/RenewItem/${id}`;

    //Api(By P'Ratt) รับค่าเป็น x-www-form-urlencoded
    return this.http.post<any>(this.baseUrl, payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  //Renew หนังสือแบบเป็น List
  userRenewList(id, BarcodeList: HoldList[]) {
   // console.log(BarcodeList);
    this.baseUrl = `${environment.apiurl}/api/RenewItemList/${id}`;
    return this.http.post<ResponseHoldList[]>(this.baseUrl, BarcodeList, {
      headers: this.header,
    });
  }

  //แนะนำ หนังสือ
  acqSuggestionAdd(id, acq: aquisition) {
    //ไว้ส่งค่า
    // let body = new URLSearchParams();
    // body.set('barcode', barcode);
    const payload = new HttpParams()
      .set('title', acq.title)
      .set('author', acq.author)
      .set('amount', acq.amount)
      .set('isn', acq.isn)
      .set('prices', acq.prices)
      .set('reason', acq.reason);

    this.baseUrl = `${environment.apiurl}/api/AcqSuggestion/${id}`;
    //Api จากพี่รัตรรับค่าเป็น x-www-form-urlencoded
    return this.http.post<any>(this.baseUrl, payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  //แสดงรายการแนะนำหนังสือ
  opacMemberSuggestList(id) {
    this.baseUrl = `${environment.apiurl}/api/AcqSuggestion/${id}`;
    return this.http.get<ResponseSuggestList>(this.baseUrl, httpOptions);
  }

  public reviewDelete(id: string): Observable<any> {
    return this.http.delete<any[]>(
      `${environment.apiurl}/api/deleteReview/${id}`,
      httpOptions
    );
  }

  addMemberReview(val: reviewDataAdd): Observable<any> {
    return this.http.post<any>(`${environment.apiurl}/api/bookAddReview`, val, {
      headers: this.header,
    });
  }

  updateMemberReview(val: reviewDataAdd): Observable<any> {
    return this.http.put<any>(
      `${environment.apiurl}/api/bookUpdateReview`,
      val,
      { headers: this.header }
    );
  }

  //get Reviews count
  opacMemberGetReviewsCount(id) {
    this.baseUrl = `${environment.apiurl}/api/reviewCount/${id}`;
    return this.http.get<number>(this.baseUrl, httpOptions);
  }

  opacMemberReviewList(id, currentPage, perPage) {
    this.baseUrl = `${environment.apiurl}/api/reviewListMember/${id}/${currentPage}/${perPage}`;
    return this.http.get<responseReviewList>(this.baseUrl, httpOptions);
  }

  /**status user สำหรับ Request Delivery */
  getBlockNotice(id) {
    this.baseUrl = `${environment.apiurl}/api/GetBlockNotice/${id}`;
    return this.http.get<any[]>(this.baseUrl, httpOptions);
  }


  public NDDAddBasket(id:string,NDDBasketData: any): Observable<any> {
    return this.http.put<any>( `${environment.apiurl}/api/NDDAddBasket/${id}`,
    NDDBasketData,
      { observe: 'response' }).pipe(catchError(this.handleError));
  }

    //NDDBasketlist
    NDDBasketlist(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDBasketlist/${id}`;
      //console.log(environment.apiurl);
      return this.http.get<NDDBasketlistResponse>(this.baseUrl, httpOptions);
    }

    public NDDRemovefromBasket(userid: string,ddinbasketid:string ): Observable<any> {
      this.baseUrl = `${environment.apiurl}/api/NDDRemovefromBasket/${userid}/${ddinbasketid}`;
    // console.log(this.baseUrl)
      return this.http
        .put<any>( this.baseUrl, {
          observe: 'response',
        })
        .pipe(catchError(this.handleError));
    }
  

    getNDDMemberEmail(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDMemberEmail/${id}`;
      return this.http.get<any[]>(this.baseUrl, httpOptions);
    }

    public getNDDMemberAddress(id): Observable<any> {
      return this.http
        .get<any>(`${environment.apiurl}/api/NDDMemberAddress/${id}`, {
          observe: 'response',
        })
        .pipe(catchError(this.handleError));
    }


    getNDDProvince() {
      this.baseUrl = `${environment.apiurl}/api/NDDProvince`;
      return this.http.get<any[]>(this.baseUrl, httpOptions);
    }

    

    getNDDDistrictbyProvince(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDDistrictbyProvince?provinceid=${id}`;
      return this.http.get<any[]>(this.baseUrl, httpOptions);
    }

    getNDDPostcode(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDPostcode?districtid=${id}`;
      return this.http.get<any[]>(this.baseUrl, httpOptions);
    }

    getNDDPointShow(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDPointShow/${id}`;
      return this.http.get<any>(this.baseUrl, httpOptions);
    }


    getNDDUserPrivilege(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDUserPrivilege/${id}`;
      return this.http.get<any>(this.baseUrl, httpOptions);
    }

    NDDPointTimeShow(locationid,pointid) {
      this.baseUrl = `${environment.apiurl}/api/NDDPointTimeShow/${locationid}/${pointid}`;
      return this.http.get<any>(this.baseUrl, httpOptions);
    }


    public NDDTypeOpen(id): Observable<any> {
      return this.http
        .get<any>(`${environment.apiurl}/api/NDDTypeOpen/${id}`, {
          observe: 'response',
        })
        .pipe(catchError(this.handleError));
    }

    public NDDEditBasket(userid,ddinbasketid,nddBasketData: NDDBasketData): Observable<any> {
      return this.http.put<any>( `${environment.apiurl}/api/NDDEditBasket/${userid}/${ddinbasketid}`,
      nddBasketData,
        { observe: 'response' }).pipe(catchError(this.handleError));
    }

    public NDDShippingCost(): Observable<any> {
      return this.http
        .get<any>(`${environment.apiurl}/api/NDDShippingCost`, {
          observe: 'response',
        })
        .pipe(catchError(this.handleError));
    }


    public NDDAddRequest(userid,ddinbasketid,NddRequestData: NDDRequestData): Observable<any> {
      return this.http.post<any>( `${environment.apiurl}/api/NDDAddRequest/${userid}/${ddinbasketid}`,
      NddRequestData,
        { observe: 'response' }).pipe(catchError(this.handleError));
    }

    NDDAgreement(id) {
      this.baseUrl = `${environment.apiurl}/api/NDDAgreement/${id}`;
      return this.http.get<any>(this.baseUrl, httpOptions);
    }

    getLoginType() {
      this.baseUrl = `${environment.apiurl}/api/loginType`;
      return this.http.get<string>(this.baseUrl, httpOptions);
    }
  
    // public updatePassword(userid,password: UserPassword): Observable<any> {
    //   return this.http.put<any>( `${environment.apiurl}/api/UserPassword/${userid}`,
    //   password,
    //     { observe: 'response' }).pipe(catchError(this.handleError));
    // }

    public updatePassword(userid,password: UserPassword): Observable<any> {
      return this.http.put<any>( `${environment.apiurl}/api/UserPassword/${userid}`,
      password,
        { observe: 'response' }).pipe(
          map(response => response))
    }


    updatePassword1(userid,password: UserPassword){
      return new Promise((resolve,reject) => {
          this.http.put(`${environment.apiurl}/api/UserPassword/${userid}`, password, { observe: 'response' }).subscribe(response => {
              if (response.status == 200) {
                  resolve(200);
              } else {
                resolve("");
              }
          },err => {
            const error =err.error?.Message 
       //     const error = err.error?.error_description || err.error?.Message || err.statusText;
     //  console.log(err)
            return   resolve(error);

           
          })
      })
  }

    // updatePassword1(userid,password: UserPassword):  Observable<any> {
    //   return this.http.put<any>( `${environment.apiurl}/api/UserPassword/${userid}`,
    //   password, {
    //     headers: this.header,
    //   });
    // }


    addMemberTagCloud(val: TagCloudDataAdd): Observable<any> {
      return this.http.post<any>(`${environment.apiurl}/api/AddTags`, val, {
        headers: this.header,
      });
    }

      //get Tag 
  opacMemberGetUserTag(id) {
    this.baseUrl = `${environment.apiurl}/api/GetUserTag/${id}`;
    return this.http.get<TagCloudNameList[]>(this.baseUrl, httpOptions);
  }

  opacMemberGetUserTagDetail(id,tagWord) {
    this.baseUrl = `${environment.apiurl}/api/GetUserTagDetail/${id}/${tagWord}/1/1000`;
    return this.http.get<responseTagsCloudBookList>(this.baseUrl, httpOptions);
  }

  opacMemberDeleteTag(val : DeleteTagsParam) {
    this.baseUrl = `${environment.apiurl}/api/DeleteTag`;
    return this.http.post<any>(this.baseUrl,val,{
      headers: this.header
    });
  }

  opacMemberSuggestFriend(val : SuggestFriendParam) {
    this.baseUrl = `${environment.apiurl}/api/opacMemberSuggestFriend`;
    return this.http.post<any>(this.baseUrl,val,{
      headers: this.header
    });
  }

  OpacMemberGetSuggestFriendList(id) {
    this.baseUrl = `${environment.apiurl}/api/GetSuggestFriendList/${id}`;
    return this.http.get<SuggestFriendModel[]>(this.baseUrl, httpOptions);
  }

  public DeleteSuggestFriend(SFID: string): Observable<any> {
    return this.http.delete<any[]>(
      `${environment.apiurl}/api/DeleteSuggestFriend?SFID=${SFID}`,
      httpOptions
    );
  }



  

}
