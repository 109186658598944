import { Component, OnInit } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { DataService } from 'src/app/services/data.service';
import { HomeService } from 'src/app/services/home.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  // OpenConsent: boolean = true
  Telephone: string;
  EmailContact: string;
  addressEN: string;
  facebook: string;
  AboutUsFooter: string;
  backgroundFooter: string = 'var(--wl-bg-color-footer)';
  colorFooter: string = 'var(--wl-color-footer)';
  logoSubcription: Subscription = new Subscription();
  pdpaUrl: '';
  ngOnDestroy(): void {
    if (this.logoSubcription) {
      this.logoSubcription.unsubscribe();
    }
  }
  constructor(
    private homeService: HomeService,
    private adminService: AdminService,
    private data: DataService
  ) {
    this.AboutUsFooter = '';
    this.homeService.getParameter('AboutUsFooter').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.AboutUsFooter = VALUE[0].VALUE;
      }
    });


    this.Telephone = '';
    this.homeService.getParameter('TelNo').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.Telephone = VALUE[0].VALUE;
      }
    });

    this.EmailContact = '';
    this.homeService.getParameter('EmailContact').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.EmailContact = VALUE[0].VALUE;
      }
    });

    this.addressEN = '';
    this.homeService.getParameter('addressEN').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.addressEN = VALUE[0].VALUE;
      }
    });

    this.facebook = '';
    this.homeService.getParameter('LibraryFacebookURL').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.facebook = VALUE[0].VALUE;
      }
    });

    this.getcolor();

    this.homeService.getParameter('PDPAUrl').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.pdpaUrl = VALUE[0].VALUE;
      }
    });


  }


  getcolor() {  
    
    this.data.currentcolorBGFooter.subscribe(
      (backgroundFooter) => (this.backgroundFooter = backgroundFooter)
    );

    this.data.currentcolorFooter.subscribe(
      (colorFooter) => (this.colorFooter = colorFooter)
    );

    // this.adminService
    //   .getColorSetting('FooterColor')
    //   .subscribe((val: string) => {
    //     if (val != '') {
    //       this.colorFooter = val;
    //     }
    //   });


    this.logoSubcription =  this.adminService.getColorSettingPipe('FooterColor')
    .pipe(
      switchMap(async (res: any) => {
       
        switch (res.status) {
          case 200:
            this.colorFooter =res.body
            break;
          default:
        }
      }),
      catchError((e) => {
        return of([]);
      })
    )
    .subscribe();

    this.logoSubcription =  this.adminService.getColorSettingPipe('FooterBg')
    .pipe(
      switchMap(async (res: any) => {
       
        switch (res.status) {
          case 200:
            this.backgroundFooter =res.body
            break;
          default:
        }
      }),
      catchError((e) => {
        return of([]);
      })
    )
    .subscribe();

    // this.adminService.getColorSetting('FooterBg').subscribe((val: string) => {
    //   if (val != '') {
    //     this.backgroundFooter = val;
    //   }
    // });
 
  }


  // acceptPDPA(){
  //   this.OpenConsent = false
  //   localStorage.setItem('OpenConsent', 'true')
  // }
}
