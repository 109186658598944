<!-- <div class="card" aria-hidden="true">
  <img src="..." class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title placeholder-glow">
      <span class="placeholder col-6"></span>
    </h5>
    <p class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
    </p>
    <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
  </div>
</div> -->

<div class="row" *ngIf="loading">
  <div class="col-md-2">
    <img
      src="assets/images/bookcover/loading.jpg"
      class="img-fluid img-thumbnail img-thumbnail-walai custom-img-shadow max-height-180"
    />
  </div>
  <div class="col-md-9">
    <h5 class="card-title placeholder-glow">
      <span class="placeholder col-6"></span>
    </h5>
    <p class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
    </p>
  </div>
</div>

<div class="row" *ngIf="!loading">
  <div class="col-md-auto pt-3 pt-lg-0 text-center text-lg-start">
    <img
      src="{{ bookcoverUrl }}"
      class="img-fluid img-thumbnail img-thumbnail-walai shadow max-height-180 mx-auto"
      onError="this.src='assets/images/bookcover/noimage.jpg'"
    />
  </div>
  <div class="col-md-9 pt-3 pt-lg-0">
    <a
    class="a-walai-book h5"
    target="_blank"
    href="bibitem?bibid={{ bibid }}"
    > {{ title }}</a>


    <div class="row" *ngIf="author">
      <div class="col-md-2 info-label">
        {{ "Author" | translate }}
      </div>
      <div class="col-md-9">
        {{ author }}
      </div>
    </div>
    <div class="row mb-2" *ngIf="published">
      <div class="col-md-2 info-label">
        {{ "Published" | translate }}
      </div>
      <div class="col-md-9">
        {{ published }}
      </div>
    </div>

    <app-electronic-resource [bibid]="bibid"></app-electronic-resource>
    
  </div>
</div>
