import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  bookcaseMap,
  bookcoverOnShelf,
  bookshelfinfo,
  bookshelfpoint,
  itembookshelfinfo,
} from '../models/modelVirtualbook';
import { HomeService } from '../services/home.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AutocompleteService } from '../services/autocomplete.service';
import { AutoComplete, AutoCompleteResponse } from '../models/walai';

@Component({
  selector: 'app-virtualbookshelf',
  templateUrl: './virtualbookshelf.component.html',
  styleUrls: ['./virtualbookshelf.component.css'],
})
export class VirtualbookshelfComponent implements OnInit {
  itemid: string = '';
  bookshelf: bookshelfinfo;
  activeId = '1';
  bookcaseRow: bookcaseRow[] = [];
  bookcaseCol: bookcaseCol[] = [];
  bookcovers:any[];
  currentRow = 0;
  currentCol = 0;
  citemsBookcover: object[] = [];

  imgMap = 'assets/images/bookcover/noimage.jpg';
  noImg = 'assets/images/bookcover/noimage.jpg';
  itembookshelf: itembookshelfinfo;
  myAngularxQrCode: string = null;
  QrCodeIteminfo: string = null;
  currentUser: any[] = [];

  i = 1;
  count = 1;

  bookCoverPath: string;

  selectedRows: any[];
  scrollItems: any[];
  showScroll: boolean;
  
  qsearchForm: UntypedFormGroup;
  listAuto: AutoCompleteResponse[] = [];
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private spinner: NgxSpinnerService, private appService: AutocompleteService,
  ) {
    this.qsearchForm = new UntypedFormGroup({
      textSearch: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {
    this.scrollItems = [
      'Herb Costales',
      'Gloria Cherie',
      'Paraskeva Goran',
      'Paskal',
      'Nadia',
      'Lyudmila',
      'Mihaela',
      'Snezhana',
      'Katya',
      'Ekaterina',
      'Milena',
      'Nedyalka',
      'Nadejda',
      'Hristina',
      'Denica',
      'Gabriela',
      'Kuzman',
      'Roza',
      'Genko',
      'Lyubomir',
      'Tereza',
      'Eva',
      'Zara',
      'Mila',
      'Veronika',
      'Blaga',
      'Ilarion',
      'Sofia',
      'Ignat'

    ];



    this.spinner.show(); // หมุนๆ
    this.route.paramMap.subscribe((params) => {
      this.itemid = params.get('itemid');
    });

    this.homeService.getBookCoverPath().subscribe((covers: any) => {
      this.bookCoverPath = covers;
    });


    this.currentUser = JSON.parse(localStorage.getItem('WALAIcurrentUser')) || [];
    this.homeService
      .getbookshelfinfo(this.itemid)
      .subscribe((shelfinfo: bookshelfinfo[]) => {
        this.bookshelf = shelfinfo[0];
      
        this.homeService
          .showbookcasemap(this.bookshelf.BOOKCASECODE)
          .subscribe((Map: bookcaseMap[]) => {
            this.imgMap = Map[0].IMAGEPATH;
            
        });

        
          this.homeService
          .showbookshelfpoint(this.bookshelf.BOOKSHELFCODE)
          .subscribe((point: bookshelfpoint) => {
       
            this.currentRow = point[0].SHELFROW+1;
            this.currentCol = point[0].SHELFCOL+1;

            // this.currentRow = 1;
            // this.currentCol = 1;
            // alert(  point[0].SHELFROW+' | '+    point[0].SHELFCOL  )
          //  this.getbookcase();
        });

      
        this.showbookcovers();
        this.getiteminfo(this.itemid);

        // BookshelfDiscovery/BookshelfDiscovery.aspx?CID=" + BookcaseID --> path สืบค้นบน bookcase นั้น
        this.myAngularxQrCode = this.bookshelf.BOOKCASECODE;
        
    });
  }

  getiteminfo(itemID) {
   let valItemID = itemID
    this.homeService
      .showiteminfo(valItemID)
      .subscribe((iteminfo: itembookshelfinfo[]) => {
        this.itembookshelf = iteminfo[0];
        this.QrCodeIteminfo = this.itembookshelf.BARCODE;
      
    });
     

  }

  tabitem() {
    this.activeId = '3';
  }

  showbookcovers() {
    this.homeService
      .showbookcovers(this.bookshelf.BOOKSHELFCODE)
      .subscribe((bookcovers: bookcoverOnShelf[]) => {
       
        this.bookcovers =bookcovers;
       
      });


      // this.spinner.hide();
  }

  // getbookcase() {
  //   for (let r = 1; r <= this.bookshelf.HEIGHT; r++) {
  //     if (this.currentRow === r) {
  //       let row: bookcaseRow = { item: 1 };
  //       this.bookcaseRow.push(row);
  //     } else {
  //       let row: bookcaseRow = { item: 0 };
  //       this.bookcaseRow.push(row);
  //     }
  //   }

  //   for (let c = 1; c <= this.bookshelf.WIDTH; c++) {
  //     if (this.currentCol === c) {
  //       let Col: bookcaseCol = {
  //         item: 1
  //       };
  //       this.bookcaseCol.push(Col);
  //     } else {
  //       let Col: bookcaseCol = {
  //         item: 0
  //       };
  //       this.bookcaseCol.push(Col);
  //     }
  //   }
  // }

  pinItem(barcode) {
    const userid = this.currentUser[0].USERID;
    this.homeService
      .pinitem(userid, barcode)
      .subscribe((result: any) => {
        if(result == ''){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Pin เรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }


  onImgError(event) {
    this.spinner.hide();
    event.target.src = 'assets/images/bookcover/noimage.jpg';

    // this.spinner.hide();
    // let img = new Image();
    // img.src = event.path[0].alt;

    // img.onload = () => {
    //   event.src = event.path[0].alt;
    // };

    // img.onerror = () => {
    //   event.src = 'assets/images/bookcover/noimage.jpg';
      
    // };
   
    
  }


  auto(value) {
    if (value.length >= 2) {
      let autoCompleteWord: AutoComplete;
      autoCompleteWord = new AutoComplete();
      autoCompleteWord.Keyword = value;
      autoCompleteWord.Location = '';
      this.appService.autoSuggest(autoCompleteWord).subscribe(
        (results: any) => {
          if (results) {
            JSON.stringify(results);
            this.listAuto = results;
            console.log(this.listAuto);
            // add newly fetched posts to the existing post
          } else {
            this.listAuto = [];
          }
        },
        (error) => {}
      );
    } else {
      this.listAuto = [];
    }
  }

    // สืบค้นแบบเร่งด้าน ในที่นี้คือการสืบค้นแบบ All key หรือทุกประเภท (มีการตั้งค่าไว้ใน Solr ว่า All ในที่นี่คือ Tag อะไรบ้าง)
    search() {
      if (
        this.qsearchForm.controls.textSearch.value != '' &&
        this.qsearchForm.controls.textSearch.value != null
      ) {
        this.EnterSearch(this.qsearchForm.controls.textSearch.value);
      }
    }
  
    EnterSearch(keyWord: string) {

//         this.Qkeyword = 'results?Ntk=KEYWORD&Ntt=' +keyWord;
// window.location.href = this.Qkeyword;

      }
      
    

}

export interface bookcaseRow {
  item: number;
}

export interface bookcaseCol {
  item: number;
}


export class itemCover {
  COUNT: number;
  ITEMID: string;
  BOOKCOVER: string;
  ACTIVE: string;
}
