import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
@Component({
  selector: 'app-location-name',
  templateUrl: './location-name.component.html',
  styleUrls: ['./location-name.component.css']
})
export class LocationNameComponent implements OnInit {
  @Input() LOCATIONID: string;

  locationName:string='';
  constructor(  private homeService: HomeService,) { }

  ngOnInit(): void {
    this.locationName ='';
    this.homeService.getLocationName(this.LOCATIONID).subscribe((Name_: string) => {
      if (Name_) {
      //  alert(Name_);
        this.locationName = Name_;
      } else {
        this.locationName = 'ไม่พบข้อมูล';
      }
    });


  }

}
