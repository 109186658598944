import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  FormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { HomeService } from 'src/app/services/home.service';
import {
  Keywords,
  AutoComplete,
  AutoCompleteResponse,
  category,
  CallNo,
  callNoResponse,
  locationCollectionResponse,
  EducationalResources,
  SearchTool,
} from '../models/walai';
import { HttpClient } from '@angular/common/http';
import { SearchService } from 'src/app/services/search.service';
import { AutocompleteService } from '../services/autocomplete.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
//import { map } from 'rxjs/operators';
import { AdminService } from '../services/admin.service';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  qsearchForm: UntypedFormGroup;
  bookListNew: any;
  bookListTopPick: any;
  bookListTopView: any;
  bookListLibSuggest: any;
  keyword: Keywords;
  Qkeyword: string;

  locationID: string;
  locationName: string;

  baseUrl: string = '';

  categorys: category[];

  listAuto: AutoCompleteResponse[] = [];

  word = '';
  banner = {};

  langs: any[];
  materialTypes: any[];
  callNoLC: CallNo[];
  callNoDC: CallNo[];
  callNoLocal: CallNo[];
  callNoType: string = '';
  moreLang: boolean = false;
  locationCollectionResponse: locationCollectionResponse[];

  EducationalResources: EducationalResources[];
  searchtools: SearchTool[];
  inputState: string = 'Keyword';

  LibrarianSuggestText = 'Librarian suggest'
  LibrarianSuggestDescText = 'Librarian suggest, have you tried reading yet?'
  IsImageError: boolean = false

  constructor(
    private homeService: HomeService,
    private searchService: SearchService,
    private appService: AutocompleteService,
    private dataS: DataService,
    private adminService: AdminService
  ) {
    this.categorys =[];
    // translate.setDefaultLang('th');
  }

  ngOnInit() {


    this.getBkUrl();

    this.baseUrl = `${environment.apiurl}/api/SuggestBookRSS/`;
    // สาขาจะถูก Set ค่าจาก localStorage ที่ผู้ใช้ได้เลือกไว้ด้านบน Web
    this.locationID = localStorage.getItem('locationIDMain'); // location หลัก
    this.locationName = localStorage.getItem('locationNameMain');

    this.qsearchForm = new UntypedFormGroup({
      textSearch: new UntypedFormControl(),
      typeOption: new UntypedFormControl('KEYWORD')
    });

 
    //new book
    this.homeService.getTop(6).subscribe((book: any) => {
      this.bookListNew = book.Table1;
    });

    //top pick
    this.homeService.getTop(4).subscribe((book: any) => {
      // console.log(book);
      this.bookListTopPick = book.Table1;
    });
    // console.log(this.bookListTopPick);

    //top view
    this.homeService.getTop(3).subscribe((book: any) => {
      this.bookListTopView = book.Table1;
    });

    //บรรณารักษ์แนะนำ
    this.homeService.getTop(5).subscribe((book: any) => {
      this.bookListLibSuggest = book.Table1;
    });

    this.getCategory();

    this.browse();

    this.EducationalResources = [];
    this.searchtools = [];
    this.getEducationalResources();
    this.getSearchTool();


    this.homeService.getParameter('LibrarianSuggestText').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.LibrarianSuggestText = VALUE[0].VALUE;
      }
    });

    this.homeService.getParameter('LibrarianSuggestDescText').subscribe((VALUE: any) => {
      if (VALUE.length == 1) {
        this.LibrarianSuggestDescText = VALUE[0].VALUE;
      }
    });
  
    
  //ประวัติการเข้าชม web
  this.dataS.addOpacview();

  }

  getBkUrl() {
    this.banner = {
      'background-image': 'url(assets/images/Banner4.jpg)',
    };

    this.adminService.getLogoBanner('banner').subscribe(async (data: any) => {
      this.banner = {
        'background-image': 'url(' + data + ')',
      };
    });
  }


  search() {
    if (
      this.qsearchForm.controls.textSearch.value != '' &&
      this.qsearchForm.controls.textSearch.value != null
    ) {
      this.EnterSearch(encodeURIComponent(this.qsearchForm.controls.textSearch.value));
    }
  }

  EnterSearch(keyWord: string) {
    let _typeOption = this.qsearchForm.controls.typeOption.value;
    
    if (this.locationID != '') {
      // กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
      this.Qkeyword =
        'results?Ntk='+ _typeOption +'|LOCATION&Ntt=' + keyWord + '|' + this.locationID;
    } else {
      this.Qkeyword = 'results?Ntk='+ _typeOption + '&Ntt=' + keyWord;
    }

    window.location.href = this.Qkeyword;
  }

  auto(value) {
    let searhtype = this.qsearchForm.controls.typeOption.value;
    searhtype = searhtype.toUpperCase();
    if( searhtype == 'KEYWORD' || searhtype == 'TITLE' ){

      if (value.length >= 2) {
        let autoCompleteWord: AutoComplete;
        autoCompleteWord = new AutoComplete();
        autoCompleteWord.Keyword = value;
        autoCompleteWord.Location = '';
        this.appService.autoSuggest(autoCompleteWord).subscribe(
          (results: any) => {
            if (results) {
              JSON.stringify(results);
              this.listAuto = results;
            
              // add newly fetched posts to the existing post
            } else {
              this.listAuto = [];
            }
          },
          (error) => {}
        );
      } else {
        this.listAuto = [];
      }

    }else{
      this.listAuto = [];
    }

    
    
  }

  // เลือกคำค้นจาก AutoComplete
  selectedKeyword(word) {
    // this.word = word;
    this.qsearchForm.controls.textSearch.setValue = word;
  }

  getCategory() {
    this.categorys=[];
    this.homeService.getCatagory().subscribe((category: category[]) => {
      this.categorys = category;
    });
  }

  browse() {
    this.getLang();
    this.getMaterialTypes();
    this.getCallNumber();
    this.getCollection();
  }

  getLang() {
    this.homeService.getLang().subscribe((langs: any) => {
      this.langs = langs;
    });
  }

  getMaterialTypes() {
    this.homeService.getMaterialType().subscribe((getMaterialType: any) => {
      this.materialTypes = getMaterialType;
    });
  }

  showMore() {
    this.moreLang = true;
  }
  showLess() {
    this.moreLang = false;
  }

  getCallNumber() {
    this.homeService
      .getCallNumber()
      .subscribe((callNoResponse: callNoResponse) => {
        this.callNoDC = callNoResponse.callNoDC;
        this.callNoLC = callNoResponse.callNoLC;
        this.callNoLocal = callNoResponse.callNoLocal;
        this.callNoType = callNoResponse.type;
      });
  }

  getCollection() {
    this.homeService
      .getCollection()
      .subscribe((locationCollectionResponse: locationCollectionResponse[]) => {
        this.locationCollectionResponse = locationCollectionResponse;
      });
  }

  getEducationalResources() {
    this.homeService
      .getEducationalResources()
      .subscribe((value: EducationalResources[]) => {
        this.EducationalResources = [];

        value.forEach((element, i) => {
          let objedu: EducationalResources = {
            IMG_ID: element.IMG_ID,
            IMG_FILENAME: element.IMG_FILENAME,
            IMG_DESC: element.IMG_DESC,
            IMG_LINKTO: element.IMG_LINKTO,
            IMG_TYPE: element.IMG_TYPE,
            LOCATIONID: element.LOCATIONID,
            IMG_URL: element.IMG_URL,
          };

          this.EducationalResources.push(objedu);
        });

        
      });
  }

  getSearchTool() {
    this.homeService.getSearchTool().subscribe((value: SearchTool[]) => {
      this.searchtools = [];
      value.forEach((element, i) => {
        
        let obj: SearchTool = {
          IMG_ID: element.IMG_ID,
          IMG_FILENAME: element.IMG_FILENAME,
          IMG_DESC: element.IMG_DESC,
          IMG_LINKTO: element.IMG_LINKTO,
          IMG_TYPE: element.IMG_TYPE,
          LOCATIONID: element.LOCATIONID,
          IMG_URL: element.IMG_URL,
        };

        this.searchtools.push(obj);

      });
      
    });
  }


    //เมื่อเปลี่ยนรูปแบบการสืบค้น
    SearchOption(event:string , text: string) {
      this.inputState = text;
      this.qsearchForm.controls.typeOption.setValue(event);

      this.auto(this.qsearchForm.controls.textSearch.value);
    }
  

    // imagerror(e){
    //   e.target.hidden = true;
    //   this.IsImageError = true;
    // }
 

    imagerror(e: any) {
      //e.target.hidden = true;
      e.target.src = 'assets/images/no-img.jpg'
      e.target.error = false
      //this.IsImageMemberError = true
      //e.target.className = 'img-fluid w-100 img-icon-user-default'
    }

}


