<!-- ใช้กับหน้า Bibitem -->
<button type="button" class="btn btn-primary btn-sm py-1 fw-light shadow me-2" data-bs-toggle="modal" data-bs-target="#collectionModal"
   *ngIf="page=='bibitem' &&  USERID!=''; else NoLogin">
  My list <i class="far fa-heart"></i>
</button>


<ng-template #NoLogin>
  <a *ngIf="page=='bibitem'" href="login" class="btn btn-light button-radius1 btn-walai-default">
    My list <i class="far fa-heart"></i>
  </a>

</ng-template>

<!-- ใช้กับหน้า อื่นๆ  -->
<a data-toggle="modal" data-bs-toggle="modal" data-bs-target="#collectionModal" *ngIf="page!='bibitem' &&  USERID!=''; else NoLogin1">
  <i class="far fa-heart"></i>
</a>
<ng-template #NoLogin1>
  <a *ngIf="page!='bibitem'" href="login">
    <i class="far fa-heart"></i>
  </a>

</ng-template>
<!-- Modal -->
<div class="modal fade" id="collectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="collectionModalLabel">Save to</h5>
      
      </div>
      <div class="modal-body">
        <div class="mb-2">   <a  data-bs-toggle="collapse" href="#collapseNewList" role="button" aria-expanded="false" aria-controls="collapseNewList" class="a-walai">
          <i class="fa fa-plus fa-1x" aria-hidden="true"></i> New list
        </a></div>
        <div class="collapse mb-3" id="collapseNewList">
        <form class="text-left" [formGroup]="addCollectionForm">
          <div class="modal-content">
           
            <div class="modal-body text-left">
    
              <div class="form-group">
                <label for="recipient-name" class="col-form-label">List name:</label>
                <input type="text" class="form-control" formControlName="addCollectionForm_name" />
              </div>
         
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" [disabled]="!addCollectionForm.valid"
                (click)="addCollection()">Add</button>
            </div>
          </div>
        </form>
        </div>
        <div class="card mb-3" *ngFor="let item of collectionList">
          <div class="card-body text-left media text-muted pt-3">
            <img *ngIf="item.Collctions.length > 0; else NoBookCover" class="mr-2 rounded"
              src="{{ item.Collctions[0].BookCover }}" data-holder-rendered="true" style="width: 50px"
              onError="this.src='./assets/images/bookcover/noimage.jpg'"
              />

            <ng-template #NoBookCover>
              <img class="mr-2 rounded" src="./assets/images/bookcover/noimage.jpg" data-holder-rendered="true"
                style="width: 50px"
                
                />
            </ng-template>

            <div class="media-body pb-3 mb-0">
              <div class="d-flex justify-content-between align-items-center w-100">
                <strong class="text-gray-dark">{{ item.NAME }}</strong>

                <a class="a-walai" (click)="deletebib(item.MLID)" *ngIf="item.HaveBibID; else DontHaveBibID"><i
                    class="fa fa-minus-circle fa-2x text-danger" aria-hidden="true"></i></a>
                <ng-template #DontHaveBibID>
                  <a class="a-walai" (click)="addbib(item.MLID)"><i class="fa fa-plus-circle fa-2x text-success"
                      aria-hidden="true"></i></a>
                </ng-template>
              </div>
              <span class="d-block">{{ item.Collctions.length }} titles</span>
            </div>
          </div>
        </div>

        <!-- <div class="card mb-3" >
                    <div class="card-body text-left media text-muted pt-3">
                        <img  
                        class="mr-2 rounded" 
                        src="./assets/images/bookcover/1.jpg" 
                        data-holder-rendered="true" style="width: 50px;">
                        <div class="media-body pb-3 mb-0 ">
                          <div class="d-flex justify-content-between align-items-center w-100">
                            <strong class="text-gray-dark">Computer</strong>
                            <a href="#"><i class="fa fa-plus-circle fa-2x text-success"  aria-hidden="true"></i></a>
                          </div>
                          <span class="d-block">10 titles</span>
                        </div>
                      </div>
              </div>

              <div class="card mb-3" >
                <div class="card-body text-left media text-muted pt-3">
                    <img  
                    class="mr-2 rounded" 
                    src="./assets/images/bookcover/1.jpg" 
                    data-holder-rendered="true" style="width: 50px;">
                    <div class="media-body pb-3 mb-0 ">
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <strong class="text-gray-dark">Computer</strong>
                        <a href="#"><i class="fa fa-minus-circle fa-2x text-danger"  aria-hidden="true"></i></a>
                      </div>
                      <span class="d-block">10 titles</span>
                    </div>
                  </div>
          </div> -->
      </div>
      <div class="modal-footer">
      
        <button type="button" class="btn" data-bs-dismiss="modal">Done</button>
      </div>
    </div>
  </div>
</div>


