import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  Keywords,
  Mattype,
  Facet,
  CurrentFilter,
  AuthSeeAlso,
  AuthSymbol,
} from 'src/app/models/walai';
import { SearchService } from 'src/app/services/search.service';
import { HomeService } from 'src/app/services/home.service';
import {
  UntypedFormGroup,
  FormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';

import * as $ from 'jquery';

import { DataService } from '../services/data.service';
import { AdminService } from '../services/admin.service';
import { environment } from 'src/environments/environment';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
})
export class ResultsComponent implements OnInit {
  bookList: any;
  baseUrl: string = '';
  auth: AuthSeeAlso[] = [];
  BT: AuthSeeAlso;
  NT: AuthSeeAlso;
  RT: AuthSeeAlso;
  LV: AuthSeeAlso;
  AuthSymbol: AuthSymbol[] = [];
  AuthViewOPAC: string = '';

  bookcount = '...';
  keyword: Keywords;
  Mattype: Mattype;
  CurrentFilter: Array<CurrentFilter> = []; //คำที่ค้น
  CurrentKeyword: Array<CurrentFilter> = []; //คำที่ค้น
  DisplayTable: string;
  DisplayList: string;

  Ntk: any[];
  Ntt: any[];
  Nto: any[];

  locationID: string;
  locationName: string;

  qsearchForm: UntypedFormGroup;

  notEmptyPost = true;
  notscrolly = true;

  allFacet: Facet[];
  subjectFacet: Array<Facet> = [];
  subjectFacetLess: Array<Facet> = [];
  subjectFacetLessShow: boolean = true;

  authorFacet: Array<Facet> = [];
  authorFacetLess: Array<Facet> = [];
  authorFacetLessShow: boolean = true;

  locationFacet: Array<Facet> = [];
  locationFacetLess: Array<Facet> = [];
  locationFacetLessShow: boolean = true;

  sourceTypeFacet: Array<Facet> = [];
  sourceTypeFacetLess: Array<Facet> = [];
  sourceTypeFacetLessShow: boolean = true;

  collectionFacet: Array<Facet> = [];
  collectionFacetLess: Array<Facet> = [];
  collectionFacetLessShow: boolean = true;

  langFacet: Array<Facet> = [];
  langFacetLess: Array<Facet> = [];
  langFacetLessShow: boolean = true;

  RegionFacet: Array<Facet> = [];
  RegionFacetLess: Array<Facet> = [];
  RegionFacetLessShow: boolean = true;

  EraFacet: Array<Facet> = [];
  EraFacetLess: Array<Facet> = [];
  EraFacetLessShow: boolean = true;

  CallNoLCFacet: Array<Facet> = [];
  CallNoLCFacetLess: Array<Facet> = [];
  CallNoLCFacetLessShow: boolean = true;

  CallNoDCFacet: Array<Facet> = [];
  CallNoDCFacetLess: Array<Facet> = [];
  CallNoDCFacetLessShow: boolean = true;

  CallNoNLMFacet: Array<Facet> = [];
  CallNoNLMFacetLess: Array<Facet> = [];
  CallNoNLMFacetLessShow: boolean = true;

  IllustrationsFacet: Array<Facet> = [];
  IllustrationsFacetLess: Array<Facet> = [];
  IllustrationsFacetLessShow: boolean = true;

  TargetAudienceFacet: Array<Facet> = [];
  TargetAudienceFacetLess: Array<Facet> = [];
  TargetAudienceFacetLessShow: boolean = true;

  FormofItemFacet: Array<Facet> = [];
  FormofItemFacetLess: Array<Facet> = [];
  FormofItemFacetLessShow: boolean = true;

  NatureOfContentsFacet: Array<Facet> = [];
  NatureOfContentsFacetLess: Array<Facet> = [];
  NatureOfContentsFacetLessShow: boolean = true;

  GovernmentPublicationFacet: Array<Facet> = [];
  GovernmentPublicationFacetLess: Array<Facet> = [];
  GovernmentPublicationFacetLessShow: boolean = true;

  ConferencePublicationFacet: Array<Facet> = [];
  ConferencePublicationFacetLess: Array<Facet> = [];
  ConferencePublicationFacetLessShow: boolean = true;

  FestschriftFacet: Array<Facet> = [];
  FestschriftFacetLess: Array<Facet> = [];
  FestschriftFacetLessShow: boolean = true;

  LiteraryFormFacet: Array<Facet> = [];
  LiteraryFormFacetLess: Array<Facet> = [];
  LiteraryFormFacetLessShow: boolean = true;

  BiographyFacet: Array<Facet> = [];
  BiographyFacetLess: Array<Facet> = [];
  BiographyFacetLessShow: boolean = true;

  facetLess: number = 6;
  year_max = 0;
  year_min = 0;
  year_Cmin_value =0;
  year_Cmax_value = 0;
  // minYear: number;
  // maxYear: number;

  // minYearValue: number = 0;
  // maxYearValue: number = 0;
  //optionsYear: Options;
  // optionsYear: Options = {
  //   floor: 0,
  //   ceil: 0,
  // };
  // options: Options = {
  //   floor: 1000,
  //   ceil: 2563,
  // };
  showSubject: number = 0;
  showAuthor: number = 0;
  showlocation: number = 0;
  showMattype: number = 0;
  showPubyear: number = 0;
  showCollection: number = 0;
  showLang: number = 0;
  showRegion: number = 0;
  showEra: number = 0;
  showCallNoLC: number = 0;
  showCallNoDC: number = 0;
  showCallNoNLM: number = 0;
  showIllustrations: number = 0;
  showTargetAudience: number = 0;
  showFormofItem: number = 0;
  showNatureOfContents: number = 0;
  showGovernmentPublication: number = 0;
  showConferencePublication: number = 0;
  showFestschrift: number = 0;
  showLiteraryForm: number = 0;
  showBiography: number = 0;

  inputState: string = 'Keyword';

  suggesterWord: string[] = [];
  pageEvent: PageEvent;
  Page: number=1;
Size: number=16;
recordCount: number= 0;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private homeService: HomeService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private data: DataService,
    private adminService: AdminService,
    private router: Router
  ) {}

  pageNavigations(event? : PageEvent){
  
    this.Page = event.pageIndex;
    this.Size = event.pageSize;

    this.PageEventChange(this.Page+1);
  }

  PageEventChange(page:number) {
    this.keyword.CurrentPage = page
    const navigationExtras: NavigationExtras = {
      queryParams: {
        Ntk: this.keyword.Ntk,
        Ntt: this.keyword.Ntt,
        Nto: this.keyword.Nto,
        CurrentPage:  this.keyword.CurrentPage
      },
    };
    this.router.navigate(['/results'], navigationExtras);


    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth',
    });

    this.searchService.keywordsearch(this.keyword).subscribe(
      (results: any) => {
        if (results) {

          this.bookList = results.Display;
          this.spinner.hide();
        
        }
      },
      (error) => {}
    );
  }

  loadFacetDisplay() {
    this.adminService.getFacetSetting().subscribe((setting: any[]) => {
      setting.forEach((element) => {
        switch (element.ENG) {
          case 'Nature of contents':
            this.showNatureOfContents = element.DISPLAY;
            break;
          case 'Government publication':
            this.showGovernmentPublication = element.DISPLAY;
            break;
          case 'Conference publication':
            this.showConferencePublication = element.DISPLAY;
            break;
          case 'Festschrift':
            this.showFestschrift = element.DISPLAY;
            break;
          case 'Literary form':
            this.showLiteraryForm = element.DISPLAY;
            break;
          case 'Biography':
            this.showBiography = element.DISPLAY;
            break;
          case 'Author':
            this.showAuthor = element.DISPLAY;
            break;
          case 'Subject':
            this.showSubject = element.DISPLAY;
            break;
          case 'Pubyear':
            this.showPubyear = element.DISPLAY;
            break;
          case 'Mattype':
            this.showMattype = element.DISPLAY;
            break;
          case 'Menber Library':
            this.showlocation = element.DISPLAY;
            break;
          case 'Lang':
            this.showLang = element.DISPLAY;
            break;
          case 'Geo Area':
            this.showRegion = element.DISPLAY;
            break;
          case 'Era':
            this.showEra = element.DISPLAY;
            break;
          case 'Call no LC':
            this.showCallNoLC = element.DISPLAY;
            break;
          case 'Call no DC':
            this.showCallNoDC = element.DISPLAY;
            break;
          case 'Call no NLM':
            this.showCallNoNLM = element.DISPLAY;
            break;
          case 'Illustrations':
            this.showIllustrations = element.DISPLAY;
            break;
          case 'Target audience':
            this.showTargetAudience = element.DISPLAY;
            break;
          case 'Form of item':
            this.showFormofItem = element.DISPLAY;
            break;
          case 'Collection':
            this.showCollection = element.DISPLAY;
            break;
          default:
            break;
        }
      });
    });
  }

  ngOnInit(): void {
    this.loadFacetDisplay();
    //โหลดมาครั้งแรก

    // ไว้ซ่อน Menu bar ส่วน Filter ด้านซ้าย กรณีที่เป็น UI Mobile
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });

    this.baseUrl = `${environment.apiurl}/api/BookKeywordSearchRSS/`;

    //โหลดครั้งแรกให้แสดงผลแบบรายละเอียดหรือในที่นี่เราแทนแบบ Table
    this.DisplayTable = 'Table';

    //ดึงค่าตัวแปรสาขามาเพื่อใช้เป็นตัวกรองการสืบค้น
    this.locationID = localStorage.getItem('locationIDMain'); //location หลัก
    this.locationName = localStorage.getItem('locationNameMain');

    this.keyword = new Keywords(); //ตัวแปรแบบ  Object ใช้เพื่อส่งค่าไปยัง API การสืบค้น โดยโหลดมาครั้งแรกจะไปตัดมาจาก Get URL
    if (this.route.snapshot.queryParamMap.get('Ntk')) {
      this.keyword.Ntk = this.route.snapshot.queryParamMap.get('Ntk');
    } else {
      this.keyword.Ntk = 'KEYWORD';
    }
    if (this.route.snapshot.queryParamMap.get('Ntt')) {
      this.keyword.Ntt = this.route.snapshot.queryParamMap.get('Ntt');
    } else {
      this.keyword.Ntt = '';
    }
    if (this.route.snapshot.queryParamMap.get('Nto')) {
      this.keyword.Nto = this.route.snapshot.queryParamMap.get('Nto');
    } else {
      this.keyword.Nto = '';
    }
    if (this.route.snapshot.queryParamMap.get('CurrentPage')) {
      this.keyword.CurrentPage = Number(
        this.route.snapshot.queryParamMap.get('CurrentPage')
      );
    } else {
      this.keyword.CurrentPage = 1;
    }
    if (this.route.snapshot.queryParamMap.get('RowPerPage')) {
      this.keyword.RowPerPage = Number(
        this.route.snapshot.queryParamMap.get('RowPerPage')
      );
    } else {
      this.keyword.RowPerPage = this.Size//24;
    }
    if (this.route.snapshot.queryParamMap.get('Orderby')) {
      this.keyword.Orderby = this.route.snapshot.queryParamMap.get('Orderby');
    } else {
      this.keyword.Orderby = 'RELEVANCE';
    }

    this.keywordSearch(this.keyword);

    this.inputState = this.CurrentKeyword[0].Ntk;

    //ดึงค่าคำค้นตัวแรกไปแสดงใน  TextBox
    try {
      this.qsearchForm = new UntypedFormGroup({
        textSearch: new UntypedFormControl(this.Ntt[0]),
        typeOption: new UntypedFormControl(this.CurrentKeyword[0].Ntk),
      });

      this.Suggester(this.qsearchForm.controls['textSearch'].value);
    } catch (error) {}
  }

  loadRSS() {
    this.baseUrl =
      `${environment.apiurl}/api/BookKeywordSearchRSS/` +
      this.keyword.Ntk +
      '/' +
      this.keyword.Ntt +
      '/' +
      this.keyword.Nto;
    if (this.keyword.Nto == '') {
      this.baseUrl =
        `${environment.apiurl}/api/BookKeywordSearchRSS/` +
        this.keyword.Ntk +
        '/' +
        this.keyword.Ntt +
        '/0';
    }
    //window.location.href =  this.baseUrl ;
    window.open(
      this.baseUrl,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  //ดึงข้อมูล Auth
  Auth(keyword_: Keywords) {
    this.AuthSymbol = [];
    this.auth = [];
    //ส่งคำไปสืบค้นและรอ Api
    this.searchService.AuthSeeAlso(keyword_).subscribe(
      (results: AuthSeeAlso[]) => {
        if (results) {
          this.auth = results;

          this.auth.forEach((element) => {
            switch (element.REF) {
              case 'Broader Term':
                // BT: AuthSeeAlso;
                this.BT = element;
                break;
              case 'Narrower Term':
                // NT: AuthSeeAlso;
                this.NT = element;
                break;
              case 'Related Term':
                // RT: AuthSeeAlso;
                this.RT = element;
                break;
              case 'Language Variants':
                // LV: AuthSeeAlso
                this.LV = element;
                break;
              default:
                break;
            }
          });
        } else {
        }
      },
      (error) => {}
    );
  }

  showAuth(authID: string, type: string) {
    this.AuthSymbol = [];
    this.searchService.AuthSymbol(authID, type).subscribe(
      (results: AuthSymbol[]) => {
        if (results) {
          this.AuthSymbol = results;
        } else {
          this.AuthSymbol = [];
        }
      },
      (error) => {
        this.AuthSymbol = [];
      }
    );
  }

  Suggester(keyword: string) {
    this.suggesterWord = [];
    this.searchService
      .keywordSuggester({
        Keyword: keyword,
      })
      .subscribe(
        (results: string[]) => {
          if (results) {
            this.suggesterWord = results;
            console.log(this.suggesterWord);
          } else {
            this.suggesterWord = [];
          }
        },
        (error) => {
          this.suggesterWord = [];
        }
      );
  }

  searchAuth(authID, AuthViewOPAC) {
    this.AuthViewOPAC = AuthViewOPAC;
    //ดึง Location ที่มีการใส่ไว้ใน  localStorage มาส่งเพิ่มใน Function สืบค้น เพราะในระบบนี้มีการแสดงผลแบบแยก Location เป็นหลัก
    this.locationID = localStorage.getItem('locationIDMain'); //location หลัก
    this.locationName = localStorage.getItem('locationNameMain');

    //เพิ่มค่าให้แก่ตัวแปร  Keyword Ntk คือประเภทการสืบค้น Ntt คือคำค้น Nto คือ Operator ที่ใช้สำหรับคำค้นที่มีมากกว่าหนึ่งซึ่งเป็นได้ทั้ง And Or Not
    this.keyword.Ntk = 'AUTHLINK';
    this.keyword.Ntt = authID;

    if (this.locationID != '') {
      //กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
      this.keyword.Ntk += '|LOCATION';
      this.keyword.Ntt += '|' + this.locationID;
    }
    this.keyword.Nto = '';
    this.keyword.CurrentPage = 1; // CurrentPage หน้าที่แสดงผลในการสืบค้นครั้งใหม่จะเริ่มที่หน้าแรกเสมอ
    this.keywordSearch(this.keyword); //ส่งค่าไปยัง Function การสืบค้น
  }

  //Function การสืบค้นที่มีการไปดึงผลการสืบค้นจาก API
  keywordSearch(keyword_: Keywords) {
    this.recordCount =0;
    this.Ntk = keyword_.Ntk.split('|'); //ประเภทการสืบค้น
    this.Ntt = keyword_.Ntt.split('|'); //คำค้น
    this.Nto = keyword_.Nto.split('|');

    //แสดงรายการคำค้น ประเภทการสืบค้น และ ตัวกรองต่างๆ ที่ใช้
    this.GetCurrentFilterAndKeyword();
    this.bookList = [];

    //หมุนๆ รอก่อนผลจะมา
    this.spinner.show();

    //ส่งคำไปสืบค้นและรอ Api
    this.searchService.keywordsearch(keyword_).subscribe(
      (results: any) => {
        // console.log(results);
        if (results) {
         
          this.bookcount = results.Counts[0].Count; //จำนวนผลการสืบค้น
          try {
             this. recordCount= Number(this.bookcount ) ;
          } catch (error) {
            
          }

          this.bookList = results.Display; //รายการหนังสือที่เจอ
          this.allFacet = results.Narrow; //ตัวกรองทั้งหมด

          this.getFacet(); //แสดงรายละเอียดของตัวกรองด้านซ้าย
        } else {
          this.setNull(); //ในกรณีไม่พบผลลัพธ์การสืบค้นให้ Set ตัวแปรต่างๆ เป็นค่าว่าง เพื่อให้ Control ที่แสดงผลเป็นค่าว่างๆ ผู้ใช้จะได้ไม่ งง
        }
        this.spinner.hide(); //ซ่อนตัวหมุนๆ
      },
      (error) => {
        //ในกรณี Error  ให้ Set ตัวแปรต่างๆ เป็นค่าว่าง เพื่อให้ Control ที่แสดงผลเป็นค่าว่างๆ ผู้ใช้จะได้ไม่ งง
        this.setNull();
        this.spinner.hide();
      }
    );

    this.Auth(this.keyword);
  }

  ConvertCurrentFilterAndKeywordToText(key: CurrentFilter) {
    // {{ item.Ntk }} : {{ item.Ntt }}

    switch (key.Ntk) {
      case 'MEDIATYPE':
        return this.getMEDIATYPENameByID(key.Ntt);
      default:
        return key.Ntt;
        break;
    }
  }

  getMEDIATYPENameByID(id) {
    const type = [
      'Unknow',
      'Book',
      'Continuing Resources',
      'Music',
      'Map',
      'Visual Materials',
      'Computer Files',
      'Mixed Materials',
      '',
      'Article',
      'ebook',
    ];
    return type[id];
  }

  //แสงเรายการคำค้นและ Filter ด้านซ้าย
  GetCurrentFilterAndKeyword() {
    //Set ค่าว่างการเสมอ มีประโยชน์ในการโหลดครั้งแรกและเมื่อผลลัพธ์ Error
    this.CurrentFilter = [];
    this.CurrentKeyword = [];

    for (let index = 0; index < this.Ntk.length; index++) {
      const k = this.Ntk[index];
      const t = this.Ntt[index];
      let Filter: CurrentFilter;
      Filter = new CurrentFilter();
      Filter.Ntk = k;
      Filter.Ntt = t;
      Filter.id = t;
      switch (k) {
        case 'LOCATION':
          this.homeService.getLocationName(t).subscribe(
            (locationName: string) => {
              Filter.Ntt = locationName;
            },
            (error) => {
              this.setNull();
            }
          );

          Filter.id = t;
          this.CurrentFilter.push(Filter);
          break;
        case 'SUBJECTFACET':
          this.CurrentFilter.push(Filter);
          break;
        case 'AUTHORFACET':
          this.CurrentFilter.push(Filter);
          break;
        case 'PUBYEAR':
          this.CurrentFilter.push(Filter);
          break;
        case 'MEDIATYPE':
          Filter.Ntt = this.getMEDIATYPENameByID(t);
          this.CurrentFilter.push(Filter);
          break;
        case 'AUTHLINK':
          Filter.Ntt = this.AuthViewOPAC;
          Filter.id = t;
          this.CurrentKeyword.push(Filter);
          break;
        default:
          //จะสกัด Filter ออกเป็น Case ก่อนหน้าและถ้าเป็น Case อื่นๆ ก็ให้มองเป็น คำค้น
          this.CurrentKeyword.push(Filter);
          break;
      }
    }
  }

  //Set ค่าว่างให้ตัวแปลที่ใช้แสดงผลใน Control ต่างๆ เพื่อผลที่ดีสำหรับกรณีไม่มีข้อมูลมาแสดงผล
  setNull() {
    this.bookcount = null;
    this.subjectFacet = [];
    this.subjectFacetLess = [];

    this.authorFacet = [];
    this.authorFacetLess = [];

    this.locationFacet = [];
    this.locationFacetLess = [];

    this.sourceTypeFacet = [];
    this.sourceTypeFacetLess = [];

    this.collectionFacet = [];
    this.collectionFacetLess = [];

    this.langFacet = [];
    this.langFacetLess = [];

    this.RegionFacet = [];
    this.RegionFacetLess = [];

    this.EraFacet = [];
    this.EraFacetLess = [];

    this.CallNoLCFacet = [];
    this.CallNoLCFacetLess = [];

    this.CallNoDCFacet = [];
    this.CallNoDCFacetLess = [];

    this.CallNoNLMFacet = [];
    this.CallNoNLMFacetLess = [];

    this.IllustrationsFacet = [];
    this.IllustrationsFacetLess = [];

    this.TargetAudienceFacet = [];
    this.TargetAudienceFacetLess = [];

    this.FormofItemFacet = [];
    this.FormofItemFacetLess = [];

    this.NatureOfContentsFacet = [];
    this.NatureOfContentsFacetLess = [];

    this.GovernmentPublicationFacet = [];
    this.GovernmentPublicationFacetLess = [];

    this.ConferencePublicationFacet = [];
    this.ConferencePublicationFacetLess = [];

    this.FestschriftFacet = [];
    this.FestschriftFacetLess = [];

    this.LiteraryFormFacet = [];
    this.LiteraryFormFacetLess = [];

    this.BiographyFacet = [];
    this.BiographyFacetLess = [];
  }

  //แสดงรายการ Facet หรือ Filter Result โดยแต่ละประเภทการ Filter จะมีการแยกไปยังตัวแปลคนละตัวกัน
  getFacet() {
    this.subjectFacet = [];
    this.subjectFacetLess = [];

    this.authorFacet = [];
    this.authorFacetLess = [];

    this.locationFacet = [];
    this.locationFacetLess = [];

    this.sourceTypeFacet = [];
    this.sourceTypeFacetLess = [];

    this.collectionFacet = [];
    this.collectionFacetLess = [];

    this.langFacet = [];
    this.langFacetLess = [];

    this.RegionFacet = [];
    this.RegionFacetLess = [];

    this.EraFacet = [];
    this.EraFacetLess = [];

    this.CallNoLCFacet = [];
    this.CallNoLCFacetLess = [];

    this.CallNoDCFacet = [];
    this.CallNoDCFacetLess = [];

    this.CallNoNLMFacet = [];
    this.CallNoNLMFacetLess = [];

    this.IllustrationsFacet = [];
    this.IllustrationsFacetLess = [];

    this.TargetAudienceFacet = [];
    this.TargetAudienceFacetLess = [];

    this.FormofItemFacet = [];
    this.FormofItemFacetLess = [];

    this.NatureOfContentsFacet = [];
    this.NatureOfContentsFacetLess = [];

    this.GovernmentPublicationFacet = [];
    this.GovernmentPublicationFacetLess = [];

    this.ConferencePublicationFacet = [];
    this.ConferencePublicationFacetLess = [];

    this.FestschriftFacet = [];
    this.FestschriftFacetLess = [];

    this.LiteraryFormFacet = [];
    this.LiteraryFormFacetLess = [];

    this.BiographyFacet = [];
    this.BiographyFacetLess = [];

    let isubject: number = 0;
    let iauthor: number = 0;
    let ilocation: number = 0;
    let isourceType: number = 0;
    let firstYear: boolean = true;

    let icollection: number = 0;
    let ilang: number = 0;
    let iRegion: number = 0;
    let iEra: number = 0;
    let iCallNoLC: number = 0;
    let iCallNoDC: number = 0;
    let iCallNoNLM: number = 0;
    let iIllustrations: number = 0;
    let iTargetAudience: number = 0;
    let iFormofItem: number = 0;
    let iNatureOfContents: number = 0;
    let iGovernmentPublication: number = 0;
    let iConferencePublication: number = 0;
    let iFestschrift: number = 0;
    let iLiteraryForm: number = 0;
    let iBiography: number = 0;

    //  collection
    //  lang
    //   Region
    //   Era
    //   CallNoLC
    //   CallNoDC
    //   CallNoNLM
    //   Illustrations
    //   TargetAudience
    //   FormofItem
    //   NatureOfContents
    //   GovernmentPublication
    //   ConferencePublication
    //   Festschrift
    //   LiteraryForm
    //   Biography

    //นำค่าที่ได้จาก Function สืบค้น ของ API ในส่วนที่เป็น Facet มาวนแยกเพื่อใส่ลงไปตามประเภทของตัวแปรที่ได้เตรียมไว้
    this.allFacet.forEach((element) => {
      switch (element.Type) {
        case 'subjectfacet':
          this.subjectFacet.push(element);
          isubject += 1;
          if (isubject <= this.facetLess) {
            this.subjectFacetLess.push(element);
          }
          break;
        case 'authorfacet':
          this.authorFacet.push(element);
          iauthor += 1;
          if (iauthor <= this.facetLess) {
            this.authorFacetLess.push(element);
          }
          break;
        case 'location':
          this.locationFacet.push(element);
          ilocation += 1;
          if (ilocation <= this.facetLess) {
            this.locationFacetLess.push(element);
          }
          break;
        case 'Biography':
          this.BiographyFacet.push(element);
          iBiography += 1;
          if (iBiography <= this.facetLess) {
            this.BiographyFacetLess.push(element);
          }
          break;
        case 'LiteraryForm':
          this.LiteraryFormFacet.push(element);
          iLiteraryForm += 1;
          if (iLiteraryForm <= this.facetLess) {
            this.LiteraryFormFacetLess.push(element);
          }
          break;
        case 'Festschrift':
          this.FestschriftFacet.push(element);
          iFestschrift += 1;
          if (iFestschrift <= this.facetLess) {
            this.FestschriftFacetLess.push(element);
          }
          break;
        case 'ConferencePublication':
          this.ConferencePublicationFacet.push(element);
          iConferencePublication += 1;
          if (iConferencePublication <= this.facetLess) {
            this.ConferencePublicationFacetLess.push(element);
          }
          break;
        case 'GovernmentPublication':
          this.GovernmentPublicationFacet.push(element);
          iGovernmentPublication += 1;
          if (iGovernmentPublication <= this.facetLess) {
            this.GovernmentPublicationFacetLess.push(element);
          }
          break;
        case 'NatureOfContents':
          this.NatureOfContentsFacet.push(element);
          iNatureOfContents += 1;
          if (iNatureOfContents <= this.facetLess) {
            this.NatureOfContentsFacetLess.push(element);
          }
          break;
        case 'FormofItem':
          this.FormofItemFacet.push(element);
          iFormofItem += 1;
          if (iFormofItem <= this.facetLess) {
            this.FormofItemFacetLess.push(element);
          }
          break;
        case 'TargetAudience':
          this.TargetAudienceFacet.push(element);
          iTargetAudience += 1;
          if (iTargetAudience <= this.facetLess) {
            this.TargetAudienceFacetLess.push(element);
          }
          break;
        case 'Illustrations':
          this.IllustrationsFacet.push(element);
          iIllustrations += 1;
          if (iIllustrations <= this.facetLess) {
            this.IllustrationsFacetLess.push(element);
          }
          break;
        case 'CallNoNLM':
          this.CallNoNLMFacet.push(element);
          iCallNoNLM += 1;
          if (iCallNoNLM <= this.facetLess) {
            this.CallNoNLMFacetLess.push(element);
          }
          break;
        case 'CallNoDC':
          this.CallNoDCFacet.push(element);
          iCallNoDC += 1;
          if (iCallNoDC <= this.facetLess) {
            this.CallNoDCFacetLess.push(element);
          }
          break;
        case 'CallNoLC':
          this.CallNoLCFacet.push(element);
          iCallNoLC += 1;
          if (iCallNoLC <= this.facetLess) {
            this.CallNoLCFacetLess.push(element);
          }
          break;
        case 'Era':
          this.EraFacet.push(element);
          iEra += 1;
          if (iEra <= this.facetLess) {
            this.EraFacetLess.push(element);
          }
          break;
        case 'Region':
          this.RegionFacet.push(element);
          iRegion += 1;
          if (iRegion <= this.facetLess) {
            this.RegionFacetLess.push(element);
          }
          break;
        case 'lang':
          this.langFacet.push(element);
          ilang += 1;
          if (ilang <= this.facetLess) {
            this.langFacetLess.push(element);
          }
          break;
        case 'collection':
          this.collectionFacet.push(element);
          icollection += 1;
          if (icollection <= this.facetLess) {
            this.collectionFacetLess.push(element);
          }
          break;
        case 'pubyear':
          if (Number(element.name)) {
            if (firstYear == true) {
              // ค่าแรกสุดเป็นค่าตั้งต้นให้ตัวแปร
              this.year_min = Number(element.name);
              this.year_max = Number(element.name);
              firstYear = false;
            } else {
              //   console.log(element.name);
              if (Number(element.name) > this.year_max) {
                this.year_max = Number(element.name);
              }
              if (Number(element.name) < this.year_min) {
                this.year_min = Number(element.name);
              }
            }
          }

          break;
        case 'mattype':
          this.sourceTypeFacet.push(element);
          isourceType += 1;
          if (isourceType <= this.facetLess) {
            this.sourceTypeFacetLess.push(element);
          }
          break;
        default:
          break;
      }
    });

    this.year_Cmin_value =  this.year_min;
    this.year_Cmax_value =  this.year_max;
   // this.PrepareYear();
  }

  //จัดการข้อมูลปีพิมพ์
  // PrepareYear() {
  //   // this.minYear =  Number(element.name);
  //   // this.maxYear =  Number(element.name);
  //   // this.optionsYear = {
  //   //   floor: this.minYear,
  //   //   ceil: this.maxYear,
  //   // };

  //   //alert( this.optionsYear.floor);
  //   // this.minYearValue = this.minYear;
  //   // this.maxYearValue = this.maxYear;
  // }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Subject
  MoreSubject(value) {
    this.subjectFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Author
  MoreAuthor(value) {
    this.authorFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Location
  MoreLocation(value) {
    this.locationFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ collection
  Morecollection(value) {
    this.collectionFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ lang
  Morelang(value) {
    this.langFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Region
  MoreRegion(value) {
    this.RegionFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Era
  MoreEra(value) {
    this.EraFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ CallNoLC
  MoreCallNoLC(value) {
    this.CallNoLCFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ CallNoDC
  MoreCallNoDC(value) {
    this.CallNoDCFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ CallNoNLM
  MoreCallNoNLM(value) {
    this.CallNoNLMFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Illustrations
  MoreIllustrations(value) {
    this.IllustrationsFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ TargetAudience
  MoreTargetAudience(value) {
    this.TargetAudienceFacetLessShow = value;
  }
  //เมื่อกดปุ่มดูเพิ่มเติมของ FormofItem
  MoreFormofItem(value) {
    this.FormofItemFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ NatureOfContents
  MoreNatureOfContents(value) {
    this.NatureOfContentsFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ GovernmentPublication
  MoreGovernmentPublication(value) {
    this.GovernmentPublicationFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ ConferencePublication
  MoreConferencePublication(value) {
    this.ConferencePublicationFacetLessShow = value;
  }
  //เมื่อกดปุ่มดูเพิ่มเติมของ Festschrift
  MoreFestschrift(value) {
    this.FestschriftFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ LiteraryForm
  MoreLiteraryForm(value) {
    this.LiteraryFormFacetLessShow = value;
  }

  //เมื่อกดปุ่มดูเพิ่มเติมของ Biography
  MoreBiography(value) {
    this.BiographyFacetLessShow = value;
  }

  //เมื่อเปลี่ยนรูปแบบการเรียงการสืบค้น
  changeOrderBy(event) {
    //เปลี่ยนแค่ตัวแปรการเรียงอย่างเดียวค่าอื่นๆ ใน Keyword ยังเหมือนเดิม
    const value = event.target.value;
    this.keyword.Orderby = value;
    this.keyword.CurrentPage = 1;

    //ส่งค่าไปยัง Function Keyword Search
    this.keywordSearch(this.keyword);
  }

  //Function ที่ใช้สืบค้น ของหน้า Result เอง โดยส่งมาจาก Text Search
  search(qsForm: boolean , keyword_: string) {
   


    var keyword = keyword_;
    if (qsForm==true) {
      keyword = this.qsearchForm.controls.textSearch.value;
    }

    if (keyword != '') {
      if (qsForm==false) {
        this.qsearchForm.controls.textSearch.setValue(keyword)
      }
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth',
      });
      // Textbox Search ด้านบน

      //ดึง Location ที่มีการใส่ไว้ใน  localStorage มาส่งเพิ่มใน Function สืบค้น เพราะในระบบนี้มีการแสดงผลแบบแยก Location เป็นหลัก
      this.locationID = localStorage.getItem('locationIDMain'); //location หลัก
      this.locationName = localStorage.getItem('locationNameMain');

      //เพิ่มค่าให้แก่ตัวแปร  Keyword Ntk คือประเภทการสืบค้น Ntt คือคำค้น Nto คือ Operator ที่ใช้สำหรับคำค้นที่มีมากกว่าหนึ่งซึ่งเป็นได้ทั้ง And Or Not
      // this.keyword.Ntk = 'KEYWORD';
      if (qsForm) {
        this.keyword.Ntk = this.qsearchForm.controls.typeOption.value;
      } else {
        this.keyword.Ntk = 'KEYWORD';
      }

      this.keyword.Ntt= keyword

      if (this.locationID != '') {
        //กรณีมีการระบุสาขา ซึ่งถ้าเป็นค่าว่าง จะไม่ระบุสาขา
        this.keyword.Ntk += '|LOCATION';
        this.keyword.Ntt += '|' + this.locationID;
      }
      this.keyword.Nto = '';
      this.keyword.CurrentPage = 1; // CurrentPage หน้าที่แสดงผลในการสืบค้นครั้งใหม่จะเริ่มที่หน้าแรกเสมอ

      const navigationExtras: NavigationExtras = {
        queryParams: {
          Ntk: this.keyword.Ntk,
          Ntt: this.keyword.Ntt,
          Nto: this.keyword.Nto,
          CurrentPage:  this.keyword.CurrentPage
        },
      };
      this.router.navigate(['/results'], navigationExtras);

      this.keywordSearch(this.keyword); //ส่งค่าไปยัง Function การสืบค้น

      if (qsForm) {
        this.Suggester(this.qsearchForm.controls['textSearch'].value);
      }
     



    }
  }

  //รูปแบบการการแสดงผลซึ่งมี 2 แบบคือแบบเป็นช่องๆ และแบบเแสดงรายละเอียด
  DisplayStyle(Style) {
    if (Style === 'Table') {
      this.DisplayTable = Style;
      this.DisplayList = null;
    } else {
      this.DisplayTable = null;
      this.DisplayList = Style;
    }
  }

  //ใช้สำหรับการแบ่งหน้าแบบเลื่อน Mouse ไว้ล่างสุดเมื่อไปล่างสุด จะมีการไปดึงผลลัพธ์ของหน้าถัดไปมาแสดงต่อท้าย
  onScroll() {
   
    if (this.notscrolly && this.notEmptyPost) {
      this.spinner.show();
      this.notscrolly = false;
      this.loadNextPost();
    }
  }

  // load th next  posts
  loadNextPost() {
    this.keyword.CurrentPage = this.keyword.CurrentPage + 1;

    this.searchService.keywordsearch(this.keyword).subscribe(
      (results: any) => {
        if (results) {
          const newPost = results.Display;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmptyPost = false;
          }

          // add newly fetched posts to the existing post
          this.bookList = this.bookList.concat(newPost);
          this.notscrolly = true;
        }
      },
      (error) => {}
    );
  }

  // แสงดรายละเอียดของประเภททรัพยากร เนื่องจากหนังสือและวารสารบางชื่อ อาจจะเป็น บทความหรือ E-book ดังนั้นระบบจึงต้องไปตรวจสอบอีกครั้งเพื่อจะได้ประเภทที่ต้องการจริงๆ
  // getMattype(typeid, bibid): Promise<string> {
  //   return new Promise((resolve, _reject) => {
  //     if (typeid != 1 && typeid != 2) {
  //       this.Mattype = new Mattype();
  //       const matName = this.Mattype.GetMattypeName(typeid);
  //       resolve(`assets/images/Mattype/${matName}.png`);
  //     } else {
  //       this.homeService.getBookNewMatType(bibid).subscribe((mid: any) => {
  //         resolve(`assets/images/Mattype/${mid}.png`);
  //       });
  //     }
  //   });
  // }

  //ลบคำค้นจาก Current Keyword ทางด้านซ้าย
  RemoveKeyword(type, word) {
    //keyword จะถูกเชื่อมด้วย | ดังนั้นจะต้องแยกประเภทการสืบค้นและคำค้นต่างๆ ออกจากกันก่อน โดยคำค้นและประเภทนั้นถูกเรียงกันเป็นลำดับอย่างชัดเจนตั้งแต่ส่งมาแล้ว
    this.Ntk = this.keyword.Ntk.split('|');
    this.Ntt = this.keyword.Ntt.split('|');
    this.Nto = this.keyword.Nto.split('|');

    for (let index = 0; index < this.Ntk.length; index++) {
      //วนลบคำค้น
      if (this.Ntk[index] == type && this.Ntt[index] == word) {
        //พบตำแหน่งของคำค้นที่ต้องการลบ *คำและประเภทตรงกัน
        this.Ntk.splice(index, 1);
        this.Ntt.splice(index, 1);
      }
    }

    this.keyword.Ntk = '';
    this.keyword.Ntt = '';
    for (let index = 0; index < this.Ntk.length; index++) {
      //วนลบคำค้น
      this.keyword.Ntk += this.Ntk[index] + '|';
      this.keyword.Ntt += this.Ntt[index] + '|';
    }

    //ตัด | ตัวสุดท้ายออก
    this.keyword.Ntk = this.keyword.Ntk.slice(0, -1);
    this.keyword.Ntt = this.keyword.Ntt.slice(0, -1);

    //เมื่อลบคำค้นเรียบร้อยแล้ว จะมีการไปลืบค้นหาผลลัพธ์ใหม่
    this.keywordSearch(this.keyword);
  }

  //เพิ่มตัวกรองการสืบค้น
  addFilter(type, name) {
    this.keyword.CurrentPage = 1;
    //การเพิ่มตัวกรองการสืบค้นทำได้โดยการใช้ | ต่อท้ายคำค้นและประเภทการสืบค้นได้เลย
    this.keyword.Ntk += '|' + type;
    this.keyword.Ntt += '|' + name;
    this.keywordSearch(this.keyword);
  }

  addFilterYear() {
    this.keyword.CurrentPage = 1;
    this.keyword.Ntk += '|PUBYEAR';
    this.keyword.Ntt += '|[ ' + this.year_Cmin_value + ' TO ' + this.year_Cmax_value  + ']';
    this.keywordSearch(this.keyword);
    // alert(event.value);
    // alert(event.highValue);
  }

  save(bibid: string) {
    this.data.saveForExport(bibid);
  }

  //เมื่อเปลี่ยนรูปแบบการสืบค้น
  SearchOption(event: string, text: string) {
    this.inputState = text;
    this.qsearchForm.controls.typeOption.setValue(event);
  }
}
