<!-- <app-header></app-header> -->
    
<main class="form-signin">
  <form [formGroup]="loginForm" class="mt-5">
    <div class="clearfix mb-5 a-walai text-center" [routerLink]="['/home']">
      <img   src="{{ logo }}"
      onError="this.src='assets/images/logo_wulib.png'" alt="..." class="float-left mb-2 mt-4" style="width: 40px;">
      <!-- <h4 class="ml-5 text-walai-default-color">{{'SignInTo'|translate}} WALAI Autolib</h4> -->
      <h4 class="ml-5 text-walai-default-color">{{'SignIn'|translate}}</h4>
    </div>
    <div class="form-floating">
      <input type="email" class="form-control"  formControlName="username" id="floatingInput" placeholder="{{userLogin|translate}}">
      <label for="floatingInput">{{userLogin|translate}}</label>
    </div>
    <div class="form-floating">
      <input type="password" class="form-control" id="floatingPassword" placeholder="Password" formControlName="password">
      <label for="floatingPassword">{{'Password'|translate}}</label>
    </div>

   
    <!-- <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button> -->
<div class=" text-center">
      <button  class="btn btn-primary btn-lg px-5 mb-2" (click)="loginOK()" [disabled]="!loginForm.valid" disabled *ngIf="Onlogin==false">{{'SignIn'|translate}}</button>
    <button class="btn btn-primary btn-lg px-5 mb-2" type="button" disabled *ngIf="Onlogin">
      <span class="spinner-border text-warning mr-2" role="status" aria-hidden="true" *ngIf="Onlogin"></span>
      Sign in...
    </button>

      <a class="dropdown-item a-hover a-walai" (click)="help()">{{'Help'|translate}}</a>
   <!-- <span><a class="dropdown-item a-hover" [routerLink]="['/Forgotpassword']">{{'ForgotPassword'|translate}}?</a></span>  -->
</div>


  
  </form>
</main>

<!-- 
<main role="main" > 
    <div class="container-fluid ">
      <div class="row justify-content-md-center row flex-column-reverse flex-md-row">
        <div class="walai-card">
          <form class="px-4 py-3" [formGroup]="loginForm">
            <div class="clearfix mb-5 a-walai" [routerLink]="['/home']">
                <img src="./assets/images/logo_wulib.png" alt="..." class="float-left" style="width: 40px;">
                <h4 class="ml-5 text-walai-default-color">{{'SignInTo'|translate}} WALAI Autolib</h4>
              </div>
          
            <div class="form-group">
              <label for="exampleDropdownFormEmail1">{{'EmailAddressOrUsername'|translate}}</label>
              <input type="email" class="form-control" formControlName="username"
                placeholder="email@example.com">
            </div>
            <div class="form-group">
              <label for="exampleDropdownFormPassword1">{{'Password'|translate}}</label>
              <input type="password" class="form-control" formControlName="password" placeholder="{{'Password'|translate}}">
            </div>
             <button  class="btn btn-primary btn-lg px-5 mb-2" (click)="loginOK()" [disabled]="!loginForm.valid" disabled *ngIf="Onlogin==false">{{'SignIn'|translate}}</button>
            <button class="btn btn-primary btn-lg px-5 mb-2" type="button" disabled *ngIf="Onlogin">
              <span class="spinner-border text-warning mr-2" role="status" aria-hidden="true" *ngIf="Onlogin"></span>
              Sign in...
            </button>
            </form>
        
          <div class="dropdown-divider"></div>
          <a class="dropdown-item a-hover" (click)="help()">{{'Help'|translate}}</a>
          <a class="dropdown-item a-hover" [routerLink]="['/Forgotpassword']">{{'ForgotPassword'|translate}}?</a>
        </div>
      </div>
    </div>
</main> -->